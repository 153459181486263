import { useState } from 'react'
import DOMPurify from 'dompurify'
import SidePanel from '../../../components/common/side-panel/SidePanel'
import MainPanel from '../../../components/common/main-panel/MainPanel'
import instance from '../../../utils/Axios'
import { toast } from 'react-toastify'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'
import { useSelector } from 'react-redux'

const InviteMembersContent = () => {
  const {
    auth: { user },
  } = useSelector((state) => state)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    triggerGoogleAnalyticsEvent('invite-member', {
      userId: user?.id,
    })

    instance
      .post('/users/invite-member', { email, name })
      .then(() => {
        setEmail('')
        setName('')
        toast.success('Invite sent successfully!')
      })
      .catch(() => {
        toast.error('Invite cannot be sent!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <MainPanel>
      <div className="invite-members-container">
        <h2 className="title">Invite Members</h2>
        <form onSubmit={onSubmit}>
          <div className="tikbox-form-element">
            <label htmlFor="name">Name</label>
            <input
              value={name}
              onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
              name="name"
              id="name"
              placeholder="Member name..."
              required
            />
          </div>
          <div className="tikbox-form-element">
            <label htmlFor="email">Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
              name="email"
              type="email"
              id="email"
              placeholder="Email..."
              required
            />
          </div>
          <div className="tikbox-form-element">
            <button disabled={loading} type="submit">
              {loading ? 'Sending...' : 'Invite'}
            </button>
          </div>
        </form>
      </div>
    </MainPanel>
  )
}

const InviteMembers = () => {
  return (
    <div className="tikbox-invite-members">
      <div className="tikbox-invite-members__content">
        <SidePanel />
        <InviteMembersContent />
      </div>
    </div>
  )
}

export default InviteMembers
