import _ from 'lodash'
import { toast } from 'react-toastify'
import * as FileUtils from '../../utils/FileUtils'
import S3BucketUploader from './S3BucketUploader'
import instance from '../../utils/Axios'

class LibraryAssetUploader {
  async saveAsLibraryAsset(
    fileBlob,
    fileUrl,
    assetFolderName,
    authorId,
    additionalSuffix = '',
    manageUpload = false,
    callback,
  ) {
    const fileName = FileUtils.getFilename(fileUrl) + additionalSuffix

    const extension = FileUtils.getFileExtension(fileUrl)
    const updatedFileName = FileUtils.replaceSpacesIntoUnderscores(fileName)

    const Key = `${assetFolderName}/${authorId}/${updatedFileName}.${extension}`

    // Get presigned url
    const getPresignedUrl = await instance.get(
      `/assets/generate-presigned-url?key=${Key}&authorId=${authorId}`,
    )
    if (!getPresignedUrl || getPresignedUrl?.status !== 200) {
      return toast.error('Failed to get presigned url')
    }
    const presignedUrl = getPresignedUrl?.data?.message

    const s3Upload =
      await S3BucketUploader.manageUploadPresignedAssetToS3Bucket(
        fileBlob,
        Key,
        presignedUrl,
        manageUpload && callback,
      )
    return s3Upload
  }
}

export default new LibraryAssetUploader()
