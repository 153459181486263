export const TiktokIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_187_11441)">
        <path
          d="M0 13.4167C0 8.67091 0 6.29803 0.938221 4.49188C1.72885 2.96985 2.96985 1.72885 4.49188 0.938221C6.29803 0 8.67091 0 13.4167 0H26.5833C31.3291 0 33.702 0 35.5081 0.938221C37.0301 1.72885 38.2711 2.96985 39.0618 4.49188C40 6.29803 40 8.67091 40 13.4167V26.5833C40 31.3291 40 33.702 39.0618 35.5081C38.2711 37.0301 37.0301 38.2711 35.5081 39.0618C33.702 40 31.3291 40 26.5833 40H13.4167C8.67091 40 6.29803 40 4.49188 39.0618C2.96985 38.2711 1.72885 37.0301 0.938221 35.5081C0 33.702 0 31.3291 0 26.5833V13.4167Z"
          fill="black"
        />
        <path
          d="M12.1363 23.9506C12.2991 22.6028 12.8519 21.8482 13.8936 21.0746C15.3841 20.0269 17.2459 20.6195 17.2459 20.6195V17.1037C17.6985 17.0922 18.1513 17.119 18.599 17.1839V21.7084C18.599 21.7084 16.7378 21.1157 15.2473 22.164C14.2062 22.937 13.6522 23.6922 13.49 25.04C13.4849 25.7719 13.6223 26.7286 14.2548 27.5558C14.0984 27.4756 13.939 27.3842 13.7766 27.2817C12.3833 26.3461 12.1295 24.9425 12.1363 23.9506ZM26.2865 10.603C25.2612 9.47949 24.8735 8.34512 24.7333 7.54824H26.0231C26.0231 7.54824 25.766 9.63768 27.6402 11.6924L27.6662 11.7201C27.1611 11.4023 26.6978 11.0273 26.2865 10.603ZM32.5 13.7884V18.2219C32.5 18.2219 30.8541 18.1574 29.636 17.8475C27.9353 17.4141 26.8421 16.7494 26.8421 16.7494C26.8421 16.7494 26.087 16.2754 26.026 16.2424V25.3975C26.026 25.9073 25.8864 27.1804 25.4608 28.2421C24.9051 29.6311 24.0477 30.5428 23.89 30.7292C23.89 30.7292 22.8472 31.9616 21.0074 32.7915C19.3491 33.5402 17.8931 33.5212 17.4578 33.5402C17.4578 33.5402 14.9409 33.6399 12.6761 32.168C12.1864 31.8436 11.7292 31.4762 11.3105 31.0705L11.3219 31.0786C13.5872 32.5505 16.1036 32.4508 16.1036 32.4508C16.5394 32.4318 17.9954 32.4508 19.6532 31.7021C21.4912 30.8722 22.5358 29.6398 22.5358 29.6398C22.6918 29.4534 23.5531 28.5417 24.1065 27.1522C24.531 26.091 24.6717 24.8174 24.6717 24.3076V15.1535C24.7328 15.1871 25.4873 15.6611 25.4873 15.6611C25.4873 15.6611 26.581 16.3264 28.2818 16.7592C29.5004 17.0691 31.1457 17.1335 31.1457 17.1335V13.6594C31.7087 13.7857 32.1885 13.8198 32.5 13.7884Z"
          fill="#FD355A"
        />
        <path
          d="M31.1464 13.6594V17.1324C31.1464 17.1324 29.5011 17.068 28.2824 16.7581C26.5817 16.3247 25.488 15.66 25.488 15.66C25.488 15.66 24.7335 15.186 24.6724 15.1524V24.3087C24.6724 24.8184 24.5328 26.092 24.1072 27.1533C23.5516 28.5428 22.6941 29.4545 22.5365 29.6409C22.5365 29.6409 21.4931 30.8733 19.6538 31.7032C17.9961 32.4519 16.5401 32.4329 16.1043 32.4519C16.1043 32.4519 13.5879 32.5516 11.3225 31.0797L11.3112 31.0716C11.0721 30.84 10.8469 30.5954 10.6369 30.3392C9.91402 29.4578 9.47089 28.4155 9.35954 28.1181C9.35935 28.1168 9.35935 28.1155 9.35954 28.1143C9.18037 27.5975 8.80393 26.3564 8.85537 25.1543C8.94637 23.0335 9.69245 21.7317 9.88972 21.4056C10.4121 20.5163 11.0916 19.7206 11.8979 19.0539C12.6094 18.4787 13.4159 18.021 14.2843 17.6996C15.223 17.3223 16.2282 17.12 17.246 17.1037V20.6195C17.246 20.6195 15.3842 20.029 13.8943 21.0746C12.8526 21.8482 12.2998 22.6028 12.137 23.9506C12.1302 24.9425 12.384 26.3461 13.7761 27.2822C13.9386 27.3851 14.0979 27.4765 14.2543 27.5563C14.4975 27.8725 14.7935 28.1479 15.1304 28.3716C16.4903 29.2324 17.6298 29.2925 19.0869 28.7335C20.0585 28.3597 20.7899 27.5173 21.1291 26.5839C21.3421 26.001 21.3393 25.4143 21.3393 24.8076V7.54824H24.7306C24.8708 8.34512 25.2585 9.47949 26.2838 10.603C26.6951 11.0273 27.1584 11.4023 27.6635 11.7201C27.8128 11.8745 28.5758 12.6377 29.5553 13.1063C30.0618 13.3486 30.5957 13.5341 31.1464 13.6594Z"
          fill="white"
        />
        <path
          d="M8.00977 27.0385V27.0412L8.09392 27.2693C8.08425 27.2427 8.05298 27.162 8.00977 27.0385Z"
          fill="#33F3ED"
        />
        <path
          d="M14.2842 17.6997C13.4158 18.0211 12.6094 18.4787 11.8978 19.054C11.0913 19.7221 10.412 20.5195 9.89019 21.4105C9.69293 21.7355 8.94684 23.0384 8.85584 25.1592C8.80441 26.3613 9.18084 27.6024 9.36001 28.1192C9.35982 28.1205 9.35982 28.1218 9.36001 28.123C9.47306 28.4177 9.91449 29.46 10.6374 30.3441C10.8474 30.6004 11.0725 30.8449 11.3117 31.0765C10.5454 30.5685 9.86194 29.9544 9.28371 29.2541C8.56701 28.3803 8.12501 27.3489 8.00971 27.0444C8.00957 27.0423 8.00957 27.0401 8.00971 27.0379V27.0341C7.82997 26.5179 7.45241 25.2762 7.50497 24.0725C7.59597 21.9517 8.34206 20.6499 8.53932 20.3238C9.06094 19.4327 9.74028 18.6353 10.547 17.9673C11.2583 17.3919 12.0648 16.9341 12.9333 16.613C13.475 16.3976 14.0393 16.2386 14.6165 16.139C15.4864 15.9934 16.3748 15.9808 17.2487 16.1016V17.1038C16.23 17.1198 15.2238 17.322 14.2842 17.6997Z"
          fill="#33F3ED"
        />
        <path
          d="M24.7334 7.54828H21.3421V24.8082C21.3421 25.4149 21.3421 26 21.1319 26.5845C20.7894 27.5173 20.0608 28.3597 19.0898 28.7335C17.6321 29.2948 16.4926 29.2325 15.1332 28.3717C14.7958 28.149 14.499 27.8744 14.2549 27.5591C15.413 28.1512 16.4496 28.1409 17.7338 27.6468C18.7043 27.273 19.434 26.4306 19.7754 25.4973C19.989 24.9144 19.9862 24.3277 19.9862 23.7215V6.45833H24.669C24.669 6.45833 24.6164 6.88738 24.7334 7.54828ZM31.1464 12.699V13.6595C30.5966 13.534 30.0637 13.3484 29.5581 13.1064C28.5786 12.6378 27.8156 11.8745 27.6664 11.7201C27.8395 11.8291 28.0192 11.9282 28.2044 12.017C29.3954 12.5869 30.5682 12.757 31.1464 12.699Z"
          fill="#33F3ED"
        />
      </g>
      <defs>
        <clipPath id="clip0_187_11441">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
