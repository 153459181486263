import moment from 'moment'
import { Tooltip } from 'antd'
import {
  AgreementCategory,
  AgreementContentType,
  AgreementKeys,
} from '../../../constants'
import { AgreementItem } from './AgreementItem'
import { Fragment, useEffect, useState } from 'react'
import { faLink, faCopy } from '@fortawesome/free-solid-svg-icons'
import {
  sortAgreements,
  triggerGoogleAnalyticsEvent,
} from '../../../utils/Helper'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TikBoxModal from '../../../components/common/modal/Modal'

const transformRoyaltyValue = (str, royaltyVal, duration) => {
  str = str.replace(/{{royalty_value}}/g, royaltyVal)
  str = str.replace(/{{duration}}/g, duration)

  return str
}

const ReviewContent = ({
  editingAgreement,
  setEditAgreement,
  cancelEditAgreement,
  saveUpdatedAgreement,
  setAgreementContent,
  agreementContent,
  agreementData,
  selectedAgreements,
  user,
  signingAssets,
  acceptedTerms,
  acceptAgreementTerms,
  reviewRef,
  cco,
  setEditAgreementCCO,
  cancelEditAgreementCCO,
  confirmModifyModal,
  toggleConfirmModifyModal,
}) => {
  const {
    creativeCommonLicenses: { data: creativeCommonLicensesData },
  } = useSelector((state) => state)
  const [dataDeletionFull, setDataDeletionFull] = useState(false)
  const [guaranteesFull, setGuaranteesFull] = useState(false)
  const [indemnityFull, setIndemnityFull] = useState(false)
  const [terminationFull, setTerminationFull] = useState(false)
  const [technologyEvoFull, setTechnologyEvoFull] = useState(false)

  const boilerplateParagraphs = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.category === AgreementCategory.BOILERPLATE,
    ),
  )
  const boilerplateOls = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.OL &&
        x.category === AgreementCategory.BOILERPLATE,
    ),
  )
  const { guaranteeOls, indemnityOls, terminationOls, evolvingOls } =
    boilerplateOls.reduce(
      (acc, x, index) => {
        if (index < 5) {
          acc.guaranteeOls.push(x)
        } else if (index < 9) {
          acc.indemnityOls.push(x)
        } else if (index < 14) {
          acc.terminationOls.push(x)
        } else {
          acc.evolvingOls.push(x)
        }
        return acc
      },
      {
        guaranteeOls: [],
        indemnityOls: [],
        terminationOls: [],
        evolvingOls: [],
      },
    )
  const boilerplateSubtitles = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.category === AgreementCategory.BOILERPLATE,
    ),
  )
  const royaltySubtitles = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.category === AgreementCategory.ROYALTY &&
        !x.code.includes('PERPETUAL'),
    ),
  )
  const royaltyParagraphs = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.category === AgreementCategory.ROYALTY &&
        !x.code.includes('PERPETUAL'),
    ),
  )
  const licenseSubtitles = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.category === AgreementCategory.LICENSE,
    ),
  )
  const licenseParagraphs = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.category === AgreementCategory.LICENSE,
    ),
  )
  const assignmentSubtitles = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.category === AgreementCategory.ASSIGNMENT,
    ),
  )
  const assignmentParagraphs = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.category === AgreementCategory.ASSIGNMENT,
    ),
  )
  const perpetualSubtitles = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.SUBTITLE &&
        x.code.includes('PERPETUAL') &&
        x.category === AgreementCategory.ROYALTY,
    ),
  )
  const perpetualParagraphs = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.PARAGRAPH &&
        x.code.includes('PERPETUAL') &&
        x.category === AgreementCategory.ROYALTY,
    ),
  )
  const perpetualBoilerplateOls = sortAgreements(
    agreementData.filter(
      (x) =>
        x.contentType === AgreementContentType.OL &&
        x.category === AgreementCategory.ROYALTY,
    ),
  )
  const perpetualAgreement = agreementData.find(
    (x) => x.code === 'ROYALTY_RADIO_4',
  )?._id

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('Copied to clipboard!')
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  useEffect(() => {
    triggerGoogleAnalyticsEvent('setting_agreement', {
      stage: 'review',
      userId: user?.id,
    })
  }, [])

  const ccoData = creativeCommonLicensesData.find((x) => x.code === cco)
  const copyMessage =
    selectedAgreements?.ccoAttribution &&
    `${selectedAgreements.ccoAttribution[0].value} © ${selectedAgreements.ccoAttribution[4].value}
        ${selectedAgreements.ccoAttribution[1].value} is licensed under ${cco}`

  return (
    <div className="review-content">
      <h2>Review & Finish</h2>
      {!cco && (
        <div ref={reviewRef} style={{ padding: 15 }}>
          <div className="mb-4">
            <h3 className="mb-3">Copyright and Licensing Agreement</h3>
            <p style={{ fontSize: 16 }}>
              This agreement is date {moment().format('YYYY-MM-DD')} and made
              between:
            </p>
            <ol>
              <li style={{ fontSize: 15 }}>
                1. {user.name} (hereafter "the license")
              </li>
              <li style={{ fontSize: 15 }}>
                2. [Name of licensee] of [address of licensee]{' '}
              </li>
            </ol>
            <p>
              The licensor wishes to license the Asset{' '}
              {signingAssets
                .map(({ originalFileName }) => originalFileName)
                .toString()}
            </p>
          </div>
          {selectedAgreements[AgreementKeys.Assignment].length > 0 && (
            <div className="mb-4">
              {assignmentSubtitles[0] && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={assignmentSubtitles[0]}
                />
              )}
              {assignmentParagraphs[0] && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={assignmentParagraphs[0]}
                />
              )}

              <div className="questionaire" style={{ marginTop: 10 }}>
                <form>
                  {agreementData
                    .filter(
                      (x) =>
                        x.category === AgreementCategory.ASSIGNMENT &&
                        x.contentType === AgreementContentType.CHECKBOX,
                    )
                    .map((checkbox, index) => {
                      const isChecked = !!selectedAgreements[
                        AgreementKeys.Assignment
                      ].find((x) => x.index === checkbox._id)
                      return (
                        isChecked && (
                          <div
                            className="tikbox-form-element"
                            key={index}
                            style={{ marginBottom: 0 }}>
                            <input
                              type="checkbox"
                              id={index}
                              checked={isChecked}
                              name={index}
                              disabled
                              style={{ width: 20 }}
                            />
                            <label htmlFor={index}>{checkbox.content}</label>
                          </div>
                        )
                      )
                    })}
                </form>
              </div>
            </div>
          )}

          {selectedAgreements[AgreementKeys.License].length > 0 && (
            <div className="mb-4">
              {licenseSubtitles[0] && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={licenseSubtitles[0]}
                />
              )}
              {licenseParagraphs[0] && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={licenseParagraphs[0]}
                />
              )}

              <div className="questionaire" style={{ marginTop: 10 }}>
                <form>
                  {agreementData
                    .filter(
                      (x) =>
                        x.category === AgreementCategory.LICENSE &&
                        x.contentType === AgreementContentType.CHECKBOX,
                    )
                    .map((checkbox, index) => {
                      const isChecked = !!selectedAgreements[
                        AgreementKeys.License
                      ].find((x) => x.index === checkbox._id)
                      return (
                        isChecked && (
                          <div
                            className="tikbox-form-element"
                            key={index}
                            style={{ marginBottom: 0 }}>
                            <input
                              type="checkbox"
                              id={index}
                              checked={isChecked}
                              name={index}
                              disabled
                              style={{ width: 20 }}
                            />
                            <label htmlFor={index}>{checkbox.content}</label>
                          </div>
                        )
                      )
                    })}
                </form>
              </div>
            </div>
          )}

          <div className="mb-4">
            {royaltySubtitles[royaltySubtitles.length - 1] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={royaltySubtitles[royaltySubtitles.length - 1]}
              />
            )}
            {royaltyParagraphs[0] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={royaltyParagraphs[0]}
                displayText={transformRoyaltyValue(
                  royaltyParagraphs[0].content,
                  selectedAgreements[AgreementKeys.RoyaltyValue],
                  agreementData.find(
                    (x) =>
                      x._id === selectedAgreements[AgreementKeys.RoyaltyLength],
                  )?.content,
                )}
              />
            )}
            {selectedAgreements[AgreementKeys.RoyaltyAcknowledgement] &&
              royaltyParagraphs[1] && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={royaltyParagraphs[1]}
                  displayText={`${royaltyParagraphs[1].content} ${
                    selectedAgreements[AgreementKeys.RoyaltyAcknowledgement]
                  }`}
                />
              )}

            {perpetualAgreement ===
              selectedAgreements[AgreementKeys.RoyaltyLength] && (
              <>
                {perpetualSubtitles[0] && (
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={perpetualSubtitles[0]}
                  />
                )}
                <div>
                  {perpetualSubtitles[1] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualSubtitles[1]}
                    />
                  )}
                  {perpetualParagraphs[0] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualParagraphs[0]}
                    />
                  )}
                </div>
                <div>
                  {perpetualSubtitles[2] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualSubtitles[2]}
                    />
                  )}
                  {perpetualParagraphs[1] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualParagraphs[1]}
                    />
                  )}
                </div>
                <div>
                  {perpetualSubtitles[3] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualSubtitles[3]}
                    />
                  )}
                  {perpetualParagraphs[2] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualParagraphs[2]}
                    />
                  )}
                </div>
                <div>
                  {perpetualSubtitles[4] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualSubtitles[4]}
                    />
                  )}
                  {perpetualParagraphs[3] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualParagraphs[3]}
                    />
                  )}
                </div>
                <div>
                  {perpetualSubtitles[5] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualSubtitles[5]}
                    />
                  )}
                  {perpetualParagraphs[4] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualParagraphs[4]}
                    />
                  )}
                </div>
                <div>
                  {perpetualSubtitles[6] && (
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={perpetualSubtitles[6]}
                    />
                  )}
                  <div
                    className={`legal-clause-container${
                      dataDeletionFull ? ' full' : ' summary'
                    }`}>
                    {perpetualParagraphs[5] && (
                      <AgreementItem
                        editingAgreement={editingAgreement}
                        setEditAgreement={setEditAgreement}
                        cancelEditAgreement={cancelEditAgreement}
                        saveUpdatedAgreement={saveUpdatedAgreement}
                        agreementContent={agreementContent}
                        setAgreementContent={setAgreementContent}
                        agreement={perpetualParagraphs[5]}
                        hideToggleFull={true}
                        showFullAgreement={dataDeletionFull}
                      />
                    )}

                    {perpetualBoilerplateOls.length && (
                      <ol>
                        {perpetualBoilerplateOls.map((ol, index) => (
                          <Fragment key={index}>
                            <AgreementItem
                              editingAgreement={editingAgreement}
                              setEditAgreement={setEditAgreement}
                              cancelEditAgreement={cancelEditAgreement}
                              saveUpdatedAgreement={saveUpdatedAgreement}
                              agreementContent={agreementContent}
                              setAgreementContent={setAgreementContent}
                              agreement={ol}
                              hideToggleFull={true}
                              showFullAgreement={dataDeletionFull}
                            />
                          </Fragment>
                        ))}
                      </ol>
                    )}
                    {dataDeletionFull && (
                      <span
                        onClick={() => setDataDeletionFull(false)}
                        className="toggler">
                        See summary clause
                      </span>
                    )}
                    {!dataDeletionFull && (
                      <span
                        onClick={() => setDataDeletionFull(true)}
                        className="toggler">
                        See legal clause
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="mb-4">
            {boilerplateSubtitles[0] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[0]}
              />
            )}
            <div
              className={`legal-clause-container${
                guaranteesFull ? ' full' : ' summary'
              }`}>
              {boilerplateParagraphs.length && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={boilerplateParagraphs[0]}
                  hideToggleFull={true}
                  showFullAgreement={guaranteesFull}
                />
              )}
              {guaranteeOls.length && (
                <ol>
                  {guaranteeOls.map((ol, index) => (
                    <Fragment key={index}>
                      <AgreementItem
                        editingAgreement={editingAgreement}
                        setEditAgreement={setEditAgreement}
                        cancelEditAgreement={cancelEditAgreement}
                        saveUpdatedAgreement={saveUpdatedAgreement}
                        agreementContent={agreementContent}
                        setAgreementContent={setAgreementContent}
                        agreement={ol}
                        hideToggleFull={true}
                        showFullAgreement={guaranteesFull}
                      />
                    </Fragment>
                  ))}
                </ol>
              )}
              {guaranteesFull && (
                <span
                  onClick={() => setGuaranteesFull(false)}
                  className="toggler">
                  See summary clause
                </span>
              )}
              {!guaranteesFull && (
                <span
                  onClick={() => setGuaranteesFull(true)}
                  className="toggler">
                  See legal clause
                </span>
              )}
            </div>
          </div>

          <div className="mb-4">
            {boilerplateSubtitles[1] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[1]}
              />
            )}
            <div
              className={`legal-clause-container${
                indemnityFull ? ' full' : ' summary'
              }`}>
              {boilerplateParagraphs.length > 1 && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={boilerplateParagraphs[1]}
                  hideToggleFull={true}
                  showFullAgreement={indemnityFull}
                />
              )}
              {indemnityOls.length && (
                <ol>
                  {indemnityOls.map((ol, index) => (
                    <Fragment key={index}>
                      <AgreementItem
                        editingAgreement={editingAgreement}
                        setEditAgreement={setEditAgreement}
                        cancelEditAgreement={cancelEditAgreement}
                        saveUpdatedAgreement={saveUpdatedAgreement}
                        agreementContent={agreementContent}
                        setAgreementContent={setAgreementContent}
                        agreement={ol}
                        hideToggleFull={true}
                        showFullAgreement={indemnityFull}
                      />
                    </Fragment>
                  ))}
                </ol>
              )}
              {indemnityFull && (
                <span
                  onClick={() => setIndemnityFull(false)}
                  className="toggler">
                  See summary clause
                </span>
              )}
              {!indemnityFull && (
                <span
                  onClick={() => setIndemnityFull(true)}
                  className="toggler">
                  See legal clause
                </span>
              )}
            </div>
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[3] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[3]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[3]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[4] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[4]}
              />
            )}
            <div
              className={`legal-clause-container${
                terminationFull ? ' full' : ' summary'
              }`}>
              {boilerplateParagraphs.length > 1 && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={boilerplateParagraphs[4]}
                  hideToggleFull={true}
                  showFullAgreement={terminationFull}
                />
              )}
              {terminationOls.length && (
                <ol>
                  {terminationOls.map((ol, index) => (
                    <Fragment key={index}>
                      <AgreementItem
                        editingAgreement={editingAgreement}
                        setEditAgreement={setEditAgreement}
                        cancelEditAgreement={cancelEditAgreement}
                        saveUpdatedAgreement={saveUpdatedAgreement}
                        agreementContent={agreementContent}
                        setAgreementContent={setAgreementContent}
                        agreement={ol}
                        hideToggleFull={true}
                        showFullAgreement={terminationFull}
                      />
                    </Fragment>
                  ))}
                </ol>
              )}
              {terminationFull && (
                <span
                  onClick={() => setTerminationFull(false)}
                  className="toggler">
                  See summary clause
                </span>
              )}
              {!terminationFull && (
                <span
                  onClick={() => setTerminationFull(true)}
                  className="toggler">
                  See legal clause
                </span>
              )}
            </div>
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[5] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[5]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[5]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[6] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[6]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[6]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[7] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[7]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[7]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[8] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[8]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[8]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[9] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[9]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[9]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[10] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[10]}
              />
            )}
            <div
              className={`legal-clause-container${
                technologyEvoFull ? ' full' : ' summary'
              }`}>
              {boilerplateParagraphs.length > 1 && (
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={boilerplateParagraphs[10]}
                  hideToggleFull={true}
                  showFullAgreement={technologyEvoFull}
                />
              )}
              {evolvingOls.length && (
                <ol>
                  {evolvingOls.map((ol, index) => (
                    <Fragment key={index}>
                      <AgreementItem
                        editingAgreement={editingAgreement}
                        setEditAgreement={setEditAgreement}
                        cancelEditAgreement={cancelEditAgreement}
                        saveUpdatedAgreement={saveUpdatedAgreement}
                        agreementContent={agreementContent}
                        setAgreementContent={setAgreementContent}
                        agreement={ol}
                        hideToggleFull={true}
                        showFullAgreement={technologyEvoFull}
                      />
                    </Fragment>
                  ))}
                </ol>
              )}
              {technologyEvoFull && (
                <span
                  onClick={() => setTechnologyEvoFull(false)}
                  className="toggler">
                  See summary clause
                </span>
              )}
              {!technologyEvoFull && (
                <span
                  onClick={() => setTechnologyEvoFull(true)}
                  className="toggler">
                  See legal clause
                </span>
              )}
            </div>
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[11] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[11]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[11]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[12] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[12]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[12]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[13] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[13]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[13]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[14] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[14]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[14]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[15] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[15]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[15]}
              />
            )}
            {boilerplateSubtitles[16] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[16]}
              />
            )}
            {boilerplateParagraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[16]}
              />
            )}
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[2] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[2]}
              />
            )}
            {boilerplateParagraphs[2] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[2]}
              />
            )}
            {/* <p style={{ fontSize: 16 }}>
            This copyright agreement shall be governed by and is subject to
            English and Welsh law and parties agree to accept the exclusive
          </p> */}

            <p style={{ fontSize: 16 }}>...</p>

            <p style={{ fontSize: 16 }}>
              I agree to the terms and conditions of this agreement
            </p>

            <div className="questionaire" style={{ marginTop: 10 }}>
              <form>
                <div className="tikbox-form-element">
                  <input
                    type="checkbox"
                    id="agree1"
                    checked={acceptedTerms['agree1']}
                    onChange={acceptAgreementTerms}
                    name="agree1"
                    style={{ width: 25 }}
                  />
                  <label htmlFor="agree1">
                    {user.name}, {moment().format('YYYY-MM-DD')}
                  </label>
                </div>
                <div className="tikbox-form-element">
                  <input
                    type="checkbox"
                    id="agree2"
                    onChange={acceptAgreementTerms}
                    checked={acceptedTerms['agree2']}
                    name="agree2"
                    style={{ width: 25 }}
                  />
                  <label htmlFor="agree2">
                    [name of licensee], [address of licensee], [signature of
                    licensee]
                  </label>
                </div>
              </form>
            </div>
          </div>
          <div className="mb-4">
            {boilerplateSubtitles[17] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateSubtitles[17]}
              />
            )}
            {boilerplateParagraphs[17] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[17]}
              />
            )}
            {boilerplateParagraphs[18] && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={boilerplateParagraphs[18]}
              />
            )}
          </div>
        </div>
      )}

      {/* CCO SECTION */}
      {cco && (
        <AgreementItem
          editingAgreement={editingAgreement}
          setEditAgreement={setEditAgreement}
          cancelEditAgreement={cancelEditAgreement}
          saveUpdatedAgreement={saveUpdatedAgreement}
          agreementContent={agreementContent}
          setAgreementContent={setAgreementContent}
          agreement={{
            // code: 'custom',
            _id: 'custom',
            category: AgreementCategory.LICENSE,
            contentType: AgreementContentType.TITLE,
            content: selectedAgreements.ccoLicenseTitle,
          }}
        />
      )}
      {cco && creativeCommonLicensesData.find((x) => x.code === cco) && (
        <AgreementItem
          editingAgreement={editingAgreement}
          setEditAgreement={setEditAgreement}
          cancelEditAgreement={cancelEditAgreement}
          saveUpdatedAgreement={saveUpdatedAgreement}
          agreementContent={agreementContent}
          setAgreementContent={setAgreementContent}
          agreement={{
            ...creativeCommonLicensesData.find((x) => x.code === cco),
            content: selectedAgreements.ccoLicenseParagraph,
          }}
          cco={cco}
        />
      )}

      {cco && (
        <div className="cco-review">
          <p>
            <a
              className="view-license-link"
              href={ccoData.licenseDeedUrl}
              target="_blank"
              rel="noreferrer">
              <b>
                See the License Deed <FontAwesomeIcon size="1x" icon={faLink} />
              </b>
            </a>
          </p>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}>
            <h3>MARK YOUR WORK</h3>
            <Tooltip title="Copy">
              <FontAwesomeIcon
                onClick={() => copyToClipboard(copyMessage)}
                size="1x"
                icon={faCopy}
              />
            </Tooltip>
          </div>
          <div
            style={{
              backgroundColor: '#c3c3c3',
              border: '1px solid #000',
              padding: '15px 10px',
            }}>
            {copyMessage}
          </div>
        </div>
      )}
      {cco && (
        <TikBoxModal
          open={confirmModifyModal}
          toggle={toggleConfirmModifyModal}
          title="Modifying a CC License">
          <div className="confirm-cco-edit">
            <p>
              If you make a change to the text of any CC license, you may no
              longer refer to it as a Creative Commons or CC license, and you
              must not use any CC trademarks (including the Creative Commons
              name) or branding in connection with the license
            </p>

            <p>
              <a
                href="https://wiki.creativecommons.org/wiki/Modifying_the_CC_licenses"
                target="_blank ">
                <b>For more information</b>{' '}
                <FontAwesomeIcon size="1x" icon={faLink} />
              </a>
            </p>

            <div className="btns">
              <button className="cancel-btn" onClick={cancelEditAgreementCCO}>
                Cancel
              </button>
              <button className="modify-btn" onClick={setEditAgreementCCO}>
                Modify
              </button>
            </div>
          </div>
        </TikBoxModal>
      )}
    </div>
  )
}

export default ReviewContent
