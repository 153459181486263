import { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { HOME, USER_TEMPLATE_AGREEMENT } from '../../../constants/Routes'
import AssignmentContent from './AssignmentContent'
import LicenseContent from './LicenseContent'
import NavigationBtns from './NavigationBtns'
import AgreementHeader from './AgreementHeader'
import RoyaltyContent from './RoyaltyContent'
import BoilerplateContent from './BoilerplateContent'
import {
  getAgreements,
  updateAgreement,
} from '../../../redux/actions/AgreementActions'
import Spinner from '../../../components/common/spinner/Spinner'
import { AgreementCategory, AgreementKeys } from '../../../constants'
import ReviewContent from './ReviewContent'
import { saveUserAgreement } from '../../../redux/actions/UserAgreementActions'
import { toast } from 'react-toastify'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'
import { getCreativeCommonLicenses } from '../../../redux/actions/CreativeCommonLicenseActions'

const perpetualLicenseAgreementsCodes = [
  'LICENSE_CHECKBOX_3',
  'LICENSE_CHECKBOX_4',
]

const emptyAgreement = {
  [AgreementKeys.Assignment]: [],
  [AgreementKeys.License]: [],
  [AgreementKeys.RoyaltyValue]: 0,
  [AgreementKeys.RoyaltyLength]: undefined,
  [AgreementKeys.RoyaltyAcknowledgement]: '',
}
const emptyCCOAgreement = {
  ...emptyAgreement,
  lastEditedCCOAt: null,
  ccoAttribution: [
    { name: 'workTitle', value: '' },
    { name: 'workCreator', value: '' },
    { name: 'workLink', value: '' },
    { name: 'creatorProfileLink', value: '' },
    { name: 'creationYear', value: '' },
  ],
  ccoBoilerplate: [
    {
      name: 'ownLicense',
      value: false,
    },
    {
      name: 'licenseTerms',
      value: false,
    },
    {
      name: 'nonRevocable',
      value: false,
    },
  ],
  ccoLicenseTitle:
    '{{username}} licenses {{imageName}} under the terms of the {{cco}} Creative Commons License',
  ccoLicenseParagraph: '',
}

const AgreementBody = ({
  step,
  addAgreement,
  selectedAgreements,
  setSelectedAgreements,
  removeAgreement,
  acceptedTerms,
  acceptAgreementTerms,
  reviewRef,
  cco,
  setCco,
  knowCCOLicense,
  onChangeAtrributionForm,
  onChangeCCOBoilerplate,
}) => {
  const {
    agreements: { data: agreementData },
    auth: { user },
    assets: { signing },
    creativeCommonLicenses: { data: creativeCommonLicensesData },
  } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [editingAgreement, setEditingAgreement] = useState()
  const [agreementContent, setAgreementContent] = useState()
  const [editingAgreementCCO, setEditingAgreementCCO] = useState()
  const [agreementContentCCO, setAgreementContentCCO] = useState()
  const [confirmModifyModal, setConfirmModifyModal] = useState(false)
  const [agreementBuilder, setAgreementBuilder] = useState({
    questions: [
      {
        timeline: 1,
        active: true,
        question: {
          title: 'Do you know which license you need?',
          selection: 'License Expertise',
        },
        options: [
          {
            title: 'Yes. I know the license I need.',
            selection: 'I know the license I need.',
            activeTimelines: [1, 2],
          },
          {
            title: 'No. I need help selecting a license.',
            selection: 'I need help selecting a license.',
            activeTimelines: [1, 3, 4, 5, 6],
          },
        ],
        answer: '',
        optionsType: 'radio',
      },
      {
        timeline: 2,
        active: true,
        question: {
          title: 'What license do you need?',
          selection: 'Creative Commons License',
        },
        options: creativeCommonLicensesData.map((license) => ({
          title: license.code,
          selection: license.name,
          activeTimelines: [1, 2],
        })),
        answer: '',
        optionsType: 'select',
      },
      {
        timeline: 3,
        active: true,
        question: {
          title: 'Do you want attribution for your work?',
          selection: 'Attribution',
        },
        options: [
          {
            title: 'Yes. Anyone using my work must include proper attribution.',
            selection:
              'Anyone can use my work, even without giving me attribution.',
            activeTimelines: [1, 3, 4, 5, 6],
            cco: 'CC BY 4.0',
          },
          {
            title:
              'No. Anyone can use my work, even without giving me attribution.',
            selection: 'Anyone using my work must include proper attribution.',
            activeTimelines: [1, 3, 7],
            cco: 'CC0 1.0',
          },
        ],
        answer: '',
        optionsType: 'radio',
      },
      {
        timeline: 4,
        active: true,
        question: {
          title: 'Do you want to allow others to use your work commercially?',
          selection: 'Commercial Use',
        },
        options: [
          {
            title: 'Yes. Others can use my work, even for commercial purposes.',
            selection: 'Others can use my work, even for commercial purposes.',
            activeTimelines: [1, 3, 4, 5, 6],
            cco: 'CC BY 4.0',
            setCco: ['CC BY 4.0', 'CC BY-ND 4.0'],
            setCcoChild: ['CC BY 4.0', 'CC BY-SA 4.0'],
          },
          {
            title: 'No. Others can not use my work for commercial purposes.',
            selection: 'Others can not use my work for commercial purposes.',
            activeTimelines: [1, 3, 4, 5, 6],
            cco: 'CC BY-NC 4.0',
            setCco: ['CC BY-NC 4.0', 'CC BY-NC-ND 4.0'],
            setCcoChild: ['CC BY-NC 4.0', 'CC BY-NC-SA 4.0'],
          },
        ],
        answer: '',
        optionsType: 'radio',
      },
      {
        timeline: 5,
        active: true,
        question: {
          title:
            'Do you want to allow others to remix, adapt, or build upon your work?',
          selection: 'Derivative Works',
        },
        options: [
          {
            title: 'Yes. Others can remix, adapt, or build upon my work.',
            selection: 'Others can remix, adapt, or build upon my work.',
            activeTimelines: [1, 3, 4, 5, 6],
            cco: 'CC BY 4.0',
          },
          {
            title: 'No. Others may only use my work in unadapted form.',
            selection: 'Others may only use my work in unadapted form.',
            activeTimelines: [1, 3, 4, 5],
            cco: 'CC BY-ND 4.0',
          },
        ],
        answer: '',
        optionsType: 'radio',
      },
      {
        timeline: 6,
        active: true,
        question: {
          title:
            'Do you want to allow others to share adaptations of your work under any terms?',
          selection: 'Sharing Requiements',
        },
        options: [
          {
            title:
              'Yes. Others can share adaptations of my work under any terms.',
            selection:
              'Others can share adaptations of my work under any terms.',
            activeTimelines: [1, 3, 4, 5, 6],
            cco: 'CC BY 4.0',
          },
          {
            title:
              'No. Others must use the same CC license if they adapt my work.',
            selection:
              'Others must use the same CC license if they adapt my work.',
            activeTimelines: [1, 3, 4, 5, 6],
            cco: 'CC BY-SA 4.0',
          },
        ],
        answer: '',
        optionsType: 'radio',
      },
      {
        timeline: 7,
        active: false,
        question: {
          title: 'Waive Your Copyright',
          selection: 'Copyright Waiver',
        },
        options: [
          {
            title:
              'I hereby waive all copyright and related or neighboring rights together with all associated claims and causes of action with respect to this work to the extent possible under the law.',
            selection: 'I waived copyright',
            activeTimelines: [1, 3, 7],
          },
          {
            title:
              'I have read and understand the terms and intended legal effect of CC0, and hereby voluntarily elect to apply it to this work.',
            selection: 'I waived copyright',
            activeTimelines: [1, 3, 7],
          },
        ],
        answer: [],
        optionsType: 'checkbox',
      },
    ],
    currentTimeline: 1,
  })

  const removeAnswerFromAgreementBuilder = (timeline, value) => {
    let updatedAgreementBuilder = agreementBuilder

    setAgreementBuilder({
      ...updatedAgreementBuilder,
      questions: updatedAgreementBuilder.questions.map((question) => {
        if (question.timeline === timeline) {
          // If not checkbox, just remove the value
          if (question.optionsType !== 'checkbox') {
            question.answer = ''
          } else {
            // If checkbox, remove the value from the array
            question.answer = question.answer.filter(
              (answer) => answer !== value,
            )
          }
        }
        return question
      }),
    })
  }

  const updateAgreementBuilder = (timeline, value) => {
    let updatedAgreementBuilder = agreementBuilder
    const updatedQuestion = updatedAgreementBuilder.questions.find(
      (question) => question.timeline === timeline,
    )
    // Get active questions timelines from selected timeline option
    const updatedQuestionOption = updatedQuestion.options.find(
      (option) => option.title === value,
    )
    const setNextTimelineCco = updatedQuestionOption.setCco
    const setNextChildTimelineCco = updatedQuestionOption.setCcoChild
    let nextTimeline
    let nextChildTimeline
    if (updatedQuestionOption.setCco) {
      const timel = updatedQuestionOption.activeTimelines.findIndex(
        (x) => x === timeline,
      )
      nextTimeline = updatedQuestionOption.activeTimelines[timel + 1]
      nextChildTimeline = updatedQuestionOption.activeTimelines[timel + 2]
    }
    // Update questions active status based of selected timeline active timelines and deactivate the others
    const questions = updatedAgreementBuilder.questions.map(
      (timelineQuestion) => {
        if (
          updatedQuestionOption.activeTimelines.includes(
            timelineQuestion.timeline,
          )
        ) {
          timelineQuestion.active = true
        } else {
          timelineQuestion.active = false
        }
        if (timelineQuestion.timeline === timeline) {
          // If checkbox, which takes more than one answer,
          // add the new entry and use Set to ensure there is no duplicate
          if (timelineQuestion.optionsType === 'checkbox') {
            let newAnswers = timelineQuestion.answer
            newAnswers.push(value)
            newAnswers = [...new Set(newAnswers)]
            timelineQuestion.answer = newAnswers
          } else {
            timelineQuestion.answer = value
          }
          if (updatedQuestionOption.cco) {
            setCco(updatedQuestionOption.cco)
          }
        }
        if (
          setNextTimelineCco &&
          nextTimeline &&
          timelineQuestion.timeline === nextTimeline
        ) {
          timelineQuestion.options = [
            {
              ...timelineQuestion.options[0],
              cco: setNextTimelineCco[0],
            },
            {
              ...timelineQuestion.options[1],
              cco: setNextTimelineCco[1],
            },
          ]
          timelineQuestion.answer = ''
        }
        if (
          setNextChildTimelineCco &&
          nextChildTimeline &&
          timelineQuestion.timeline === nextChildTimeline
        ) {
          timelineQuestion.options = [
            {
              ...timelineQuestion.options[0],
              cco: setNextChildTimelineCco[0],
            },
            {
              ...timelineQuestion.options[1],
              cco: setNextChildTimelineCco[1],
            },
          ]
          timelineQuestion.answer = ''
        }
        return timelineQuestion
      },
    )

    setAgreementBuilder({
      ...updatedAgreementBuilder,
      questions,
    })
  }

  const getNavigationParams = () => {
    const getQuestionTimeline = agreementBuilder.questions.find(
      (question) => question.timeline === agreementBuilder.currentTimeline,
    )
    const questionAnswerOption = getQuestionTimeline?.options?.find((option) =>
      getQuestionTimeline.optionsType === 'checkbox'
        ? getQuestionTimeline.answer.includes(option.title)
        : option.title === getQuestionTimeline.answer,
    )
    const findActiveOptionIndex =
      questionAnswerOption?.activeTimelines?.findIndex(
        (timeline) => timeline === agreementBuilder.currentTimeline,
      )
    return {
      getQuestionTimeline,
      questionAnswerOption,
      findActiveOptionIndex,
    }
  }

  const navigateToNextAgreementTimeline = () => {
    const { findActiveOptionIndex, questionAnswerOption } =
      getNavigationParams()
    if (questionAnswerOption.activeTimelines[findActiveOptionIndex + 1]) {
      setAgreementBuilder({
        ...agreementBuilder,
        currentTimeline:
          questionAnswerOption.activeTimelines[findActiveOptionIndex + 1],
      })
    }
  }

  const navigateToPreviousAgreementTimeline = () => {
    const { getQuestionTimeline } = getNavigationParams()
    const currentTimelineIndex =
      getQuestionTimeline.options[0].activeTimelines.findIndex(
        (timeline) => timeline === agreementBuilder.currentTimeline,
      )
    const prevTimeline =
      getQuestionTimeline.options[0].activeTimelines[currentTimelineIndex - 1]
    if (prevTimeline) {
      setAgreementBuilder({
        ...agreementBuilder,
        currentTimeline: prevTimeline,
      })
    }
  }

  const navigateToSpecificTimeline = (timeline) => {
    setAgreementBuilder({
      ...agreementBuilder,
      currentTimeline: timeline,
    })
  }

  const setEditAgreement = (id, content) => {
    if (cco) {
      setEditingAgreementCCO(id)
      setAgreementContentCCO(content)
      setConfirmModifyModal(true)
      return
    }
    setEditingAgreement(id)
    setAgreementContent(content)
  }

  const cancelEditAgreement = () => {
    setEditingAgreement()
    setAgreementContent()
  }

  const setEditAgreementCCO = () => {
    setEditingAgreement(editingAgreementCCO)
    setAgreementContent(agreementContentCCO)
    setConfirmModifyModal(false)
  }

  const cancelEditAgreementCCO = () => {
    setEditingAgreementCCO()
    setAgreementContentCCO()
    setConfirmModifyModal(false)
  }

  const toggleConfirmModifyModal = (value) => {
    setConfirmModifyModal(value)
  }

  const saveUpdatedAgreement = async () => {
    if (cco) {
      setSelectedAgreements({
        ...selectedAgreements,
        lastEditedCCOAt: new Date(),
        ...(editingAgreement === 'custom'
          ? { ccoLicenseTitle: agreementContent }
          : { ccoLicenseParagraph: agreementContent }),
      })
      cancelEditAgreement()
      return
    }
    if (editingAgreement) {
      dispatch(updateAgreement(editingAgreement, agreementContent))
      cancelEditAgreement()
    }
  }

  const handleGetCreativeCommonLicenses = async () => {
    await dispatch(getCreativeCommonLicenses())
  }

  useEffect(() => {
    if (knowCCOLicense) {
      const newQuestions = agreementBuilder.questions.map((question, index) => {
        if (knowCCOLicense === 'true') {
          return {
            ...question,
            ...(index === 0
              ? { answer: question.options[0]?.title, active: true }
              : index === 1
              ? { active: true, answer: cco }
              : { active: false }),
          }
        } else {
          return {
            ...question,
            ...(index === 0
              ? { answer: question.options[1]?.title, active: true }
              : index === 1
              ? { active: false }
              : { active: true }),
          }
        }
      })
      setAgreementBuilder({
        ...agreementBuilder,
        questions: [...newQuestions],
        currentTimeline: knowCCOLicense === 'true' ? 2 : 3,
      })
    }
  }, [knowCCOLicense])

  useEffect(() => {
    handleGetCreativeCommonLicenses()
  }, [])

  if (!agreementData) return <div>Retrieving data...</div>

  return (
    <div className="agreement-interface-body">
      <div className="agreement-interface-body__content">
        {step === 1 ? (
          <AssignmentContent
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            removeAgreement={removeAgreement}
            data={agreementData.filter(
              (agreement) =>
                agreement.category === AgreementCategory.ASSIGNMENT,
            )}
          />
        ) : step === 2 ? (
          <LicenseContent
            cco={cco}
            setCco={setCco}
            knowCCOLicense={knowCCOLicense}
            agreementBuilder={agreementBuilder}
            updateAgreementBuilder={updateAgreementBuilder}
            navigateToNextAgreementTimeline={navigateToNextAgreementTimeline}
            removeAnswerFromAgreementBuilder={removeAnswerFromAgreementBuilder}
            navigateToPreviousAgreementTimeline={
              navigateToPreviousAgreementTimeline
            }
            navigateToSpecificTimeline={navigateToSpecificTimeline}
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            removeAgreement={removeAgreement}
            data={agreementData.filter(
              (agreement) => agreement.category === AgreementCategory.LICENSE,
            )}
          />
        ) : step === 3 ? (
          <RoyaltyContent
            cco={cco}
            knowCCOLicense={knowCCOLicense}
            onChangeAtrributionForm={onChangeAtrributionForm}
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            removeAgreement={removeAgreement}
            data={agreementData.filter(
              (agreement) => agreement.category === AgreementCategory.ROYALTY,
            )}
            licenseData={agreementData.filter(
              (agreement) => agreement.category === AgreementCategory.LICENSE,
            )}
            perpetualLicenseAgreementsCodes={perpetualLicenseAgreementsCodes}
          />
        ) : step === 4 ? (
          <BoilerplateContent
            cco={cco}
            knowCCOLicense={knowCCOLicense}
            onChangeCCOBoilerplate={onChangeCCOBoilerplate}
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            setSelectedAgreements={setSelectedAgreements}
            removeAgreement={removeAgreement}
            data={agreementData.filter(
              (agreement) =>
                agreement.category === AgreementCategory.BOILERPLATE,
            )}
          />
        ) : (
          <ReviewContent
            cco={cco}
            knowCCOLicense={knowCCOLicense}
            setEditAgreementCCO={setEditAgreementCCO}
            cancelEditAgreementCCO={cancelEditAgreementCCO}
            toggleConfirmModifyModal={toggleConfirmModifyModal}
            confirmModifyModal={confirmModifyModal}
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            reviewRef={reviewRef}
            selectedAgreements={selectedAgreements}
            agreementData={agreementData}
            user={user}
            signingAssets={signing}
            acceptedTerms={acceptedTerms}
            acceptAgreementTerms={acceptAgreementTerms}
            data={agreementData.filter(
              (agreement) =>
                agreement.category === AgreementCategory.BOILERPLATE,
            )}
          />
        )}
      </div>
    </div>
  )
}

const Agreement = () => {
  const history = useHistory()
  const location = useLocation()
  const reviewRef = useRef()
  const dispatch = useDispatch()
  const {
    template,
    cco: ccoState,
    knowCCOLicense,
    copyright,
    editAsset,
  } = location.state || {}
  const [cco, setCco] = useState(ccoState)

  const {
    agreements: { data, loading },
    assets,
    agreementTemplates: { data: agreementTemplatesData },
    userAgreements,
    auth: { user },
    creativeCommonLicenses: { data: creativeCommonLicensesData },
  } = useSelector((state) => state)

  const [step, setStep] = useState(knowCCOLicense ? 2 : 1)
  const [selectedAgreements, setSelectedAgreements] = useState(
    _.cloneDeep(knowCCOLicense ? emptyCCOAgreement : emptyAgreement),
  )
  const [acceptedTerms, setAcceptedTerms] = useState({
    agree1: false,
    agree2: false,
  })
  const [downloading, setDownloading] = useState(false)

  const nextStep = () => {
    if (step < 5) {
      const isAssignmentSelected =
        step === 1 && !!selectedAgreements[AgreementKeys.Assignment].length
      setStep((prevState) => prevState + (isAssignmentSelected ? 2 : 1))
    }
  }
  const prevStep = () => {
    if (step === 1) {
      history.push(HOME)
    }
    if (step === 2 && knowCCOLicense) {
      history.push(USER_TEMPLATE_AGREEMENT)
    }
    const isAssignmentSelected =
      step === 3 && !!selectedAgreements[AgreementKeys.Assignment].length
    setStep((prevState) => prevState - (isAssignmentSelected ? 2 : 1))
  }

  const onChangeAtrributionForm = (e) => {
    const newCCOAttribution = selectedAgreements.ccoAttribution
    const updateAttributionIndex = selectedAgreements.ccoAttribution.findIndex(
      (item) => item.name === e.target.name,
    )
    newCCOAttribution[updateAttributionIndex] = {
      name: e.target.name,
      value: e.target.value,
    }
    setSelectedAgreements({
      ...selectedAgreements,
      ccoAttribution: newCCOAttribution,
    })
  }

  const onChangeCCOBoilerplate = (e) => {
    const newCCOBoilerplate = selectedAgreements.ccoBoilerplate
    const updateBoilerIndex = selectedAgreements.ccoBoilerplate.findIndex(
      (item) => item.name === e.target.name,
    )
    newCCOBoilerplate[updateBoilerIndex] = {
      name: e.target.name,
      value: e.target.checked,
    }
    setSelectedAgreements({
      ...selectedAgreements,
      ccoBoilerplate: newCCOBoilerplate,
    })
  }

  const acceptAgreementTerms = (e) => {
    setAcceptedTerms({ ...acceptedTerms, [e.target.name]: e.target.checked })
  }

  const addAgreement = (key, value) => {
    const existingAgreement = selectedAgreements[key]
    if (
      [
        AgreementKeys.RoyaltyLength,
        AgreementKeys.RoyaltyValue,
        AgreementKeys.RoyaltyAcknowledgement,
      ].includes(key)
    ) {
      setSelectedAgreements({
        ...selectedAgreements,
        [key]: value,
      })
    } else {
      const existingIndex = existingAgreement.findIndex(
        (entry) => entry.index === value,
      )
      if (existingIndex >= 0) {
        existingAgreement.splice(existingIndex, 1, { index: value })
      } else {
        existingAgreement.push({ index: value })
      }

      let perpetualAgreement
      if (key === AgreementKeys.License) {
        const licenseAgreement = data.find((x) => x._id === value)
        if (perpetualLicenseAgreementsCodes.includes(licenseAgreement.code)) {
          // Update royalty period to perpetual
          perpetualAgreement = data.find(
            (x) => x.code === 'ROYALTY_RADIO_4',
          )?._id
        }
      }
      setSelectedAgreements({
        ...selectedAgreements,
        [key]: existingAgreement,
        ...(perpetualAgreement ? { royaltyLength: perpetualAgreement } : {}),
      })
    }
  }
  const removeAgreement = (key, value) => {
    const existingAgreement = selectedAgreements[key]
    if (
      ![
        AgreementKeys.RoyaltyLength,
        AgreementKeys.RoyaltyValue,
        AgreementKeys.RoyaltyAcknowledgement,
      ].includes(key)
    ) {
      let removePerpetualDefault = false
      const existingIndex = existingAgreement.findIndex(
        (entry) => entry.index === value,
      )

      if (key === AgreementKeys.License) {
        // Find license agreement with the value to be removed
        const licenseAgreement = data.find((x) => x._id === value)
        if (perpetualLicenseAgreementsCodes.includes(licenseAgreement.code)) {
          // Check if there is a perpetual agreement left
          const allLicensePerpetualAgreements = data.reduce(
            (acc, agreement) => {
              if (perpetualLicenseAgreementsCodes.includes(agreement.code)) {
                acc.push(agreement._id)
              }
              return acc
            },
            [],
          )
          const selectedPerpetualLicenses = existingAgreement.filter((x) => {
            return allLicensePerpetualAgreements.includes(x.index)
          })
          removePerpetualDefault =
            selectedPerpetualLicenses.length !== 2 ? true : false
        }
      }
      if (existingIndex >= 0) {
        existingAgreement.splice(existingIndex, 1)
        setSelectedAgreements({
          ...selectedAgreements,
          [key]: existingAgreement,
          ...(removePerpetualDefault ? { royaltyLength: undefined } : {}),
        })
      }
    }
  }

  const uploadAgreement = async (isDraft = false) => {
    if (!isDraft && !assets.signing.length) {
      toast.error('Upload an asset')
      return 'error'
    }
    if (assets.signing) {
      dispatch(
        saveUserAgreement({
          ...selectedAgreements,
          cco,
          fromCCO: !!cco,
          template,
          copyright,
          isDraft,
          assets: assets.signing.map(({ _id }) => _id),
        }),
      )
    }
  }

  const closeAgreement = async () => {
    // TODO: Save draft agreement
    await uploadAgreement(true)
    setSelectedAgreements(_.cloneDeep(emptyAgreement))
    history.goBack()
  }

  const handleGetAgreements = async () => {
    await dispatch(getAgreements())
  }

  const downloadAgreement = async () => {
    setDownloading(true)
    triggerGoogleAnalyticsEvent('downloading_agreement', {
      userId: user?.id,
    })
    setTimeout(async () => {
      const response = await uploadAgreement(false)
      if (response !== 'error') {
        setDownloading(false)
        setSelectedAgreements(_.cloneDeep(emptyAgreement))
        history.push(HOME)
        toast.success('Agreement is downloading...')
      }
    }, 2000)
  }

  useEffect(() => {
    handleGetAgreements()
  }, [])

  useEffect(() => {
    if (template) {
      const agreementTemplate = agreementTemplatesData.find(
        (agreement) => agreement._id === template,
      )
      if (agreementTemplate) {
        setSelectedAgreements(agreementTemplate)
      }
    }
    if (editAsset) {
      const userAgreement = userAgreements.data.find(
        (x) => x.assetId === editAsset._id,
      )
      if (userAgreement) {
        setSelectedAgreements(userAgreement)
      }
    }
  }, [template, editAsset])

  useEffect(() => {
    const ccoData = creativeCommonLicensesData.find((x) => x.code === cco)
    if (cco) {
      setSelectedAgreements({
        ...selectedAgreements,
        ccoLicenseParagraph: ccoData.content,
      })
    }
  }, [cco])

  return (
    <div className="agreement">
      <AgreementHeader cco={cco} closeAgreement={closeAgreement} step={step} />
      {loading && !data ? (
        <Spinner />
      ) : (
        <div className="agreement-interface">
          <AgreementBody
            onChangeAtrributionForm={onChangeAtrributionForm}
            onChangeCCOBoilerplate={onChangeCCOBoilerplate}
            addAgreement={addAgreement}
            selectedAgreements={selectedAgreements}
            setSelectedAgreements={setSelectedAgreements}
            removeAgreement={removeAgreement}
            step={step}
            acceptedTerms={acceptedTerms}
            acceptAgreementTerms={acceptAgreementTerms}
            reviewRef={reviewRef}
            cco={cco}
            setCco={setCco}
            knowCCOLicense={knowCCOLicense}
          />
          <NavigationBtns
            acceptedTerms={acceptedTerms}
            step={step}
            prevStep={prevStep}
            nextStep={nextStep}
            downloadAgreement={downloadAgreement}
            downloading={downloading}
            cco={cco}
            knowCCOLicense={knowCCOLicense}
            selectedAgreements={selectedAgreements}
          />
        </div>
      )}
    </div>
  )
}

export default Agreement
