import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AssetsIcon } from '../icons/AssetsIcon'
import AppModal from './AppModal'
import { PRIVACY_POLICY, TERMS_CONDITIONS } from '../../../constants/Routes'
import { useCallback, useEffect, useRef, useState } from 'react'
import UploadBox from '../../upload-box/UploadBox'
import { ASSET_LIBRARY, ASSET_UPLOAD_TYPE } from '../../../constants'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'
import LibraryAssetUploader from '../../../services/library-assets/LibraryAssetUploader'
import FileUtils from '../../../utils/FileUtils'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  removeSignAsset,
  saveAssets,
} from '../../../redux/actions/AssetActions'
import { SignUpForm } from '../../../pages/main/landing-page/SignUp'
import { SignInForm } from '../../../pages/main/landing-page/SignIn'

const titles = {
  1: 'Terms and Conditions',
  2: 'Add watermark to your image for free',
  3: 'Your watermarked asset has been successfully downloaded.',
}
export const WatermarkModal = ({ open, onClose }) => {
  const {
    assets: { loading: assetsLoading, signing: assetsSigning },
  } = useSelector((state) => state)
  const [step, setStep] = useState(1)
  const [assetType, setAssetType] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [, setUploading] = useState(false)
  const [uploadAssetType] = useState(ASSET_UPLOAD_TYPE.SINGLE)
  const [copyright, setCopyright] = useState('')
  const [uploadedAssets, setUploadedAssets] = useState([])
  const [acceptedFiles, setAcceptedFiles] = useState([])
  const [showPrivateEmailForm, setShowPrivateEmailForm] = useState(false)
  const [isSignInOpen, setIsSignInOpen] = useState(false)
  const [isSignUpOpen, setIsSignUpOpen] = useState(true)
  const dispatch = useDispatch()
  const assetsUploadProgresses = useRef([])

  const changeStep = () => {
    setStep((prevState) => prevState + 1)
  }

  const onDrop = useCallback(
    async (acceptedFiles) => {
      await uploadAssetsToS3(acceptedFiles, setUploadProgress)
    },
    [copyright],
  )

  const uploadAssetsToS3 = async (acceptedFiles, setUploadProgress) => {
    try {
      setUploading(true)
      const authorId = 'watermark'
      triggerGoogleAnalyticsEvent('uploading_asset', {
        userId: authorId,
      })
      const uploadData = acceptedFiles.map((file, index) => {
        const fileName = file.name
        const fileExt = fileName.substring(fileName.lastIndexOf('.'))
        const fileNameUrl = `${moment().format('x')}${index}${fileExt}`
        return {
          file,
          fileNameUrl,
        }
      })
      const prevProgressesState = assetsUploadProgresses.current
      assetsUploadProgresses.current = [
        ...prevProgressesState,
        ...uploadData.map((data) => ({
          file: data.file,
          name: data.fileNameUrl,
          progress: 0,
        })),
      ]
      const uploadedAssets = await Promise.all(
        uploadData.map((data) => {
          const uploadProgress = (value) => {
            const prevProgresses = assetsUploadProgresses.current
            setUploadProgress(value)
            const index = assetsUploadProgresses.current.findIndex(
              (x) => x.name === data.fileNameUrl,
            )
            if (index !== -1) {
              assetsUploadProgresses.current.splice(index, 1)
            }
            assetsUploadProgresses.current = [
              ...prevProgresses,
              {
                file: data.file,
                name: data.fileNameUrl,
                progress: value,
              },
            ]
          }
          return LibraryAssetUploader.saveAsLibraryAsset(
            data.file,
            data.fileNameUrl,
            `assets/${FileUtils.getFileType(data.file.type)}s`,
            authorId,
            '',
            true,
            uploadProgress,
          )
        }),
      )
      setAcceptedFiles(acceptedFiles)
      setUploadedAssets(uploadedAssets)
      return uploadedAssets
    } catch (error) {
      toast.error('Error uploading assets')
    } finally {
      setUploading(false)
    }
  }

  const handleSaveAsset = async (assets) => {
    await dispatch(saveAssets(assets, 'watermark', copyright))
  }
  const resetAssetUplaoded = async (name = false) => {
    let clearData = !name
    await dispatch(removeSignAsset(!name, name))
    const prevProgressesState = assetsUploadProgresses.current
    let newData = []
    if (name) {
      newData = prevProgressesState.filter((x) => x.name !== name)
    }
    assetsUploadProgresses.current = newData
    if (!newData.length) {
      clearData = true
    }
    if (clearData) {
      setAssetType('')
      setUploadProgress(0)
      setUploading(false)
    }
  }

  const submitForm = (e) => {
    e.preventDefault()

    if (uploadedAssets.length) {
      handleSaveAsset(
        uploadedAssets.map((asset, index) => ({
          fileKey: asset.key,
          fileUrl: asset.Location,
          originalFileName: acceptedFiles[index].name,
          fileType: FileUtils.getFileType(acceptedFiles[index].type),
        })),
        copyright,
      )
    }
  }

  useEffect(() => {
    if (assetsSigning.length) {
      const assetWatermarked = assetsSigning[assetsSigning.length - 1]
      const location = assetWatermarked.Location
      changeStep()
      // Open the image URL in a new tab
      window.open(location, '_blank')
      resetAssetUplaoded()
    }
  }, [assetsSigning])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={titles[step]}
      hideHeader={true}
      showCloseIcon={false}
      customClass="watermark-modal">
      <div className="watermark-modal-content">
        {step === 1 && (
          <div className="watermark-modal-content__step-one">
            <AssetsIcon width={54} height={54} />
            <p style={{ maxWidth: 400, textAlign: 'center' }}>
              To use TikBox’s free watermarker please confirm you agree with our{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={TERMS_CONDITIONS}>
                terms
              </Link>{' '}
              and{' '}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                to={PRIVACY_POLICY}>
                privacy policy
              </Link>
            </p>

            <button onClick={changeStep}>Accept and Continue</button>
          </div>
        )}
        {step === 2 && (
          <div className="watermark-modal-content__step-two">
            <form onSubmit={submitForm}>
              <input
                className="watermark-input"
                type="text"
                placeholder="© Nageela Yusuf"
                required
                value={copyright}
                onChange={(e) => {
                  setCopyright(e.target.value)
                }}
              />
              <br />
              <UploadBox
                type={ASSET_LIBRARY.IMAGE.type}
                setAssetType={setAssetType}
                onDrop={onDrop}
                uploading={assetType === ASSET_LIBRARY.IMAGE.type}
                uploadProgress={uploadProgress}
                resetAssetUplaoded={resetAssetUplaoded}
                assetType={assetType}
                uploadAssetType={uploadAssetType}
                assetsUploadProgresses={assetsUploadProgresses.current}
                contractTitle="Watermark image"
              />
              <br />
              <button
                className="btn submit"
                type="submit"
                disabled={uploadProgress !== 100 || assetsLoading}>
                {assetsLoading ? 'Submitting...' : 'Submit'}
              </button>
            </form>
          </div>
        )}

        {step === 3 && (
          <div className="watermark-modal-content__step-three">
            <p>
              Adding a copyright notice is the first step in protecting your
              asset, why not:
            </p>
            <ul>
              <li>
                license it with TikBox tools so you can monetize on your
                creativity ?
              </li>
              <li>save it for later </li>
            </ul>

            <button onClick={changeStep}>Start licensing for free</button>
          </div>
        )}

        {step === 4 && (
          <div className="watermark-modal-content__step-four">
            <div className="tikbox-signup">
              <div className="form watermark-signup">
                {isSignInOpen ? (
                  <WatermarkSignIn
                    setIsSignInOpen={setIsSignInOpen}
                    setIsSignUpOpen={setIsSignUpOpen}
                  />
                ) : isSignUpOpen ? (
                  <WatermarkSignUp
                    showPrivateEmailForm={showPrivateEmailForm}
                    setShowPrivateEmailForm={setShowPrivateEmailForm}
                    setIsSignInOpen={setIsSignInOpen}
                    setIsSignUpOpen={setIsSignUpOpen}
                  />
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </AppModal>
  )
}

const WatermarkSignUp = ({
  showPrivateEmailForm,
  setShowPrivateEmailForm,
  setIsSignInOpen,
  setIsSignUpOpen,
}) => {
  return (
    <>
      <SignUpForm
        showPrivateEmailForm={showPrivateEmailForm}
        setShowPrivateEmailForm={setShowPrivateEmailForm}
      />
      <div className="hint">
        Already a member?{' '}
        <button
          className="link"
          onClick={() => {
            setIsSignInOpen(true)
            setIsSignUpOpen(false)
          }}>
          Sign in
        </button>
      </div>
    </>
  )
}

const WatermarkSignIn = ({ setIsSignInOpen, setIsSignUpOpen }) => {
  return (
    <>
      <SignInForm />
      <div className="hint">
        Don’t you have an account?{' '}
        <button
          className="link"
          onClick={() => {
            setIsSignInOpen(false)
            setIsSignUpOpen(true)
          }}>
          Sign up
        </button>
      </div>
    </>
  )
}
