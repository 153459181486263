import { useState } from 'react'
import SignUpModal from '../main/landing-page/SignUp'
import SignInModal from '../main/landing-page/SignIn'
import Header from '../../components/header/Header'
import { Footer } from '../../components/footer/Footer'

const PrivacyPolicy = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false)
  const [isSignInOpen, setIsSignInOpen] = useState(false)
  return (
    <>
      <Header
        setIsSignUpOpen={setIsSignUpOpen}
        setIsSignInOpen={setIsSignInOpen}
      />
      <div className="privacy-policy">
        <h2>Privacy policy</h2>
        <p>
          Necessary cookies enable core functionality such as security, network
          management, and accessibility. You may disable these by changing your
          browser settings, but this may affect how the website functions.
        </p>
        <p>
          This website uses cookies from Google Analytics, Meta Pixel, Jetpack,
          LinkedIn and YouTube to collect anonymous information such as the
          number of visitors to the site, how the visitors use the features on
          our site and which are the most popular pages.
        </p>
        <p>Keeping these cookies enabled helps us to improve our website.</p>
        <p>All tracking and analytics via cookies are in compliance with the UK GDPR 
          and EU GDPR. Users can adjust their cookie settings to manage tracking and data collection preferences</p>
        
        <h2>BACKGROUND:</h2>
        <p>
          TikBox.io Ltd understands that your privacy is important to you and
          that you care about how your personal data is used. We respect and
          value the privacy of everyone who visits our website:{' '}
          <a href="https://tikbox.io/blog">https://tikbox.io/blog</a> (Our
          Site), and web application (Our Application):{' '}
          <a href="https://tikbox.io">https://tikbox.io</a> and{' '}
          <a href="https://api.tikbox.io">https://api.tikbox.io</a>{' '}
          (collectively ‘the Platform’) and will only collect and use personal
          data in ways that are described here, and in a way that is consistent
          with our obligations and your rights under the law.
        </p>
        <p>
          This policy sets out the basis on which any personal data about you
          will be processed and applies to the processing of personal data in
          connection with our software-as-a-service licensing, labelling, and
          monetisation Application, and Our Site which contains informational
          material; and our direct marketing activities. The most up-to-date
          version of our Privacy Policy can be found at{' '}
          <a href="https://tikbox.io/privacy-policy">
            https://tikbox.io/privacy-policy
          </a>
        </p>
        <p>
          You agree that any use by you of the Platform shall constitute your
          acceptance of Our Privacy Policy and our Terms and Conditions of Use.
          Our Privacy Policy sets out the terms on which we process any personal
          data we collect from you, or that you provide to us, while Our Terms
          of Use, set out the terms of use governing your use of the Platform.
          It is recommended that you print a copy of the Privacy Policy and
          Terms of Use for your future reference. If you do not agree with our
          Privacy Policy and Terms of Use, please refrain from using the
          Platform.
        </p>

        <p>TikBox.io Ltd will collect and process personal data only to the extent permitted by law and in compliance with applicable data protection regulations.</p>
<p>TikBox.io Ltd is not liable for any personal data users voluntarily disclose in public or unintended settings on the Platform.
</p>
        <h4>1. Definitions and Interpretation</h4>
        <p>
          In this Policy the following terms shall have the following meanings:{' '}
        </p>
        <div className="privacy-policy__table definitions-interpretation">
          <div className="privacy-policy__table-row">
            <h4>"Account"</h4>
            <p>
              means an account required to access and/or use certain areas and
              features of Our Platform;
            </p>
          </div>
          <div className="privacy-policy__table-row">
            <h4>"Cookie"</h4>
            <p>
              means a small text file placed on your computer or device by Our
              Application when you visit certain parts of Our Application and/or
              when you use certain features of Our Platform. Details of the
              Cookies used by Our Application are set out in Part 12, below; and
            </p>
          </div>
          <div className="privacy-policy__table-row">
            <h4>"Cookie Law"</h4>
            <p>
              means the relevant parts of the Privacy and Electronic
              Communications (EC Directive) Regulations 2003;
            </p>
          </div>
        </div>
        <h4>2. Information About the Date Controller and Processor</h4>
        <p>
          Our Application is owned and operated by TikBox.io Ltd. We are a
          limited company registered in England and Wales under company number
          15424686. Our registered address is 20 Wenlock Road, London, England,
          N1 7GU
        </p>
        <p>
          For the purposes of data protection legislation in the European Union:
        </p>
        <ul>
          <li>
            When TikBox.io processes your personal data for its business
            purposes, listed below, TikBox.io is the data controller (i.e. the
            person that determines the purposes and manner in which your
            personal data are processed): (i) Identity Data; (ii) Contact Data;
            (iii) Transaction Data; (iv) Technical Data; (v) Profile Data; and
            (vi) Usage Data
          </li>
          <li>
          When TikBox.io processes your personal data on your behalf (e.g., for managing content uploaded by you), TikBox.io acts as a data processor, only processing data according to your instructions.
          </li>
        </ul>

        <h4>3. What Does This Policy Cover?</h4>
        <p>
          This Privacy Policy applies only to your use of Our Application. Our
          Application may contain links to other platforms and websites. Unless
          stated otherwise in this agreement, please note that we have no
          control over how your data is collected, stored, or used by other
          platforms and we advise you to check the privacy policies of any such
          platforms before providing any data to them.
        </p>
        <p>
          When we act as a data processor on your behalf, we will carry out the
          processing activities below:
        </p>
        <ul style={{ listStyle: 'none' }}>
          <li style={{ marginBottom: 2 }}>
            a. <b>Subject matter of the processing:</b> the provision of the
            Platform and Services to you.
          </li>
          <li style={{ marginBottom: 2 }}>
            b. <b>Duration of the processing:</b> starting on the date you
            provide the relevant personal data to us (for example by adding a
            licensors or licensees details to an agreement when either party is
            creating a license) until you either remove the relevant personal
            data from the Application or delete your account with TikBox.io.
          </li>
          <li style={{ marginBottom: 2 }}>
            c. <b>Nature and purpose of the processing:</b> adding relevant personal details to the documents you create on the Application, for example, licensor details in a licensing agreement. Processing of personal data will strictly follow your documented instructions, ensuring compliance with applicable data protection regulations.
          </li>
          <li style={{ marginBottom: 2 }}>
            d. <b>Type of personal data involved:</b> name, address, email
            address, royalty fees and potentially other details relating to
            employment and expertise.{' '}
          </li>
        </ul>
        <p>
          <b>Categories of data subject:</b> the asset’s creator, copyright
          holder, licensing agent/adviser and licensee depending on what assets
          you upload.
        </p>

        <h4>4. What Is Personal Data?</h4>
        <p>
          Personal data is defined by the General Data Protection Regulation (EU
          Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018
          (collectively, “the Data Protection Legislation”) as ‘any information
          relating to an identifiable person who can be directly or indirectly
          identified in particular by reference to an identifier’.
        </p>
        <p>
          Personal data is, in simpler terms, any information about you that
          enables you to be identified. Personal data covers obvious information
          such as your name and contact details, but it also covers less obvious
          information such as identification numbers, electronic location data,
          and other online identifiers.
        </p>
        <h4>5. What Are My Rights?</h4>
        <p>
          Under the Data Protection Legislation, you have the following rights,
          which we will always work to uphold:
        </p>
        <p>
          a) The right to be informed about our collection and use of your
          personal data. This Privacy Policy should tell you everything you need
          to know, but you can always contact us to find out more or to ask any
          questions using the details in Part 13.
        </p>
        <p>
          b) The right to access the personal data we hold about you. Part 11
          will tell you how to do this.
        </p>
        <p>
          c) The right to have your personal data rectified if any of your
          personal data held by us is inaccurate or incomplete. Please contact
          us using the details in Part 13 to find out more.
        </p>
        <p>
          d) The right to be forgotten, i.e. the right to ask us to delete or
          otherwise dispose of any of your personal data that we hold. Please contact
          us using the details in Part 13 to find out more.
        </p>
        <p>
          e) The right to restrict (i.e. prevent) the processing of your
          personal data.
        </p>
        <p>
          f) The right to object to us using your personal data for a particular
          purpose or purposes.
        </p>
        <p>
          g) The right to withdraw consent. This means that, if we are relying
          on your consent as the legal basis for using your personal data, you
          are free to withdraw that consent at any time.
        </p>
        <p>
          h) The right to data portability. This means that, if you have
          provided personal data to us directly, you have the right to request
          that we provide you with a copy of all of it, and to transmit your
          personal data to another data controller in a structured, commonly
          used and machine-readable format, where it is technically and
          economically feasible for us to do so
        </p>
        <p>
          i) Rights relating to automated decision-making and profiling. We do
          not use your personal data in this way.
        </p>
        <p>
          j) For more information about Our use of your personal data or
          exercising your rights as outlined above, please contact us using the
          details provided in Part 11.
        </p>
        <p>
          k) It is important that your personal data is kept accurate and
          up-to-date. If any of the personal data we hold about you changes,
          please keep us informed as long as we have that data.
        </p>
        <p>
          l) Further information about your rights can also be obtained from the
          Information Commissioner’s Office or your local Citizens Advice
          Bureau.
        </p>
        <p>
          m) If you have any cause for complaint about our use of your personal
          data, you have the right to lodge a complaint with the Information
          Commissioner’s Office. We would welcome the opportunity to resolve
          your concerns ourselves, however, so please contact us first, using
          the details in Part 11.
        </p>

        <h4>6 Personal information we may process about you</h4>
        <p>
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together as follows:
        </p>
        <ul>
          <li>
            {' '}
            <b>Identity Data,</b> which includes your first name, last name,
            social media profile, user ID, company name, job position and
            company department.
          </li>
          <li>
            {' '}
            <b>Contact Data,</b> which includes your email address and postal
            address.
          </li>
          <li>
            {' '}
            <b>Content Data,</b> any personal data contained within the documents, video, audio, or image files you uploaded to Our Platform for processing and signing. This data will be processed solely to provide you with the services on the Platform and will not be used for generating AI content or shared with third parties without your explicit consent.
          </li>
          <li>
            {' '}
            <b>Transaction Data,</b> which includes details about payments to
            and from you and other details of services you have purchased from
            us.
          </li>
          <li>
            {' '}
            <b>Technical Data,</b> which includes your internet protocol (IP)
            address, your login data, browser type and version, time zone
            setting and location you use to access the Platform.
          </li>
          <li>
            {' '}
            <b>Profile Data,</b> which includes your password, subscriptions
            purchased by you, your preferences, feedback and survey responses.
          </li>
          <li>
            {' '}
            <b>Usage Data,</b> which includes information about how you use Our
            Application{' '}
          </li>
          <li>
            {' '}
            <b>Customer Support Data</b> includes feedback and survey responses.
            If you have done a webchat or contacted us by email or on live-chat
            we will retain a record of that conversation
          </li>
        </ul>
        <p>
          If you sign up to use the Services and create a profile this will
          include:
        </p>
        <ul>
          <li>
            {' '}
            <b>Profile/Identity Data:</b> first name, last name and gender.
          </li>
          <li>
            {' '}
            <b>Contact Data</b> includes, address, email address.
          </li>
          <li>
            <b>Communications Data</b> includes your preferences in receiving
            marketing from us and your communication preferences.
          </li>
        </ul>

        <p>
          If you purchase a Service or complete a royalty/fee transaction with
          us this will include:
        </p>
        <ul>
          <li>
            <b>Billing Data:</b> debit/credit card holder information
            (name/billing address) of whoever purchased something from the
            Platform (processed by Stripe)
          </li>
          <li>
            {' '}
            <b>Financial Data:</b> Although we rely on Stripe to retain and
            store your credit/debit card information and we do not retain it
            ourselves, in the course of your use of the Platform, you may state
            the salaries of yourselves or your staff.
          </li>
          <li>
            {' '}
            <b>Transaction Data</b> includes details about payments from you or
            to you for products and purchases made on the Platform{' '}
          </li>
        </ul>

        <h4>7. What Data Do You Collect and How?</h4>
        <p>
          Depending upon your use of the Platform, we may collect and hold some
          personal and non-personal data.
        </p>
        <p>
        We will only act on your documented instructions unless we are required by law to act without such instructions. You can provide us with your instructions by making selections and inputting information on the Application, or when you communicate with us through our live chat support or by email. Processing will comply with data protection laws, and we will not use uploaded content for purposes outside of service provision, such as generating AI-based content, without explicit consent.
        </p>
        <p>
          If we are required by law to process personal data and don’t have your
          instructions to do so, we will notify you before we carry out the
          processing required by law (unless the law prohibits us from notifying
          you).
        </p>

        <p>
          We also collect, use and share aggregated data such as statistical or
          demographic data for our business purposes. Aggregated data may be
          derived from your personal data but is not considered personal data in
          law as this data does not directly or indirectly reveal your identity.
          For example, we may aggregate your usage data to calculate the
          percentage of users accessing a specific Application feature. However,
          if we combine or connect aggregated data with your personal data so
          that it can directly or indirectly identify you, we treat the combined
          data as personal data which will be used in accordance with this
          privacy policy.
        </p>
        <p>
          We do not collect any special categories of personal data about you
          (this includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offenses.
        </p>
        <p>
        We are the developer of TikBox.io, a proprietary software product (the “TikBox Software”). The TikBox Software provides template copyright agreements, adds copyright metatags in the assets you upload to the Application, and offers tools for users to upload/edit agreements and make royalty payments online (together, the "Services"). All personal data and uploaded content processed on the Platform will only be used for the defined purposes stated here, without any further processing for AI generation without user consent.
        </p>
        <p>
          Our Platform operates to both market to individuals and organisation
          TikBox Software; as well as make TikBox Software available for use and
          purchase. To the extent we collect personal data via our Platform, our
          main purpose is to better serve our current and potential customers
          and to improve the function of our Platform. A breakdown of the
          personal data we may process is set out in the table below.
        </p>

        <div className="privacy-policy__table data-collection">
          <div className="privacy-policy__table-content">
            <div className="privacy-policy__table-row">
              <p>
                <b>Type of data</b>
              </p>
              <p>
                <b>Why it's processed?</b>
              </p>
              <p>
                <b>Legal basis for processing</b>
              </p>
              <p>
                <b>Period data is stored</b>
              </p>
              <p>
                <b>Will data be shared with third parties?</b>
              </p>
              <p>
                <b>Will data be transferred outside EEA</b>
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>
                Technical Data – The IP address or other unique identifier of
                the device you used to access the Platform.
              </p>{' '}
              <p>To improve the function of the Platform.</p>{' '}
              <p>
                Processing is necessary for our legitimate interests (i.e. to
                understand who is visiting the Platform).
              </p>{' '}
              <p>3 years</p>{' '}
              <p>
                For analytics and error tracking purposes, may share this data
                with organizations such as Amplitude, Inc., Twitter Inc.,
                Microsoft Corp., Google LLC and Facebook Inc. or their or its
                affiliates. This helps us improve Our Application.
              </p>{' '}
              <p>
                Amplitude, Inc., Twitter Inc., Microsoft Corp., Google LLC and
                Facebook Inc. or their or its affiliates may store and process
                this data in the USA. To ensure these transfers are lawful, we rely on the fact that each entity has implemented appropriate safeguards, such as Standard Contractual Clauses (SCCs), approved by the European Commission, or other lawful mechanisms in compliance with GDPR.
              </p>
            </div>

            <div className="privacy-policy__table-row">
              <p>Technical Data and Usage Data</p>{' '}
              <p>To improve the function of the Platform</p>{' '}
              <p>
                Processing is necessary for our legitimate interests (i.e. to
                understand who is visiting the Platform).
              </p>{' '}
              <p>3 years</p>{' '}
              <p>
                We may share this data with organizations such as Amplitude,
                Inc. for analytics and error tracking purposes. This helps us
                improve our Platform.
              </p>{' '}
              <p>
                Amplitude, Inc.. stores and processes this data in the USA. To
                ensure this transfer is lawful, we rely on the fact that
                Amplitude, Inc. is certified as compliant with SOC type 2, ISO
                27001 EU Privacy Shield, and GDPR
              </p>
            </div>

            <div className="privacy-policy__table-row">
              <p>Identity Data</p>{' '}
              <p>
                To enable us to engage in direct marketing (such as newsletters
                or marketing emails for products and services provided by us
                that we believe will be of interest to you).
              </p>{' '}
              <p>
                Processing is necessary for our legitimate interests (i.e.
                direct marketing activities to customers and potential
                customers).
              </p>{' '}
              <p>
                Until such time as you inform us you don’t want to continue
                receiving marketing communications – you have the right to
                unsubscribe at any time.
              </p>{' '}
              <p>
                We use third party CRM or analytics providers such as HubSpot
                Inc. to manage our sales and marketing activities.
              </p>{' '}
              <p>
                HubSpot Inc. stores and processes personal information in the
                USA. To ensure this transfer is lawful, we rely on the fact that
                HubSpot Inc. is certified as complying with the EU-US Privacy
                Shield regime.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Contact Data</p>{' '}
              <p>
                Records of your communication with us may be used to enable us
                to maintain records with our potential and actual clients.
              </p>{' '}
              <p>
                Processing is necessary to take steps at your request prior to
                entering into a contract. If this doesn’t apply, then you have
                provided your consent.
              </p>{' '}
              <p>For up to 6 years</p>{' '}
              <p>
                We may use third party hosted services such as Google to manage
                our activities.
              </p>{' '}
              <p>
                We may use hosted services (such as Google) which stores and
                processes personal information in the USA or elsewhere. Where we
                do so, we will ensure that the transfer is lawful.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>
                Identity Data, Contact Data, Technical Data and Profile Data.
              </p>{' '}
              <p>
                We may use any personal data you provide to us as part of any
                survey to improve Our Application or services.
              </p>{' '}
              <p>
                Processing is necessary for our legitimate interests (i.e. to
                understand the requirements of our potential and actual
                clients).
              </p>{' '}
              <p>
                For as long as we are using such data to improve our services
                and for a reasonable period afterwards.
              </p>{' '}
              <p>
                We may use third party hosted services such as Google to manage
                our activities.
              </p>{' '}
              <p>
                We may use hosted services (such as Google) which stores and
                processes personal information in the USA or elsewhere. Where we
                do so, we will ensure that the transfer is lawful.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Identity Data, Contact Data and Profile Data.</p>{' '}
              <p>To register you as a new customer.</p>{' '}
              <p>Performance of a contract with you.</p>{' '}
              <p>Until such time as you delete your account.</p>{' '}
              <p>
                We share this data with organizations such as MongoDb Inc., to
                help store user account information.
              </p>{' '}
              <p>
                Atlas stores this data in the USA. To ensure this transfer is
                lawful, we rely on the fact that we have a contract in place
                with Atlas (i.e. the Standard Contractual Clauses) which is
                approved by the European Commission and gives personal data the
                same protection it has in Europe.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Identity Data, Contact Data and Profile Data.</p>{' '}
              <p>
                To process users’ access to the Application, including ensuring
                secure access to the Application
              </p>{' '}
              <p>
                Performance of a contract with you. Necessary to comply with a
                legal obligation.
              </p>{' '}
              <p>Until such time as you delete your account.</p>{' '}
              <p>
                We share this data with organizations such as Auth0, Inc., to
                ensure secure access to the Application.
              </p>{' '}
              <p>
                Amazon Web Services Inc. stores this data in the EU and is
                certified as complying with GDPR.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Identity Data, Contact Data and Transaction Data.</p>{' '}
              <p>
                To manage payments, fees / charges and to collect / recover
                money owed to us.
              </p>{' '}
              <p>
                Performance of a contract with you. Processing is necessary for
                our legitimate interests (i.e. to recover debts due to us).
              </p>{' '}
              <p>For as long as debts remain outstanding.</p>{' '}
              <p>
                We will share your email address with Stripe Inc. so that
                payment receipts can be sent to you by email.
              </p>{' '}
              <p>
                Stripe Inc. stores this data in the USA. To ensure this transfer
                is lawful, we rely on the fact that Stripe Inc. is certified as
                complying with the EU-US Privacy Shield regime.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Content Data.</p>{' '}
              <p>
                To edit, video, audio, image, and other multimedia files
                together.
              </p>{' '}
              <p>
                Performance of a contract with you. Necessary to comply with a
                legal obligation.
              </p>{' '}
              <p>
                Until such time as you delete the multimedia or your account.
              </p>{' '}
              <p>
                To help us store multimedia, we share this data with
                organisations such as MongoDb Inc., Amazon Web Services, Inc. or
                its affiliates.
              </p>{' '}
              <p>
                Atlas, Amazon Web Services, Inc. or its affiliates store this
                data in the USA. To ensure this transfer is lawful, we rely on
                the fact that we have contracts in place (i.e. the Standard
                Contractual Clauses) which is approved by the European
                Commission and gives personal data the same protection it has in
                Europe.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Identity Data, Contact Data, and Profile Data.</p>{' '}
              <p>
                To manage our relationship with users which will include
                notifying users about changes to our terms or privacy policy.
              </p>{' '}
              <p>
                Performance of a contract with you. Necessary to comply with a
                legal obligation.
              </p>{' '}
              <p>Until such time as you delete your account.</p>{' '}
              <p>
                We may use third party CRM providers such as HubSpot Inc. to
                manage our sales and marketing activities.
              </p>{' '}
              <p>
                HubSpot Inc. stores this data in the USA. To ensure this
                transfer is lawful, we rely on the fact that HubSpot Inc. is
                certified as complying with the EU-US Privacy Shield regime.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Identity Data, Contact Data and Technical Data.</p>{' '}
              <p>
                To administer and protect our business and the Platform (e.g.
                making users aware of new features or system issues,
                troubleshooting, data analysis, testing, system maintenance,
                support, reporting and hosting of data).
              </p>{' '}
              <p>
                Necessary for our legitimate interests (i.e. for running our
                business, provision of administration and IT services, network
                security, to prevent fraud and in the context of a business
                reorganisation or group restructuring exercise). Necessary to
                comply with a legal obligation.
              </p>{' '}
              <p>
                Until such time as you delete your account and a reasonable
                period thereafter.
              </p>{' '}
              <p>
                We may share this data with organisations such as:Functional
                Software, Inc. (t/a Sentry) to help identify web application
                crashes that affect a given user; and Amplitude Inc. to provide
                user behaviour tracking and in-app messaging.LogRocket, Inc. to
                provide user session debugging
              </p>{' '}
              <p>
                Functional Software, Inc. (t/a Sentry) and Amplitude Inc. may
                store this data in the USA. To ensure this transfer is lawful,
                we rely on the fact that Functional Software, Inc. (t/a Sentry)
                and Amplitude Inc. are certified as complying with the EU-US
                Privacy Shield regime.Trint has executed a GDPR-compliant DPA
                with LogRocket, Inc.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Technical Data and Usage Data</p>
              <p>
                To use data analytics to improve Platform services, customer
                relationships, and experiences.
              </p>{' '}
              <p>
                Necessary for our legitimate interests (i.e. to define types of
                customers for our services, to keep Our Application updated and
                relevant, to develop our business and to inform our marketing
                strategy).
              </p>{' '}
              <p>
                As there is an annual seasonality in our business, we will
                retain data for analytics purposes for 3 years.
              </p>{' '}
              <p>
                We may share this data with organizations such as Functional
                Software, Inc. (t/a Sentry) and Amplitude Inc. for analytics and
                error tracking purposes. This helps us improve the Application.
              </p>{' '}
              <p>
                Functional Software, Inc. (t/a Sentry) and Amplitude Inc. may
                store this data in the USA. To ensure this transfer is lawful,
                we rely on the fact that Functional Software, Inc. (t/a Sentry)
                and Amplitude Inc. are certified as complying with the EU-US
                Privacy Shield regime.
              </p>
            </div>
            <div className="privacy-policy__table-row">
              <p>
                Sharing Personal Information with third parties and of third
                parties (funds/investors)
              </p>{' '}
              <p>
                {' '}
                (a) Identity <br />
                (b) Contact
              </p>
              <p>
                (a) Legitimate Interest The user has inputted certain Personal
                Data about connected third parties and circulated that Personal
                Data to other connected third parties because those third
                parties have indicated that they wish to participate in a
                funding round or enter into another contractual relationship.
                <br />
                <br /> The User will have obtained consent from the third
                parties prior to entering the data into the Platform{' '}
              </p>
            </div>
          </div>
        </div>

        <h4>8. How Do You Ensure My Data is Processed Securely?</h4>
        <p>
          We have implemented appropriate technical and organisational measures
          to ensure we can securely process personal data. This includes
          measures to address the particular risks that are presented by
          processing, for example from accidental or unlawful destruction, loss,
          alteration, unauthorised disclosure of, or access to the personal data
          we process on your behalf. When implementing these measures, we will
          take into account:
        </p>
        <ul style={{ listStyle: 'none' }}>
          <li>
            a. the state of technological development and the availability of
            relevant technology (for the purposes of Article 32(1) of the GDPR);
          </li>
          <li>
            b. the costs of implementation of any measures (which may include,
            where appropriate, pseudonymising and encrypting personal data,
            ensuring confidentiality, integrity, availability and resilience of
            our systems and services, ensuring that availability of and access
            to personal data can be restored in a timely manner after an
            incident, and regularly assessing and evaluating the effectiveness
            of the technical and organisational measures we have adopted);
          </li>
          <li>c. the nature, scope, context and purposes of processing; and</li>
          <li>d. the risks for the rights and freedoms of the data subject</li>
        </ul>

        <h4>9. How Do You Use My Personal Data?</h4>
        <p>
          Under the Data Protection Legislation, we must always have a lawful
          basis for using personal data. The following table describes how long
          we may keep your personal data, how we may use your personal data, and
          Our lawful bases for doing so:
        </p>
        <p>
          Where we send electronic marketing communications we will provide an
          option to unsubscribe or opt-out of further communication, or you may
          opt out by contacting us at admin [at] tikbox.io
        </p>
        <p>
          We will not sell your personal data (or any other data you provide us
          with) to third-parties for marketing purposes.
        </p>

        <h4>10. How Do You Collect Personal Data</h4>
        <p>We may obtain personal data about you in two main ways:</p>
        <p>Direct interactions.</p>
        <ul>
          <li>
            You may, for example, provide us with your personal data by filling
            in forms on our Platform, by corresponding with us by post, phone,
            email or otherwise, or by providing us with your business card at an
            event.
          </li>
        </ul>
        <p>Automated technologies.</p>
        <ul>
          <li>
            Personal data may be collected automatically (such as the automatic
            recognition of your IP address or placement of cookies on your
            device).
          </li>
        </ul>

        <h4 style={{ backgroundColor: 'yellow', maxWidth: 500 }}>
          11. How and Where Do You Store or Transfer My Personal Data?
        </h4>
        <p>
          The personal data that we collect from you is likely to be transferred
          to and stored at a destination outside of the European Economic Area
          (“EEA”). This data may also be processed by staff operating outside of
          the EEA who work for us or for one of our business partners or service
          providers. Further information is provided in the table above.
        </p>
        <p>
          The security of your personal data is essential to us, and to protect
          your data, we take a number of important measures, including the
          following:
        </p>
        <ul>
          <li>
            limiting access to your personal data to those employees, agents,
            contractors, and other third parties with a legitimate need to know
            and ensuring that they are subject to duties of confidentiality;
          </li>
          <li>
            procedures for dealing with data breaches (the accidental or
            unlawful destruction, loss, alteration, unauthorised disclosure of,
            or access to, your personal data) including notifying you and/or the
            Information Commissioner’s Office where we are legally required to
            do so;
          </li>
        </ul>
        <p>
          If we need to transfer any personal data outside the UK, we ensure
          that appropriate safeguards are in place to keep the data secure and
          that effective legal remedies are available for data subjects.{' '}
        </p>

        <h4>12.Your Authority to Appoint Sub-processors</h4>
        <p>
          By accepting these Terms, you authorise us to appoint third parties to
          process personal data as a sub-processor as we see fit. This clause
          will be deemed to be your general written authorisation under Article
          28(2) of the GDPR. We will let you know if we plan to make any changes
          concerning the addition or replacement of any sub-processors before we
          make the change.
        </p>
        <p>
          When we engage a sub-processor, we will ensure that we have a written
          contract in place with the sub-processor (or substantially on that
          sub-processor’s standard terms of business) that sets out sufficient
          guarantees to implement appropriate technical and organisational
          measures to ensure that the processing will meet the requirements of
          the relevant Data Protection Legislation.{' '}
        </p>
        <p>
          You can review the our GDPR-compliant Privacy Policy and Data
          Processing Ademdum of our sub-processor, SignNow here:
          <a href="https://www.signnow.com/privacy_notice">
            https://www.signnow.com/privacy_notice
          </a>{' '}
          and their Data Processing Addendum:{' '}
          <a href="https://www.signnow.com/dpa">https://www.signnow.com/dpa</a>
        </p>

        <h4>13. Do You Share My Personal Data?</h4>
        <p>
          We will not share any of your personal data with any third parties for
          any purposes, subject to the following exceptions:
        </p>
        <div className="privacy-policy__table personal-data">
          <div className="privacy-policy__table-row">
            <p>
              <b>
                Purpose of sharing data and third party(s) to which data might
                be shared
              </b>
            </p>
            <p>
              <b>Use justification</b>
            </p>
          </div>
          <div className="privacy-policy__table-row">
            <p>If you request we do so</p>
            <p> You have provided your consent</p>
          </div>
          <div className="privacy-policy__table-row">
            <p>
              If TikBox.io Limited or substantially all of its assets are
              acquired by a third party, personal information about our
              customers will be one of the transferred assets.
            </p>
            <p>Legitimate interests (i.e. to dispose of our business).</p>
          </div>
          <div className="privacy-policy__table-row">
            <p>
              If we are under a duty to disclose or share your personal data in
              order to comply with any legal obligation or to protect the
              rights, property, or safety of TikBox.io Limited, our customers,
              or others. This includes exchanging information with other
              companies and organizations for the purposes of fraud protection.
            </p>{' '}
            <p>
              Processing is necessary for compliance with a legal obligation, or
              processing is necessary in order to protect the vital interests of
              a natural person.
            </p>
          </div>
          <div className="privacy-policy__table-row">
            <p>
              We may disclose your personal information to third parties, the
              courts and/or regulators or law enforcement agencies in connection
              with proceedings or investigations anywhere in the world where we
              are compelled or believe it is reasonable to do so. Where
              permitted, we will direct any such request to you or notify you
              before responding unless to do so would prejudice the prevention
              or detection of a crime.
            </p>{' '}
            <p>
              Processing is necessary for compliance with a legal obligation, or
              processing is necessary in order to protect the vital interests of
              a natural person. Alternatively, legitimate interests (i.e. to
              cooperate with law enforcement and regulatory authorities).
            </p>
          </div>
          <div className="privacy-policy__table-row">
            <p>
              Sharing Personal Information with third parties and of third
              parties (licensor/licensee)
            </p>
            <p>
              Processing is necessary when users have inputted certain Personal
              Data about themselves, and circulated that Personal Data between
              themselves because those parties have indicated that they wish to
              make an agreement.
            </p>
          </div>
        </div>

        <h4>14. Can I Withhold Information?</h4>
        <p>
          You may access certain areas of Our Platform without providing any
          personal data at all. However, to use all features and functions
          available on Our Application you are required to submit or allow for
          the collection of certain data.
        </p>
        <h4>15. How Can I Access My Personal Data?</h4>
        <p>
          If you want to know what personal data we have about you, you can ask
          us for details of that personal data and for a copy of it (where any
          such personal data is held). This is known as a “subject access
          request”.
        </p>
        <p>
          All subject access requests should be made in writing and sent to the
          email shown in Part 13.
        </p>
        <p>
          There is not normally any charge for a subject access request. If your
          request is ‘manifestly unfounded or excessive’ (for example, if you
          make repetitive requests) a fee may be charged to cover Our
          administrative costs in responding.
        </p>
        <p>
          We will respond to your subject access request within one month of
          receiving it. Normally, we aim to provide a complete response,
          including a copy of your personal data within that time. In some
          cases, however, particularly if your request is more complex, more
          time may be required up to a maximum of three months from the date we
          receive your request. You will be kept fully informed of Our progress.
        </p>

        <h4>16. How Do You Use Cookies?</h4>
        <p>
          Our Application may place and access certain first-party Cookies on
          your computer or device. First-party Cookies are those placed directly
          by us and are used only by us. We use Cookies to facilitate and
          improve your experience of Our Application and to provide and improve
          Our services.
        </p>
        <p>
          By using Our Platform, you may also receive certain third-party
          Cookies on your computer or device. Third-party Cookies are those
          placed by Platforms, services, and/or parties other than us.
          Third-party Cookies are used on Our Application to improve your
          experience of Our Application and to provide and improve Our services.
          For more details, please refer to the table below.
        </p>
        <p>
          All Cookies used by and on Our Application are used in accordance with
          current Cookie Law.
        </p>
        <p>
          Before Cookies are placed on your computer or device, you will be
          shown a pop-up banner requesting your consent to set those Cookies. By
          giving your consent to the placing of Cookies you are enabling us to
          provide the best possible experience and service to you. You may, if
          you wish, deny consent to the placing of Cookies; however certain
          features of Our Application may not function fully or as intended.
        </p>
        <p>
          Certain features of Our Application depend on Cookies to function.
          Cookie Law deems these Cookies to be “strictly necessary”. These
          Cookies are shown in the table below. Your consent will not be sought
          to place these Cookies, but it is still important that you are aware
          of them. You may still block these Cookies by changing your internet
          browser’s settings, but please be aware that Our Application may not
          work properly if you do so.
        </p>
        <p>
          The following first-party, and essential, Cookies may be placed on
          your computer or device:
        </p>

        <div className="privacy-policy__table cookies">
          <div className="privacy-policy__table-content">
            <div className="privacy-policy__table-row">
              <p>Name of Cookie</p>
              <p>Purpose</p>
              <p>Duration</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>moove_gdpr_popup</p>
              <p>
                Remembers a user’s cookie preference & consent given or
                withdrawn for the current website.
              </p>
              <p>1 year</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>__stripe_mid</p>
              <p>
                This cookie is set by Stripe payment gateway. This cookie is
                used to enable payment on the website without storing any
                payment information on a server.
              </p>
              <p>11 months</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>__stripe_sid</p>
              <p>
                This cookie is set by Stripe payment gateway. This cookie is
                used to enable payment on the website without storing any
                payment information on a server.
              </p>
              <p>30 mins</p>
            </div>
          </div>
        </div>
        <p>
          and the following third-party Cookies may be placed on your computer
          or device:
        </p>

        <div className="privacy-policy__table cookies-2">
          <div className="privacy-policy__table-content">
            <div className="privacy-policy__table-row">
              <p>Name of Cookie</p> <p>Provider</p> <p>Purpose</p> <p>Type</p>{' '}
              <p>Duration</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>tk_or</p> <p>JetPack</p>{' '}
              <p>
                This is a referral cookie used for analysing referrer behaviour
                for Jetpack{' '}
              </p>
              <p>Performance</p> <p>4 years</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>tk_r3d</p> <p>JetPack</p>{' '}
              <p>
                Used for the internal metrics of user activities to improve user
                experience
              </p>{' '}
              <p>Performance</p> <p>3 days</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>tk_lr</p> <p>JetPack</p>{' '}
              <p>
                This is a referral cookie used for analysing referrer behaviour
                for Jetpack
              </p>{' '}
              <p>Performance</p> <p>1 year</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>VISITOR_INFO1 _LIVE</p> <p>YouTube</p>{' '}
              <p>
                Used to track the information of the embedded YouTube videos on
                a website.
              </p>{' '}
              <p>Advertisement</p> <p>5 months</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>YSC</p> <p>YouTube</p>{' '}
              <p>Used to track the views of embedded videos.</p>{' '}
              <p>Performance </p>
              <p></p>
            </div>
            <div className="privacy-policy__table-row">
              <p>_ga</p> <p>Google Analytics</p>{' '}
              <p>
                Used to calculate statistical data on how the visitor uses the
                website. The cookie stores information anonymously and assign a
                randomly generated number to identify unique visitors.
              </p>{' '}
              <p>Analytics</p> <p>2 years</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>_gid</p> <p>Google Analytics</p>{' '}
              <p>
                Used to calculate statistical data on how the visitor uses the
                website. The cookie stores information anonymously and assign a
                randomly generated number to identify unique visitors.
              </p>{' '}
              <p>Analytics</p> <p>1 day</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>_gat_gtag_UA_ 175559667_1</p> <p>Google Analytics</p>{' '}
              <p>Used to distinguish users.</p> <p>Analytics</p> <p>1 minute</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>_fbp</p> <p>Facebook</p>{' '}
              <p>
                Used to show relevant advertisements to the users and measure
                and improve the advertisements.
              </p>{' '}
              <p>Advertisement</p> <p>2 months</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Fr</p> <p>Facebook</p>{' '}
              <p>
                Targeted advertisements, including tracking user across websites
                that have Facebook pixel.
              </p>{' '}
              <p>Advertisement</p> <p>2 months</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>IDE</p> <p>Google DoubleClick</p>{' '}
              <p>
                Used by Google DoubleClick and stores information about how the
                user uses the website and any other advertisement before
                visiting the website. This is used to present users with ads
                that are relevant to them according to the user profile.
              </p>{' '}
              <p>Advertisement</p> <p>1 year</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>wc_cart_hash_# wc_fragments_#</p> <p>WooCommerce</p>{' '}
              <p>Remembers what’s in your shopping cart</p> <p>Payment</p>{' '}
              <p>Session</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>Lang</p> <p>LinkedIn</p>{' '}
              <p>This cookie is used to set default locale/language</p>{' '}
              <p>Performance</p> <p>Session</p>
            </div>
            <div className="privacy-policy__table-row">
              <p>UserMatchHistory</p> <p>LinkedIn</p>{' '}
              <p>This cookie is used by LinkedIn Ads to help ID syncing</p>{' '}
              <p>Advertisement</p> <p>30 days</p>
            </div>
          </div>
        </div>

        <p>
          Our Application also uses local storage to store information locally
          on your computer or mobile device. Our Application uses local storage
          to save multimedia to avoid loss if the Platform closes unexpectedly,
          to speed up the delivery of some services, and to allow the Platform
          to continue providing some services without an internet connection.{' '}
        </p>
        <h4>17. How Do I Contact You?</h4>
        <p>
          To contact us about anything to do with your personal data and data
          protection, including to make a subject access request, please e-mail
          us at admin [at] tikbox.io
        </p>
        <h4>18. Changes to this Privacy Policy</h4>
        <p>
          We may change this Privacy Notice from time to time. This may be
          necessary, for example, if the law changes, or if we change Our
          business in a way that affects personal data protection.
        </p>
        <p>
          Any changes will be immediately posted on Our Application and you will
          be deemed to have accepted the terms of the Privacy Policy on your
          first use of Our Application following the alterations. We recommend
          that you check this page regularly to keep up-to-date. This Privacy
          Policy was last updated on 1st August 2024
        </p>
      </div>
      {isSignUpOpen && (
        <SignUpModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      {isSignInOpen && (
        <SignInModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      <Footer />
    </>
  )
}

export default PrivacyPolicy
