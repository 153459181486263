import {
  GET_CREATIVE_COMMON_LICENSES,
  GET_CREATIVE_COMMON_LICENSES_LOADING,
} from '../../redux/actions/Types'

const initialState = {
  data: [],
  loading: false,
}

export default function CreativeCommonLicenseReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case GET_CREATIVE_COMMON_LICENSES:
      return {
        ...state,
        data: action.payload,
      }
    case GET_CREATIVE_COMMON_LICENSES_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    default:
      return state
  }
}
