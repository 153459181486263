export const VideoPreviewIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#F9F9F9" />
      <path
        d="M10 19C10 15.7002 10 14.0502 11.0251 13.0251C12.0503 12 13.7002 12 17 12H18C21.2998 12 22.9497 12 23.9749 13.0251C25 14.0502 25 15.7002 25 19V21C25 24.2998 25 25.9497 23.9749 26.9749C22.9497 28 21.2998 28 18 28H17C13.7002 28 12.0503 28 11.0251 26.9749C10 25.9497 10 24.2998 10 21V19Z"
        stroke="#383838"
        strokeWidth="1.5"
      />
      <path
        d="M25 16.9059L25.1259 16.802C27.2417 15.0562 28.2996 14.1834 29.1498 14.6048C30 15.0263 30 16.4236 30 19.2181V20.7819C30 23.5765 30 24.9737 29.1498 25.3952C28.2996 25.8166 27.2417 24.9438 25.1259 23.198L25 23.0941"
        stroke="#383838"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.5 19C20.3284 19 21 18.3284 21 17.5C21 16.6716 20.3284 16 19.5 16C18.6716 16 18 16.6716 18 17.5C18 18.3284 18.6716 19 19.5 19Z"
        stroke="#383838"
        strokeWidth="1.5"
      />
    </svg>
  )
}
