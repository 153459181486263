export const AssetUploadCancelIcon = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5993 13.6L2.40039 2.40002M2.40159 13.6L13.6004 2.40002"
        stroke="#383838"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
