import React from 'react'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import logo from '../../../assets/images/TikBoxLogoBlue.png'

const AppModal = ({
  open,
  children,
  onClose,
  title,
  hideHeader = false,
  showCloseIcon = true,
  customClass = '',
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      classNames={{ modal: `modalApp ${customClass}` }}
      showCloseIcon={showCloseIcon}>
      {!hideHeader && <img src={logo} alt="logo" />}
      <h2>{title}</h2>
      <div className="content">{children}</div>
    </Modal>
  )
}

export default AppModal
