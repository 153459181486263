export const SoundUploadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.4125 20.4643L18.4125 20.4643C17.8994 20.9472 17.2078 21.2174 16.1006 21.3575C14.982 21.4991 13.5168 21.5 11.5 21.5H10.7273C9.08594 21.5 7.8936 21.4994 6.9687 21.403C6.05177 21.3074 5.45789 21.1224 4.99104 20.7935C4.70483 20.5918 4.45176 20.3534 4.23903 20.0859L4.23902 20.0859C3.89586 19.6544 3.70288 19.1079 3.60254 18.2551C3.50081 17.3905 3.5 16.2741 3.5 14.7273V12.1818C3.5 10.6976 3.50008 9.08403 3.55818 7.69434C3.58722 6.99964 3.63048 6.36872 3.6942 5.84295C3.75914 5.30715 3.84133 4.91748 3.93377 4.68421L3.93377 4.68421C4.57466 3.06698 6.25649 2.50001 7.83836 2.5L13.8182 2.5C15.6596 2.5 16.4519 2.50714 17.0696 2.72397L17.0696 2.72398C18.0952 3.084 18.8925 3.84101 19.2672 4.78635L19.2672 4.78637C19.3741 5.05602 19.4357 5.37858 19.4676 5.86167C19.4997 6.34924 19.5 6.96497 19.5 7.81818V14C19.5 15.9006 19.4988 17.2727 19.3494 18.3184C19.2026 19.3465 18.9213 19.9854 18.4125 20.4643Z"
        fill="url(#paint0_linear_2020_4191)"
        stroke="white"
      />
      <path
        d="M14.773 7.08162C14.7092 7.03813 14.6357 7.01104 14.5591 7.00273C14.4824 6.99442 14.4049 7.00513 14.3333 7.03394L9.6052 8.94129C9.51797 8.97632 9.44306 9.03675 9.39001 9.11488C9.33696 9.19301 9.30818 9.28529 9.30733 9.37998V13.4045C9.08819 13.3297 8.85776 13.2942 8.62648 13.2996C7.71395 13.2996 7 13.8909 7 14.649C7 15.4072 7.71395 15.9985 8.62648 15.9985C8.89816 16.01 9.16863 15.9558 9.41536 15.8405C9.66209 15.7252 9.87793 15.5521 10.0449 15.3357C10.1126 15.2942 10.1688 15.2362 10.2085 15.167C10.2481 15.0979 10.2699 15.0198 10.2719 14.9399V11.5353L14.0544 10.0714V12.4556C13.8295 12.3769 13.5925 12.3398 13.3546 12.3459C12.4421 12.3459 11.7281 12.9372 11.7281 13.6954C11.7281 14.4535 12.4421 15.0448 13.3546 15.0448C13.6263 15.0563 13.8968 15.0021 14.1435 14.8868C14.3902 14.7715 14.6061 14.5985 14.773 14.382C14.8407 14.3405 14.897 14.2825 14.9366 14.2133C14.9763 14.1442 14.9981 14.0661 15 13.9862V7.47263C14.9973 7.39358 14.9751 7.31646 14.9355 7.2482C14.8959 7.17995 14.8401 7.1227 14.773 7.08162Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2020_4191"
          x1="11.048"
          y1="3.42492"
          x2="11.048"
          y2="21.9999"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0B8FF" />
          <stop offset="1" stopColor="#8D98F8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
