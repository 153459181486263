import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DOMPurify from 'dompurify'
import { registerUser, removeAuthErrors } from '../../redux/actions/AuthActions'

import AuthFooter from './AuthFooter'
import { SignInLink } from './SignIn'
import { HOME, SIGN_UP } from '../../constants/Routes'
import NavigationHeader from '../navigation/NavigationHeader'
import { toast } from 'react-toastify'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

const ErrorPill = ({ message }) => {
  return <span style={{ color: '#d9534f', fontSize: 12 }}>{message}</span>
}

const SignUpPage = () => (
  <>
    <div className={'container mx-auto signInWraper'}>
      <NavigationHeader />
      <div className="w-full max-w-sm signInContent">
        <div className={'text-2xl mb-3 font-bold text-center'}>
          Register an Account
        </div>
        <SignUpForm />

        <SignInLink />
      </div>
    </div>
    <AuthFooter />
  </>
)

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      email: '',
      passwordOne: '',
      passwordTwo: '',
      reason: '',
      errors: {
        username: '',
        email: '',
        passwordOne: '',
        passwordTwo: '',
      },
      showPrivateEmailForm: false,
    }
  }

  onChange = (event) => {
    const nameRegex = /^[a-zA-Z\s'-]+$/
    let err = ''
    if (!event.target.value) {
      err = `${event.target.name} is required`
    } else if (event.target.name === 'passwordOne') {
      if (event.target.value.length < 6) {
        err = 'Your password should be at least six characters'
      } else {
        err = ''
      }
    } else {
      if (event.target.name === 'passwordTwo') {
        if (event.target.value !== this.state.passwordOne) {
          err = 'Your passwords should be the same'
        } else {
          err = ''
        }
      } else if (event.target.name === 'username') {
        const testUsername = nameRegex.test(event.target.value)
        if (!testUsername) {
          err =
            'Invalid name. Only letters, spaces, hyphens, and apostrophes are allowed.'
        }
      } else {
        err = ''
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        [event.target.name]: err,
      },
    })
    this.setState({
      [event.target.name]: DOMPurify.sanitize(event.target.value),
    })
  }

  onBlur = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(HOME)
      return
    }
  }

  componentDidUpdate() {
    const registerError = this.props.auth.registerError
    if (registerError) {
      toast.dismiss()
      toast.error(registerError)

      this.props.removeAuthErrors()
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('sign_up', { method: 'Form' })
    const params = new URLSearchParams(this.props.location.search)
    let referralToken = params.get('referralToken')

    const { username, email, passwordOne, passwordTwo, reason } = this.state

    const domain = email.split('@')[1]
    if (
      [
        'gmail.com',
        'hotmail.com',
        'yahoo.com',
        'outlook.com',
        'aol.com',
        'zoho.com',
        'mail.com',
        'protomail.com',
        'icloud.com',
        'yandex.com',
        'gmx.com',
      ].includes(domain) &&
      !reason
    ) {
      return this.setState({ showPrivateEmailForm: true })
    }

    const newUser = {
      name: username,
      email: email,
      password: passwordOne,
      password2: passwordTwo,
      referralToken,
      reason,
    }

    this.props.registerUser(newUser, this.props.history)
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      reason,
      showPrivateEmailForm,
      errors: {
        username: usernameErr,
        email: emailErr,
        passwordOne: passwordOneErr,
        passwordTwo: passwordTwoErr,
      },
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne.length < 6 ||
      passwordOne === '' ||
      email === '' ||
      username === '' ||
      (showPrivateEmailForm && !reason)

    return (
      <form
        onSubmit={this.onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 auth-form">
        {showPrivateEmailForm && (
          <>
            <p>
              Thank you for your interest in TikBox! To help us keep our
              community genuine, please tell us why TikBox is important to you
              and how you plan to use it. We look forward to hearing your
              thoughts and will get back to you soon
            </p>
            <div className="mb-4">
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="reason"
                onChange={this.onChange}
                placeholder="Reason....">
                {reason}
              </textarea>
              {usernameErr && <ErrorPill message={usernameErr} />}
            </div>
          </>
        )}
        {!showPrivateEmailForm && (
          <>
            <div className="mb-4">
              <input
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={username}
                name="username"
                onChange={this.onChange}
                placeholder="Full Name"
              />
              {usernameErr && <ErrorPill message={usernameErr} />}
            </div>

            <div className="mb-4">
              <input
                type="email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={email}
                name="email"
                onChange={this.onChange}
                placeholder="Email Address"
              />
              {emailErr && <ErrorPill message={emailErr} />}
            </div>

            <div className="mb-4">
              <input
                name="passwordOne"
                value={passwordOne}
                onChange={this.onChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="password"
                placeholder="Password"
              />
              {passwordOneErr && <ErrorPill message={passwordOneErr} />}
            </div>

            <div className="mb-4">
              <input
                name="passwordTwo"
                value={passwordTwo}
                onChange={this.onChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="password"
                placeholder="Confirm Password"
              />
              {passwordTwoErr && <ErrorPill message={passwordTwoErr} />}
            </div>
          </>
        )}

        <div className="flex items-center justify-between">
          <button
            className={
              (isInvalid || this.props.auth.signUpLoading
                ? 'bg-gray-500 cursor-not-allowed '
                : 'bg-blue-500 hover:bg-blue-700 ') +
              'block w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            }
            disabled={isInvalid || this.props.auth.signUpLoading}
            type="submit">
            {this.props.auth.signUpLoading ? 'Creating account...' : 'Sign Up'}
          </button>
        </div>
      </form>
    )
  }
}

SignUpFormBase.propTypes = {
  registerUser: PropTypes.func.isRequired,
  removeAuthErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const SignUpLink = () => (
  <p className="text-center w-full" style={{ marginBottom: 40 }}>
    Don't have an account?{' '}
    <Link
      className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
      to={SIGN_UP}>
      Sign Up
    </Link>
  </p>
)

const SignUpForm = connect(mapStateToProps, { registerUser, removeAuthErrors })(
  withRouter(SignUpFormBase),
)

export default SignUpPage

export { SignUpForm, SignUpLink }
