import React, { Component, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import {
  registerUser,
  removeAuthErrors,
} from '../../../redux/actions/AuthActions'
import { HOME } from '../../../constants/Routes'

import Surfer from '../../../assets/images/landing-page/surfer.png'
import { triggerGoogleAnalyticsEvent, getLocation } from '../../../utils/Helper'
import DOMPurify from 'dompurify'

const ErrorPill = ({ message }) => {
  return <span className="error">{message}</span>
}

const SignUpModal = ({ setIsSignUpOpen, setIsSignInOpen }) => {
  const [showPrivateEmailForm, setShowPrivateEmailForm] = useState(false)
  return (
    <section className="tikbox-signup">
      <div className="darkBG" onClick={() => setIsSignUpOpen(false)} />
      <div className="centered">
        <div className="modal">
          <div className="surfer-image">
            <img src={Surfer} alt="" />
          </div>
          <div className="form">
            <SignUpForm
              showPrivateEmailForm={showPrivateEmailForm}
              setShowPrivateEmailForm={setShowPrivateEmailForm}
            />
            <div className="hint">
              Already a member?{' '}
              <button
                className="link"
                onClick={() => {
                  setIsSignInOpen(true)
                  setIsSignUpOpen(false)
                }}>
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      email: '',
      passwordOne: '',
      passwordTwo: '',
      reason: '',
      privacy: false,
      coupon: this.props.auth.coupon,
      errors: {
        username: '',
        email: '',
        passwordOne: '',
        passwordTwo: '',
        privacy: '',
      },
      location: '',
      region: '',
    }
  }

  onChange = (event) => {
    const nameRegex = /^[a-zA-Z\s'-]+$/
    let err = ''
    if (!event.target.value) {
      err = `${event.target.name} is required`
    } else if (event.target.name === 'privacy') {
      if (event.target.checked) {
        err = ''
      } else {
        err = 'Please check privacy & policy'
      }
    } else if (event.target.name === 'passwordOne') {
      if (event.target.value.length < 6) {
        err = 'Your password should be at least six characters'
      } else {
        err = ''
      }
    } else {
      if (event.target.name === 'passwordTwo') {
        if (event.target.value !== this.state.passwordOne) {
          err = 'Your passwords should be the same'
        } else {
          err = ''
        }
      } else if (event.target.name === 'username') {
        const testUsername = nameRegex.test(event.target.value)
        if (!testUsername) {
          err =
            'Invalid name. Only letters, spaces, hyphens, and apostrophes are allowed.'
        }
      } else {
        err = ''
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        [event.target.name]: err,
      },
    })
    this.setState({
      [event.target.name]:
        event.target.name === 'privacy'
          ? event.target.checked
          : DOMPurify.sanitize(event.target.value),
    })
  }

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  async componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(HOME)
    }
  }

  componentDidUpdate() {
    const registerError = this.props.auth.registerError
    if (registerError) {
      toast.dismiss()
      toast.error(registerError)

      this.props.removeAuthErrors()
    }
  }

  onSubmit = async (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('sign_up', { method: 'Form' })

    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      reason,
      coupon,
      region,
    } = this.state
    const { setShowPrivateEmailForm } = this.props

    const domain = email.split('@')[1]
    const isPersonalEmail = [
      'gmail.com',
      'hotmail.com',
      'yahoo.com',
      'outlook.com',
      'aol.com',
      'zoho.com',
      'mail.com',
      'protomail.com',
      'icloud.com',
      'yandex.com',
      'gmx.com',
    ].includes(domain)

    const location = await getLocation()
    const signupIp = location.ip
    const signupCountry = location.country

    const newUser = {
      name: username,
      email: email,
      password: passwordOne,
      password2: passwordTwo,
      businessEmail: !isPersonalEmail,
      signupIp,
      signupCountry,
    }

    this.props.registerUser(newUser, this.props.history)
    // setIsSignUpOpen(false)
  }

  render() {
    const { showPrivateEmailForm, setShowPrivateEmailForm } = this.props
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      reason,
      privacy,
      coupon,
      errors: {
        username: usernameErr,
        email: emailErr,
        passwordOne: passwordOneErr,
        passwordTwo: passwordTwoErr,
        privacy: privacyErr,
      },
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne.length < 6 ||
      passwordOne === '' ||
      email === '' ||
      username === '' ||
      !privacy ||
      (showPrivateEmailForm && !reason)

    return (
      <form onSubmit={this.onSubmit}>
        <div className="form-content">
          <h1>
            Sign up to <b>TikBox</b>
          </h1>
          {showPrivateEmailForm && (
            <>
              <p style={{ fontSize: 14, lineHeight: '20px' }}>
                Thank you for your interest in TikBox! To help us keep our
                community genuine, please tell us why TikBox is important to you
                and how you plan to use it. We look forward to hearing your
                thoughts and will get back to you soon. Alternatively,{' '}
                <span
                  onClick={() => {
                    setShowPrivateEmailForm(false)
                  }}
                  style={{
                    cursor: 'pointer',
                    color: 'blue',
                    textDecoration: 'underline',
                  }}>
                  sign-up
                </span>{' '}
                using your work or place of study email account
              </p>
              <div className="mb-4">
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="reason"
                  onChange={this.onChange}
                  placeholder="Reason....">
                  {reason}
                </textarea>
                {usernameErr && <ErrorPill message={usernameErr} />}
              </div>
            </>
          )}
          {!showPrivateEmailForm && (
            <>
              <p>Full Name</p>
              <input
                type="text"
                value={username}
                name="username"
                onChange={this.onChange}
                placeholder="Full Name"
              />
              {usernameErr && <ErrorPill message={usernameErr} />}
              <p>Email Address</p>
              <input
                type="email"
                value={email}
                name="email"
                onChange={this.onChange}
                placeholder="Email Address"
              />
              {emailErr && <ErrorPill message={emailErr} />}

              <div className="input-row">
                <div className="input-col">
                  <p>Password</p>
                  <input
                    name="passwordOne"
                    value={passwordOne}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Password"
                  />
                  {passwordOneErr && <ErrorPill message={passwordOneErr} />}
                </div>
                <div className="input-col">
                  <p>Confirm password</p>
                  <input
                    name="passwordTwo"
                    value={passwordTwo}
                    onChange={this.onChange}
                    type="password"
                    placeholder="Confirm Password"
                  />
                  {passwordTwoErr && <ErrorPill message={passwordTwoErr} />}
                </div>
              </div>
              <label>
                <input
                  type="checkbox"
                  name="privacy"
                  value={privacy}
                  onChange={this.onChange}
                  style={{ width: 15, height: 15 }}
                />
                {'  '}
                <a
                  target="_blank"
                  href="https://tikbox.io/terms-conditions/"
                  rel="noreferrer">
                  Terms & Conditions
                </a>
              </label>
              <br />
              {privacyErr && <ErrorPill message={privacyErr} />}
              {this.props.auth.isCoupon && (
                <div>
                  <p>Promo code</p>
                  <input
                    name="coupon"
                    value={coupon}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Promo (discount) code"
                  />
                </div>
              )}
            </>
          )}
          <button
            className={
              isInvalid || this.props.auth.signUpLoading
                ? 'submit-disabled '
                : 'submit-enabled '
            }
            disabled={isInvalid || this.props.auth.signUpLoading}
            type="submit">
            {this.props.auth.signUpLoading
              ? 'Creating account...'
              : 'Create Account'}
          </button>
        </div>
      </form>
    )
  }
}

SignUpFormBase.propTypes = {
  registerUser: PropTypes.func.isRequired,
  removeAuthErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  subscriptions: state.subscriptions,
})

export const SignUpForm = connect(mapStateToProps, {
  registerUser,
  removeAuthErrors,
})(withRouter(SignUpFormBase))

export default SignUpModal
