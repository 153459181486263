import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select } from 'antd'
import SidePanel from '../../../components/common/side-panel/SidePanel'
import MainPanel from '../../../components/common/main-panel/MainPanel'
import { resetSigningAssets } from '../../../redux/actions/AssetActions'
import { PlusIcon } from '../../../components/common/icons/PlusIcon'
import AssetsUploadSection from '../../../components/common/assets-upload-section/AssetsUploadSection'
import TikBoxModal from '../../../components/common/modal/Modal'
import {
  getAgreementTemplates,
  saveAgreementTemplate,
} from '../../../redux/actions/AgreementTemplateActions'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'
import SelectRadio from '../../../components/common/select-radio/SelectRadio'
import { getCreativeCommonLicenses } from '../../../redux/actions/CreativeCommonLicenseActions'

const TemplateAgreementContent = () => {
  const dispatch = useDispatch()
  const [openAddTemplateModal, setOpenAddTemplateModal] = useState(false)
  const [openCCOModal, setOpenCCOModal] = useState(false)
  const [selectedTemplateAgreement, setSelectedTemplateAgreement] = useState()
  const [selectedCreativeCommonLicense, setSelectedCreativeCommonLicense] =
    useState()
  const [ccoObj, setCcoObj] = useState()
  const [templateName, setTemplateName] = useState('')
  const [useWhen, setUseWhen] = useState('')
  const [knowCCOLicense, setKnowCCOLicense] = useState()
  const {
    agreementTemplates: { data, loading },
    creativeCommonLicenses: { data: creativeCommonLicensesData },
    auth: { user },
  } = useSelector((state) => state)

  const ccoNext = () => {
    toggleCCOModal(false)
  }

  const changeKnowCCOLicense = (value) => {
    triggerGoogleAnalyticsEvent('set_know_cco_license', {
      value,
      userId: user?.id,
    })
    setKnowCCOLicense(value)
    setSelectedCreativeCommonLicense()
  }
  const selectTemplate = (value) => {
    triggerGoogleAnalyticsEvent('select_template_agreement', {
      value,
      userId: user?.id,
    })
    const templateAgreement = data.find(({ _id }) => _id === value)
    if (templateAgreement?.ccoCode) {
      setSelectedCreativeCommonLicense(templateAgreement.ccoCode)
      setKnowCCOLicense('true')
    } else {
      setSelectedTemplateAgreement(value)
      setSelectedCreativeCommonLicense()
    }
  }
  const selectCreativeCommonLicense = (value) => {
    triggerGoogleAnalyticsEvent('select_creative_common_license', {
      value,
      userId: user?.id,
    })
    setSelectedCreativeCommonLicense(value)
    setSelectedTemplateAgreement()
  }

  const toggleAddTemplateModal = (value) => {
    triggerGoogleAnalyticsEvent('toggle_add_agreement_template', {
      value,
      userId: user?.id,
    })
    setOpenAddTemplateModal(value)
  }

  const toggleCCOModal = (value) => {
    triggerGoogleAnalyticsEvent('toggle_cco_modal', {
      value,
      userId: user?.id,
    })
    setOpenCCOModal(value)
  }

  const submitForm = (when) => {
    triggerGoogleAnalyticsEvent('save_agreement_template', {
      value: when,
      userId: user?.id,
    })
    setUseWhen(when)
    handleSaveAgreementTemplate(
      { name: templateName },
      when === 'now' ? setSelectedTemplateAgreement : undefined,
    )
  }

  const handleSaveAgreementTemplate = async (data, callback) => {
    await dispatch(saveAgreementTemplate(data, callback))
    setOpenAddTemplateModal(false)
  }

  const handleGetAgreementTemplates = async () => {
    await dispatch(getAgreementTemplates())
  }

  const handleGetCreativeCommonLicenses = async () => {
    await dispatch(getCreativeCommonLicenses())
  }

  const resetAnySigningAsset = async () => {
    await dispatch(resetSigningAssets())
  }

  useEffect(() => {
    handleGetAgreementTemplates()
    resetAnySigningAsset()
    handleGetCreativeCommonLicenses()
  }, [])

  useEffect(() => {
    if (selectedCreativeCommonLicense) {
      const data = creativeCommonLicensesData.find(
        (x) => x.code === selectedCreativeCommonLicense,
      )
      setCcoObj(data)
    }
  }, [selectedCreativeCommonLicense])

  return (
    <MainPanel>
      <div className="template-agreement-container">
        <div className="title">
          <h2>Template Agreement</h2>
        </div>
        <div className="template-agreement-container__section">
          <div className="agreement-templates__list">
            <h4>Add New Template Agreement</h4>
            <p>Select Template</p>
            <div className="agreement-templates-select">
              <Select
                defaultValue="Select"
                style={{
                  marginRight: 7,
                  height: 48,
                  width: 290,
                  borderColor: 'red',
                }}
                onChange={selectTemplate}
                options={data.map((template) => {
                  return { value: template._id, label: template.templateName }
                })}
              />
              <div
                className="add-icon"
                onClick={() => toggleAddTemplateModal(true)}>
                <PlusIcon />
              </div>
            </div>

            {selectedCreativeCommonLicense && (
              <>
                <div className="cco-license-list-title">
                  <h4>
                    {ccoObj?.name?.replace('Creative Commons Attribution-', '')}{' '}
                  </h4>
                  <h4>({selectedCreativeCommonLicense})</h4>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: ccoObj?.licenseList }}
                />
              </>
            )}
            {/* <p className="cco-btn" onClick={() => setOpenCCOModal(true)}>
              Creative Commons Options
            </p> */}
            {/* {selectedCreativeCommonLicense && (
              <p className="cco-preview">
                <span className="cco-selected">
                  {selectedCreativeCommonLicense}
                </span>{' '}
                <span
                  className="remove-btn"
                  onClick={() => changeKnowCCOLicense()}>
                  X
                </span>
              </p>
            )} */}
            {/* {knowCCOLicense === 'false' && (
              <p className="cco-preview">
                <span className="cco-selected">
                  Don't know what license to use? Upload your asset, let\'s help
                  you decide.
                </span>{' '}
                <span
                  className="remove-btn"
                  onClick={() => changeKnowCCOLicense()}>
                  X
                </span>
              </p>
            )} */}
          </div>
          <div
            className={`agreement-templates__upload${
              selectedTemplateAgreement ||
              knowCCOLicense === 'false' ||
              (knowCCOLicense === 'true' && selectedCreativeCommonLicense)
                ? ' active'
                : ''
            }`}>
            <svg
              className="upload-pointer-arrow"
              width="87"
              height="38"
              viewBox="0 0 87 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect
                y="10.7471"
                width="44.0158"
                height="16.5059"
                fill="#0DAA85"
              />
              <path
                d="M87 19L43.9298 35.3791L43.9298 2.62078L87 19Z"
                fill="#0DAA85"
              />
            </svg>

            <AssetsUploadSection
              cco={selectedCreativeCommonLicense}
              knowCCOLicense={knowCCOLicense}
              template={selectedTemplateAgreement}
            />
          </div>
        </div>
      </div>
      <TikBoxModal
        open={openAddTemplateModal}
        toggle={toggleAddTemplateModal}
        title="Add New Template Agreement">
        <div className="template-agreement-form">
          <div className="input-container">
            <p className="input-container__label">Template Name</p>
            <input
              value={templateName}
              onChange={(e) => {
                setTemplateName(e.target.value)
              }}
              type="text"
              placeholder="Template Name"
            />
          </div>

          <div className="action-btns">
            <button
              className="use-later-btn"
              onClick={() => submitForm('later')}>
              {loading && useWhen === 'later' ? 'Loading...' : 'Use Later'}
            </button>
            <button className="use-now-btn" onClick={() => submitForm('now')}>
              {loading && useWhen === 'now' ? 'Loading...' : 'Use Now'}
            </button>
          </div>
        </div>
      </TikBoxModal>

      <TikBoxModal
        open={openCCOModal}
        toggle={toggleCCOModal}
        title="Do you know which license you need?">
        <div className="cco-modal-content">
          <div className="select-radios">
            <SelectRadio
              onClick={() => changeKnowCCOLicense('true')}
              title="Yes, I know the license I need."
              selected={knowCCOLicense === 'true'}
            />
            <SelectRadio
              onClick={() => changeKnowCCOLicense('false')}
              title="No, I need help selecting a license."
              selected={knowCCOLicense === 'false'}
            />
          </div>
          {knowCCOLicense === 'true' && (
            <div
              className="select-common-creative-license"
              style={{ marginTop: 20 }}>
              <label>What license do you need?</label>
              <Select
                defaultValue="Select"
                style={{
                  marginRight: 7,
                  marginTop: 15,
                  height: 48,
                  width: '100%',
                }}
                popupClassName="custom-select-dropdown"
                onChange={selectCreativeCommonLicense}
                options={creativeCommonLicensesData.map((ccl) => {
                  return { value: ccl.code, label: ccl.code }
                })}
              />
            </div>
          )}

          <div className="workflow-next-container">
            <button
              style={{}}
              className="workflow-next-btn"
              onClick={
                (knowCCOLicense === 'true' && selectedCreativeCommonLicense) ||
                knowCCOLicense === 'false'
                  ? ccoNext
                  : undefined
              }>
              Next
            </button>
          </div>
        </div>
      </TikBoxModal>
    </MainPanel>
  )
}

const TemplateAgreement = () => {
  return (
    <div className="tikbox-template-agreement">
      <div className="tikbox-template-agreement__content">
        <SidePanel />
        <TemplateAgreementContent />
      </div>
    </div>
  )
}

export default TemplateAgreement
