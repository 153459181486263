export const DropdownIcon = (props) => {
  return (
    <svg
      {...props}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        onClick={props.onClick && props.onClick}
        d="M11 1.50004C11 1.50004 7.31758 6.5 6 6.5C4.68233 6.5 1 1.5 1 1.5"
        stroke="#848484"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
