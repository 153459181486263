export const DownloadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.08398 9.99992C2.08398 6.26797 2.08398 4.40199 3.24335 3.24262C4.40273 2.08325 6.2687 2.08325 10.0007 2.08325C13.7326 2.08325 15.5986 2.08325 16.758 3.24262C17.9173 4.40199 17.9173 6.26797 17.9173 9.99992C17.9173 13.7318 17.9173 15.5978 16.758 16.7573C15.5986 17.9166 13.7326 17.9166 10.0007 17.9166C6.2687 17.9166 4.40273 17.9166 3.24335 16.7573C2.08398 15.5978 2.08398 13.7318 2.08398 9.99992Z"
        stroke="#0daa85"
        strokeWidth="1.5"
      />
      <path
        d="M10.0031 5.86548V11.7408M10.0031 11.7408C10.2748 11.7444 10.5429 11.5576 10.7406 11.3297L12.0652 9.84409M10.0031 11.7408C9.74089 11.7373 9.47539 11.5515 9.26548 11.3296L7.93317 9.84409M6.65625 14.1988H13.3229"
        stroke="#0daa85"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}
