import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { PASSWORD_FORGET } from '../../constants/Routes'
import AuthFooter from './AuthFooter'
import Axios from '../../utils/Axios'
import NavigationHeader from '../navigation/NavigationHeader'

const PasswordForgetPage = () => (
  <>
    <div className="container mx-auto signInWraper">
      <NavigationHeader />
      {/* <div className={'flex items-center justify-center main-area'}> */}
      <div className="w-full max-w-xs mx-auto pt-5">
        <div className={'text-2xl mb-3 font-bold text-center text-blue-500'}>
          Send Reset Password Link
        </div>

        <PasswordForgetForm />
      </div>
    </div>
    <AuthFooter />
  </>
)

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      error: null,
      success: null,
      loading: false,
    }
  }

  onSubmit = async (event) => {
    event.preventDefault()
    this.setState({ loading: true })

    const { email } = this.state
    try {
      const { data } = await Axios.post('/users/sendResetLink', {
        email: email,
      })

      if (!data.error) {
        this.setState({
          success:
            'Successfully sent! Please check your email inbox and junkmail (just in case!)',
          error: null,
          email: '',
          loading: false,
        })
      } else {
        this.setState({
          success: null,
          error: data.error,
          loading: false,
        })
      }
    } catch (error) {
      this.setState({
        success: null,
        error: error?.response?.data || 'Something went wrong',
        loading: false,
      })
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  render() {
    const { email, error, success } = this.state

    const isInvalid = !this.isEmail(email)

    return (
      <form
        method="post"
        onSubmit={this.onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 auth-form">
        <div className="mb-6">
          <input
            type="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={email}
            name="email"
            onChange={this.onChange}
            placeholder="Email Address"
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            className={
              (isInvalid || this.state.loading
                ? 'bg-gray-500 cursor-not-allowed '
                : 'bg-blue-500 hover:bg-blue-700 ') +
              'block w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            }
            disabled={isInvalid}
            type="submit">
            {this.state.loading ? 'Sending Email...' : 'Reset My Password'}
          </button>
        </div>

        {success && (
          <p className="text-green-400 text-xs italic mt-5">{success}</p>
        )}

        {error && <p className="text-red-500 text-xs italic mt-5">{error}</p>}
      </form>
    )
  }
}

const PasswordForgetLink = () => (
  <Link
    to={PASSWORD_FORGET}
    className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
    Forgot Password?
  </Link>
)

export default PasswordForgetPage

export { PasswordForgetForm, PasswordForgetLink }
