import { useSelector } from 'react-redux'
import { Fragment, useEffect, useState } from 'react'
import { AgreementContentType, AgreementKeys } from '../../../constants'
import Spinner from '../../../components/common/spinner/Spinner'
import { AgreementItem } from './AgreementItem'
import { DropdownIcon } from '../../../components/common/icons/DropdownIcon'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

const AssignmentContent = ({
  data,
  addAgreement,
  removeAgreement,
  selectedAgreements,
  editingAgreement,
  setEditAgreement,
  agreementContent,
  setAgreementContent,
  cancelEditAgreement,
  saveUpdatedAgreement,
}) => {
  const {
    agreements: { loading },
    auth: { user },
  } = useSelector((state) => state)
  const [showContent, setShowContent] = useState(true)
  const selections = selectedAgreements[AgreementKeys.Assignment]

  const titles = data.filter(
    (x) => x.contentType === AgreementContentType.TITLE,
  )
  const paragraphs = data.filter(
    (x) => x.contentType === AgreementContentType.PARAGRAPH,
  )
  const checkboxes = data.filter(
    (x) => x.contentType === AgreementContentType.CHECKBOX,
  )

  useEffect(() => {
    triggerGoogleAnalyticsEvent('setting_agreement', {
      stage: 'assignment',
      userId: user?.id,
    })
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="assignment-content">
      <DropdownIcon
        className={`dropdown${showContent ? ' open' : ''}`}
        onClick={() => setShowContent(!showContent)}
      />
      {titles.map((title, index) => (
        <Fragment key={index}>
          <AgreementItem
            editingAgreement={editingAgreement}
            setEditAgreement={setEditAgreement}
            cancelEditAgreement={cancelEditAgreement}
            saveUpdatedAgreement={saveUpdatedAgreement}
            agreementContent={agreementContent}
            setAgreementContent={setAgreementContent}
            agreement={title}
          />
        </Fragment>
      ))}
      {showContent &&
        paragraphs.map((p, index) => (
          <Fragment key={index}>
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={p}
            />
          </Fragment>
        ))}

      {showContent && (
        <div className="questionaire">
          <form>
            {checkboxes.map((checkbox, index) => {
              const isChecked = !!selections.find(
                (x) => x.index === checkbox._id,
              )
              return (
                <Fragment key={index}>
                  <AgreementItem
                    editingAgreement={editingAgreement}
                    setEditAgreement={setEditAgreement}
                    cancelEditAgreement={cancelEditAgreement}
                    saveUpdatedAgreement={saveUpdatedAgreement}
                    agreementContent={agreementContent}
                    setAgreementContent={setAgreementContent}
                    agreement={checkbox}
                    isChecked={isChecked}
                    addAgreement={addAgreement}
                    removeAgreement={removeAgreement}
                  />
                </Fragment>
              )
            })}
          </form>
        </div>
      )}
    </div>
  )
}

export default AssignmentContent
