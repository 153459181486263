import { useState } from 'react'
import SignUpModal from '../main/landing-page/SignUp'
import SignInModal from '../main/landing-page/SignIn'
import Header from '../../components/header/Header'
import { Footer } from '../../components/footer/Footer'

const TermsConditions = () => {
  const [isSignUpOpen, setIsSignUpOpen] = useState(false)
  const [isSignInOpen, setIsSignInOpen] = useState(false)
  return (
    <>
      <Header
        setIsSignUpOpen={setIsSignUpOpen}
        setIsSignInOpen={setIsSignInOpen}
      />
      <div className="terms-conditions">
        <h3>Terms and Conditions for the Commercial Use of TikBox.io</h3>
        <h2>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY.</h2>
        <p>
          This terms of use agreement (this "Agreement") (together with the
          documents referred to in it) is a legal agreement between you and
          TikBox.io Ltd, a limited company registered in England and Wales under
          company number 15424686. Our registered address is 20 Wenlock Road,
          London, England, N1 7GU ("we", "us" or "our") for the use of our web
          application (the Application):{' '}
          <a href="https://tikbox.io">https://tikbox.io</a> and
          <a href="https://api.tikbox.io">https://api.tikbox.io</a>, and
          informational website:{' '}
          <a href="www.blog.tikbox.io">www.blog.tikbox.io</a> (Website)
          collectively known as ‘the Platform’. By using and/or registering to
          use the Platform, you must read, agree with, and accept all of the
          terms and conditions contained in this agreement. This agreement is
          provided to you and concluded in English. You agree that any use by
          you of The Platform shall constitute your acceptance of the agreement.
          We recommend that you store or print off a copy of the agreement
          (including all policies) for your records. If you do not agree to the
          terms of this agreement, please refrain from using the Platform.
        </p>
        <p>
          <a href="https://blog.tikbox.io">https://blog.tikbox.io</a> (Our
          Site), and web application (the Application):{' '}
          <a href="https://tikbox.io">https://tikbox.io</a> and{' '}
          <a href="https://api.tikbox.io">https://api.tikbox.io</a>{' '}
          (collectively ‘the Platform’) We are not a law firm and we are not
          regulated as such in any jurisdiction.
        </p>

        <h4>1. APPLICABLE TERMS</h4>
        <p>
          1.1 This agreement refers to the following additional terms, which
          also apply to your use of The Platform:
        </p>
        <ul>
          <li>
            Our Privacy Policy located at: {' '}
            <a href="https://tikbox.io/privacy-policy">
              https://tikbox.io/privacy-policy
            </a>{' '}
            , which sets out the terms on which we process any personal data we
            collect from you, or that you provide to us. By using The Platform,
            you consent to such processing and you warrant that all data
            provided by you is accurate.
          </li>
        </ul>
        <p>
          If you are using the Service on behalf of a company or organisation,
          you confirm to us that you have authority to act on behalf of that
          entity, and that entity accepts this Agreement.
        </p>
        <p>By using The Platform, you agree that any data you upload may be 
          used for the purposes of providing and improving our Services. 
          However, we will not use your data for AI-generated content or 
          repurposing without your explicit consent.</p>
        <p>
          1.2 You and your Authorised Users may use the Services as permitted by
          your Membership plan. “Authorised User” means anyone you authorise to
          access and use the Services under your account. You are responsible
          for the actions of your Authorised Users. If you are an Authorised
          User of an organisation, you agree that (i) you have authority to act
          on behalf of that entity, and that entity accepts this Agreement (ii)
          the organisation owns the account, including its content, and may at
          any time access, use, control, and disable your account and its
          content; (iii) the organisation will have access to your data
          contained in the Services; and (iv) if the organisation owns two or
          more accounts, it may transfer Authorised Users between its accounts
          without notice. If you use an email address procured for you by your
          organisation (such as work email) to create your Services account, the
          organisation owns the account. If you do not want your organisation to
          access, use, or control your account, do not use an email address
          procured by your organisation in connection with the Service.
        </p>
        <h4>2. ACCESSING THE PLATFORM AND USING THE SERVICES</h4>
        <p>
        We provide template copyright agreements as part of our Services, 
        but we make no representations or warranties regarding their 
        completeness, accuracy, or applicability to your specific legal 
        situation. TikBox.io Ltd. assumes no liability for any outcomes 
        or legal disputes that may arise from the use of these templates.
        </p>

        <p>
          2.2 You may receive access and direct links to third-party services
          for your benefit and ease of service via The Platform. You acknowledge
          and agree that when you click on a link to a third-party website on
          The Platform, you are leaving The Platform and entering a third-party
          website which is subject to its own terms and conditions. We accept no
          responsibility or liability in respect of any events that occur whilst
          you are using third-party platforms and services.Additionally, TikBox.io 
          will not share user-uploaded content with third-party services without 
          explicit consent, except where necessary for providing the Services, 
          such as adding copyright metatags.
        </p>
        <p>
          2.3 In consideration of you agreeing to abide by the terms of this
          agreement, we hereby grant you a limited, non-exclusive,
          non-transferable (except as permitted by this Agreement) license to
          use the Services. If you are licensing the API as part of the Services
          (as indicated in your Membership plan), your license also includes the
          right to incorporate the API into your products and services as
          permitted by your Membership plan. We reserve all rights not expressly
          granted to you in this Agreement. To access and use the Services you
          must sign up with your name, email address, and password to create an
          account ("Account"). We discourage generic account names and we
          actively monitor concurrent Account usage. You may log into your
          Account on multiple devices, but you shall not be able to access and
          use the Services via the same Account from multiple devices at the
          same time. Sharing of log-in details with any other user is strictly
          prohibited.
        </p>
        <p>
          2.4 Access to The Platform is permitted on a free trial basis for your
          evaluation, and we reserve the right to withdraw or amend the services
          or functionality that we provide on The Platform without notice. We
          will not be liable if for any reason The Platform is unavailable at
          any time or for any period.
        </p>
        <p>
          2.5 Where functionality in the Application is provided in beta
          release, or is not generally available, you acknowledge that it may
          not be in final form or fully functional and may contain errors,
          design flaws, or other problems.
        </p>
        <p>
          2.6 If you choose, or you are provided with, a user identification
          code, password, or any other piece of information as part of our
          security procedures, you must treat such information as confidential,
          and you must not disclose it to any third party. We have the right to
          disable any user identification code or password if, in our opinion,
          you have failed to comply with any of the provisions of this
          agreement.
        </p>
        <p>
          2.7 You must not misuse the Platform by knowingly introducing viruses,
          Trojans, worms, logic bombs, or other material which is malicious or
          technologically harmful. You must not attempt to gain unauthorised
          access to The Platform, the server on which The Platform is stored, or
          any server, computer, or database connected to The Platform. You must
          not attack The Platform via a denial-of-service attack or a
          distributed denial-of-service attack.
        </p>
        <p>
          2.8 If you breach clause 2.7, you will be committing a criminal
          offense under the Computer Misuse Act 1990. We will report any such
          breach to the relevant law enforcement authorities and will cooperate
          with those authorities by disclosing your identity to them. In the
          event of such a breach, we reserve the right to immediately cease your
          right to use The Platform.
        </p>
        <h4>3. CONTENT OWNERSHIP</h4>
        <p>
          3.1 Your Content means your documents, communications, personal
          information, and other content you, including your Authorised Users,
          upload into or use with the Services. Between you and us, you own all
          rights to Your Content. You are responsible for ensuring that Your
          Content complies with the law. You grant us a non-exclusive license to
          use that content solely for the purpose of providing the Services and
          improving the usability and performance of the Services. We will not 
          use your content for AI-generated content without your explicit 
          consent.
        </p>

        <p>
          3.2 We shall have no obligations with respect to the content you
          submit other than to perform the Services and improve the usability
          and performance of the Services.We assume no liability for any legal 
          issues, disputes, or damages arising from your content, including, 
          but not limited to, its accuracy, legality, or compliance with 
          third-party intellectual property rights.
        </p>
        <h4>4. YOUR UNDERTAKINGS</h4>
        <p>
          4.1 You agree that when using the Platform you will comply with all
          applicable laws and this agreement. In particular, but without
          limitation, you agree not to:
        </p>
        <ul>
          <li>
            Use the Platform in any unlawful manner or in a manner which
            promotes or encourages illegal activity;
          </li>
          <li> Breach any law, statute, contract, or regulation;</li>
          <li>
            {' '}
            Act in a manner that is obscene, defamatory, libelous, unlawfully
            threatening, or unlawfully harassing;
          </li>
          <li>Provide false, inaccurate, or misleading information;</li>
          <li>Use an anonymizing proxy;</li>
          <li>
            Attempt to gain unauthorised access to The Platform or any networks,
            servers, or computer systems connected to the Platform;
          </li>
          <li>
            Rent, lease, sub-license, loan, translate, merge, adapt, vary, or
            modify the Platform or your access to the Platform;
          </li>
          <li>
            Make alterations to, or modifications of, the whole or any part of
            the Platform nor permit the Platform or any part of it to be
            combined with, or become incorporated in, any other programs or
            websites;
          </li>
          <li>
            Disassemble, de-compile, reverse engineer, or create derivative
            works based on the whole or any part of the Platform;
          </li>
          <li>
            Include our copyright notice on all entire and partial copies of the
            Platform in any form;
          </li>
          <li>
            Provide, or otherwise make available, the Platform in any form, in
            whole or in part, to any person without prior written consent from
            us.
          </li>
        </ul>
        <p>
          4.2 You agree to indemnify us in full and on demand from and against
          any loss, damage, costs, or expenses which we suffer or incur directly
          or indirectly as a result of your use of the Platform otherwise than
          in accordance with this agreement or any applicable laws.
        </p>
        <p>
          4.3 You warrant to us that all the information you provide to us is
          true and accurate to the best of your knowledge.
        </p>
        <h4>5. INTELLECTUAL PROPERTY RIGHTS</h4>
        <p>
          5.1 We are the owner or the licensee of all intellectual property
          rights in The Platform and in the material published on it. Those
          works are protected by copyright laws and treaties around the world.
          All such rights are reserved.
        </p>
        <p>
          5.2 The trademarks, service marks, and logos ("Trade Marks") contained
          on The Platform are owned by us. Where they exist, we recognize and
          respect the rights of other IP holders displayed on The Platform. You
          cannot use, copy, edit, vary, reproduce, publish, display, distribute,
          store, transmit, commercially exploit, or disseminate the Trade Marks
          without the prior written consent of us, or the relevant third party.
        </p>

        <p>
          5.3 You must not modify the paper or digital copies of any materials
          you have printed off or downloaded in any way, and you must not use
          any illustrations, photographs, video, or audio sequences or any
          graphics separately from any accompanying text.
        </p>
        <p>
          5.4 Our status (and that of any identified contributors) as the
          authors of content on The Platform must always be acknowledged.
        </p>
        <p>
          5.5 You must not use any part of the content on The Platform for
          commercial purposes without obtaining a license to do so from us or
          our licensors.
        </p>
        <p>
          5.6 If you print off, copy, or download any part of The Platform in
          breach of this agreement, your right to use The Platform will cease
          immediately and you must, at our option, return or destroy any copies
          of the materials you have made.
        </p>
        <h4>6. UPLOADING CONTENT TO THE PLATFORM</h4>
        <p>
          6.1 Whenever you make use of a feature that allows you to upload
          content to The Platform, you must comply with the content standards
          set out herein.
        </p>
        <p>
          6.2 You warrant that any such contribution does comply with these
          standards, and you will be liable to us and indemnify us for any
          breach of this warranty.
        </p>
        <p>
          6.3 We have the right to disclose your identity to any third party who
          is claiming that any content posted or uploaded by you to The Platform
          constitutes a violation of their intellectual property rights, or of
          their right to privacy.
        </p>
        <p>
          6.4 We have the right to remove any posting you make on The Platform
          if, in our opinion, your post does not comply with the content
          standards set out herein or with any applicable laws.
        </p>
        <p>
          6.5 You acknowledge and agree that The Platform acts merely as a
          passive conduit and/or host for the uploading, storage, and
          distribution of such content. Any views expressed by other users on
          The Platform do not represent our views or values. We cannot and do
          not review the content created or uploaded by users, and neither we
          nor our subsidiaries, affiliates, successors, assigns, employees,
          agents, directors, officers, and shareholders have any obligation, and
          do not undertake or assume any duty, to monitor The Platform for
          content that is inappropriate, that does not or might infringe any
          third party rights, or has otherwise been uploaded in breach of this
          agreement or applicable law.
        </p>
        <p>
          6.6 You are solely responsible for all of the content that you upload,
          post, or distribute to, on, or through The Platform, and to the extent
          permissible by law, we exclude all liability with respect to content
          and the activities of users.
        </p>
        <p>
          6.7 You are responsible for any wording, figures, or documentation
          which you add or upload on our platform beyond our industry-standard
          templates. The Legals team does not review or check the legal status,
          enforceability, or consistency of your free-form text additions or
          that of any external documentation you upload to our platform, and we
          will not be responsible or liable with respect to any subject matter
          or terms contained therein. The insertion of free-form text or the
          upload of external documentation on the Legals platform should not be
          used as a substitute for qualified legal, tax, accounting, or
          financial advice in respect of these additions or documents. TikBox.io Ltd assumes 
          no liability for any legal disputes, inaccuracies, or enforceability issues arising 
          from user-generated text, free-form additions, or external documentation uploaded by you. 
          Use of such features is at your own risk and should not replace professional legal counsel.
        </p>
        <h4>7. CONFIDENTIALITY</h4>
        <p>
          7.1 Definition of Confidential Information. All non-public information
          disclosed by a party (the “disclosing party”) to the other party (the
          “receiving party”) that the receiving party knows or reasonably should
          know is confidential to the disclosing party is “Confidential
          Information,” except for the information which:
        </p>
        <ul>
          <li>
            Becomes publicly available through no fault of the receiving party;
          </li>
          <li>
            {' '}
            Is known by the receiving party at the time of receiving such
            information without confidentiality obligations;
          </li>
          <li>
            {' '}
            Is obtained by the receiving party on a non-confidential basis from
            a third party that was not restricted from disclosing such
            information; or
          </li>
          <li>
            {' '}
            Is independently developed by the receiving party without the use of
            Confidential Information.
          </li>
        </ul>
        <p>
          7.2 Confidentiality Obligations. The receiving party agrees to (i)
          protect Confidential Information with reasonable care and (ii) use
          Confidential Information only as necessary for purposes of this
          Agreement. Each party may disclose relevant portions of Confidential
          Information to the receiving party’s representatives, on a
          need-to-know basis, on condition that such individuals or entities are
          under obligations of confidentiality that require them to protect the
          Confidential Information to the same extent as required under this
          Agreement.
          </p>
          <p>
          7.3 If Confidential Information includes data used for AI processing, TikBox.io will ensure that such data is handled and safeguarded in compliance with all relevant data protection regulations and will not use it for AI-generated content without explicit consent.  
        </p>
        <h4>8. DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) POLICY</h4>
        <p>
          We reserve the right to take down public content published on The
          Platform or distributed through shared links should we find a breach
          of terms, as stated in our policy:{' '}
          <a href="https://tikbox.io/dmca">https://tikbox.io/dmca.</a>
        </p>
        <p>
          If you believe that any content should not be available on the
          Services, you should report it to our support team. If you believe
          that any content on the Services infringes your copyright rights,
          please follow our DMCA Takedown Policy at{' '}
          <a href="https://www.tikbox.io/dmca">https://www.tikbox.io/dmca.</a>
        </p>
        <p>TikBox.io Ltd assumes no liability for the accuracy or legitimacy of 
          DMCA takedown requests and reserves the right to remove content 
          without liability in cases of alleged copyright infringement.</p>
        <h4>9. CHANGES TO THESE TERMS</h4>
        <p>
          We may revise this agreement (or any of the documents referred to in
          this agreement) at any time by amending this page. Please check this
          page from time to time to take notice of any changes we made, as they
          are binding on you.
        </p>
        <h4>10. DUPLICATE ACCOUNTS</h4>
        <p>
          We reserve the right to refuse the creation of duplicate accounts for
          the same user. In a case where duplicate accounts are detected, we
          reserve the right to close or merge these duplicate accounts without
          notification.
        </p>
        <h4>11. ELIGIBILITY</h4>
        <p>
          11.1 To be eligible for The Platform, you must (a) agree to the terms
          of this agreement and (b) be at least 16 years old. If you are aged 16
          but under 18 years of age, you and your parent or guardian must review
          this agreement and the Privacy Policy together. Parents/guardians are
          jointly and severally liable for all acts and omissions of their
          children aged under 18 years when using our Services.
        </p>
        <p>
          11.2 You further represent and warrant to us that if you are an
          individual and you open an account with us, that you are not acting on
          behalf of an undisclosed principal or a third party beneficiary,
          except as specified in clause 1.2 and 11.1.
        </p>

        <p>
          11.3 You undertake that your login may only be used by you and a login
          shared by multiple people is not permitted.
        </p>
        <h4>12. COMPLIANCE CHECKS</h4>
        <p>
          12.1 You agree that we may run KYC and AML verification checks on your
          company profile to confirm you are who you say you are, and you hereby
          agree to comply with all such KYC and AML verification. You agree that
          your personal data (including name and address) will be collected and
          processed for the purposes of conducting KYC/AML checks and in
          accordance with our Privacy Policy.
        </p>
        <h4>13. WARRANTIES</h4>
        <p>
          13.1 We warrant to you that the Services (excluding Free Services,
          Beta Services, Third-Party Content, and Third-Party Services) will:
        </p>
        <ul>
          <li>
            {' '}
            Operate substantially in conformance with the Membership plan and
            other requirements of this Agreement (and any Service Level
            Agreement if applicable);
          </li>
          <li>
            {' '}
            Be provided via an infrastructure that employs security practices
            that we have determined in good faith to be commercially reasonable.
          </li>
        </ul>
        <p>
          13.2 AS-IS; NO OTHER WARRANTIES. EXCEPT AS EXPRESSLY PROVIDED IN THIS
          AGREEMENT, THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND WE
          EXCLUDE AND DISCLAIM ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND,
          WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, WITHOUT
          LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, SATISFACTORY QUALITY, QUIET ENJOYMENT, OR
          NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND THOSE ARISING FROM COURSE
          OF DEALING AND USAGE OF TRADE.
        </p>
        < p>13.3 TIKBOX.IO LTD ASSUMES NO LIABILITY FOR ANY SPECIFIC OUTCOMES RESULTING 
        FROM USER-UPLOADED CONTENT, AI-DRIVEN PROCESSES, OR THIRD-PARTY INTEGRATIONS, 
        EXCEPT WHERE EXPRESSLY PROVIDED UNDER THIS AGREEMENT</p>
        <p>
          13.4 NO LEGAL OR OTHER PROFESSIONAL ADVICE. NONE OF THE SERVICES AND
          WE CONTENT CONSTITUTES OR PROVIDES LEGAL OR OTHER PROFESSIONAL ADVICE.
          WE DO NOT WARRANT THAT ANY OF THE SERVICES OR WE CONTENT WILL PRODUCE
          ANY SPECIFIC LEGAL OUTCOME. YOU SHOULD CONSULT A QUALIFIED LICENSED
          ATTORNEY OR ANOTHER APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE
          TAILORED TO YOUR SITUATION. WE ARE NOT A LAW FIRM, AND COMMUNICATIONS
          BETWEEN YOU AND WE WILL NOT BE PROTECTED AS PRIVILEGED COMMUNICATIONS
          UNDER THE ATTORNEY-CLIENT PRIVILEGE OR WORK PRODUCT DOCTRINE.
        </p>
        <h4>14. MEMBERSHIP SERVICES</h4>
        <p>
          14.1 Ongoing access to all Services and features of the Platform
          requires a membership to one of our plans (“Membership”). The first 30
          days of your Membership (if you’ve selected a monthly plan) or the
          first year of your Membership (if you’ve selected a yearly plan), will
          constitute the “Initial Service Term”. Thereafter, your Membership
          will be automatically renewed for successive periods of 30 days (if
          you’ve selected a monthly plan) or 12 months (if you’ve selected a
          yearly plan), each a “Renewal Period”, and you will continue to be
          charged Fees for the Membership on a monthly (if you’re on a monthly
          plan) or annual (if you’re on an annual plan) basis, until your
          Membership is cancelled in accordance with clause 13.4.
        </p>
        <h4>15. SUBSCRIPTION AND FEES</h4>
        <p>
          15.1 You will pay us all fees for the Services in accordance with
          Membership plan without withholding, offset, or deduction. Except as
          otherwise agreed by us, all fees are non-refundable, even if you are
          not using the Services or have only briefly used the Services. Any
          prepaid, unused Services will expire at the end of your subscription
          period unless carryover is expressly permitted by the Membership plan.
          We may offer a refund policy available on the website for the specific
          Services for first-time users who are dissatisfied with the Services.
          Such refund policies only apply to you if you purchased any Services
          for the first time online via a self-service checkout process without
          assistance from a sales representative. Refund policies do not apply
          to excess usage fees, our Content, and Third-Party Services.
        </p>
        <p>15.2 TikBox.io Ltd assumes no liability for any interruptions in service or 
          unused Services after payment, and no refunds will be provided outside the 
          Cooling-off Period, unless otherwise specified in the Membership plan.</p>

        <p>
          15.3 You may choose your Membership plan (number of users, usage
          volume limits, pricing, duration) from our pricing page. If you exceed
          any usage limits set in your Membership plan, we will bill you for the
          excess usage as described in the pricing page. We will bill you for
          excess usage monthly or in other reasonable increments. You are
          responsible for tracking your usage, and we are not obligated to
          notify you before you incur excess usage fees.
        </p>
        <p>
          15.4 You agree to pay all taxes and similar assessments, including
          sales tax, use tax, value-added tax (VAT), and goods and services tax
          (GST), imposed by any government on your Services. If we do not
          collect taxes at the time of the original purchase, we reserve the
          right to collect such taxes later using the same payment method with
          written notice explaining such charges. We also reserve the right to
          collect any penalties or interest imposed on your transactions if they
          are your fault (for example, if you provide us with a false address or
          tax status at the time of purchase).
        </p>
        <p>
          15.5 We may change our fees for the Services at any time, including
          introducing fees for formerly Free Services. We will notify you of
          such changes in writing or by posting on the Services. Any changes in
          fees to your Services will be effective as of your next subscription
          period and will not impact your current subscription period.
        </p>
        <p>
          15.6 If your Membership plan does not specify a different due date,
          your fees are due on the first day of the subscription period. If your
          Membership plan requires us to invoice you, you agree to pay all fees
          within thirty (30) days of the date of our invoice. All fees are
          payable in the currency specified on your Membership plan. You agree
          to provide complete and accurate billing and contact information on
          our request and keep it updated for the duration of the Agreement. If
          you do not pay amounts when due, we may immediately suspend your
          access to Services and charge interest on the overdue amount until
          actually paid at the rate of 1.5% per month, calculated daily and
          compounded monthly, or the highest rate permitted by law, whichever is
          lower. You will reimburse us for all reasonable attorneys’ fees and
          costs incurred to collect past due amounts.
        </p>
        <p>
          15.7 Before you can access Services, we may require you to provide a
          valid credit card or another form of payment acceptable to us. If you
          provide a credit card or other payment account which allows us to
          charge it, you agree that we may charge such payment account for all
          amounts due under this Agreement, including recurring fees. We may
          test your payment account with a small charge, which will be refunded
          to you within ten (10) business days. To avoid interruption of your
          Services, we may cooperate with your card provider to automatically
          update your payment account information. You authorize us and our
          payment processing providers to store your payment account
          information, including updates, and use it in connection with your use
          of the Services as described in your Membership plan.
        </p>
        <p>
          15.8 Any promotional offers we may make from time to time will have
          specific terms. For example, we may choose to offer discounted Fees
          for our Membership plans during a limited promotional period. If we do
          that, we will start charging you Fees at the standard non-promotional
          advertised price on our pricing page after the promotional period
          ends.
        </p>
        <p>15.9 TikBox.io Ltd assumes no responsibility for any financial institution 
          fees or penalties incurred as a result of payment disputes or delays,
           and any undisputed amounts remain payable during the dispute resolution 
           process.</p>
        <p>
          15.10 If you have a question about your payment or would like to
          dispute an invoice, you will contact our support team through the
          channels described on our website with details of the question or
          dispute, any supporting documentation, and your contact information
          within 30 days of the payment or receipt of the invoice. If you fail
          to contact our support before disputing the payment with a financial
          institution, we may use such failure as an argument against your
          dispute. You will continue to pay all undisputed amounts when due
          while the parties work diligently to promptly resolve the dispute.
          When a dispute is resolved, we will promptly credit any amount owed to
          you, and you will promptly pay all amounts owed to us.
        </p>
        <p>
          15.11 All payments you make are non-cancellable and non-refundable,
          subject only to the provisions of this clause 15.11. When you first
          sign up to the Platform by purchasing a Membership, you can cancel
          your purchase and ask for a refund by telling us in writing of your
          wish to cancel within 2 working days of purchasing your Membership
          (“Cooling-off Period”). We will assess your refund request and we will
          only process a refund if we are satisfied that no documentation has
          been created on your company profile during the Cooling-off Period.
          For the avoidance of doubt, creation of any documentation within the
          Cooling-off Period will render your Membership non-refundable.
          Memberships are non-refundable after the Cooling-off Period.
        </p>
        <h4>16. OUR LIABILITY</h4>
        <p>
          16.1 Nothing in this agreement excludes or limits our liability for
          death or personal injury arising from our negligence, or our fraud or
          fraudulent misrepresentation, or any other liability that cannot be
          excluded or limited by English law.
        </p>
        <p>
          16.2 To the extent permitted by law, we exclude all conditions,
          warranties, representations, or other terms which may apply to The
          Platform or any content on it, whether express or implied.
        </p>
        <p>
          16.3 Subject to clause 16.1, we will not be liable to any user for any
          loss or damage, whether in contract, tort (including negligence),
          breach of statutory duty, or otherwise, even if foreseeable, arising
          under or in connection with:
        </p>
        <ul>
          <li> Use of, or inability to use, the Platform or the Services;</li>
          <li>Use of or reliance on any content displayed on the Platform.</li>
        </ul>
        <p>
        16.4 This limitation of liability includes, without limitation, any losses 
        arising from user-uploaded content, user reliance on provided templates, 
        or AI-driven processes within the Services, except where prohibited by law.
        </p>
        <p>
          16.5 We will not be liable for any loss or damage caused by a virus,
          distributed denial-of-service attack, or other technologically harmful
          material that may infect your computer equipment, computer programs,
          data, or other proprietary material due to your use of The Platform or
          to your downloading of any content on it, or on any website linked to
          it.
        </p>
        <p>
          16.6 We assume no responsibility for the content of websites linked to
          The Platform. Such links should not be interpreted as endorsement by
          us of those linked websites. We will not be liable for any loss or
          damage that may arise from your use of them.
        </p>
        <p>
          16.7 Subject to clause 16.1, our maximum aggregate liability under or
          in connection with this agreement, or any collateral contract, whether
          in contract, tort (including negligence), or otherwise, shall in all
          circumstances be limited to a sum equal to £100 or the amount you paid
          to us in fees in the previous 12 calendar months, whichever is
          greater.
        </p>
        <h4>17. INDEMNIFICATION</h4>
        <p>
          17.1 Subject to Section 16 above, we will indemnify you and hold you
          harmless against any claims by third parties resulting from any
          alleged infringement by the Service of any UK patent or
          misappropriation of any trade secret, provided we are promptly
          notified of any and all threats, claims and proceedings related
          thereto and given reasonable assistance and the opportunity to assume
          sole control over defence and/or settlement. We will not be
          responsible for any settlement we do not approve in writing.
        </p>
        <p>17.2 TikBox.io Ltd assumes no liability for any intellectual property 
          infringement claims arising from user-modified content, 
          user-specified modifications, or content combined with third-party 
          products without our consent</p>
        <p>
          17.3 The foregoing obligations do not apply with respect to portions
          or components of the Service (i) not supplied by us, (ii) made in
          whole or in part in accordance with your specifications, (iii) that
          are modified by you or other parties after we have delivered them,
          (iv) combined with other products, processes or materials where the
          alleged infringement relates to such combination, (v) where we
          continue allegedly infringing activity after being notified thereof or
          after being informed of modifications that would have avoided the
          alleged infringement, or (vi) where your use of the Service is not
          strictly in accordance with these Terms.
        </p>
        <p>
          17.4 If, due to a claim of infringement, the Services are held by a
          court of competent jurisdiction to be or are believed by us to be
          infringing, we may, at our option and expense (a) replace or modify
          the Service to be non-infringing, provided that such modification or
          replacement contains substantially similar features and functionality,
          (b) obtain for you a licence to continue using the Service, or (c) if
          neither of the foregoing is commercially practicable, terminate our
          agreement under these Terms and your rights hereunder and provide you
          a refund of any prepaid, unused fees for the Service.
        </p>
        <p>
          17.5 You hereby agree to indemnify and hold us harmless against any
          damages, losses, liabilities, settlements and expenses (including
          without limitation costs and attorneys’ fees) in connection with any
          claim or action howsoever arising as a result of an alleged violation
          of these Terms or otherwise from a User’s use of the Materials or
          Services.
        </p>
        <p>
          17.6 You further agree to indemnify and hold us harmless against any
          damages, losses, liabilities, settlements and expenses (including
          without limitation costs and attorneys’ fees) in connection with any
          claim or action howsoever arising as a result of your failure to
          observe your obligations under the Data Protection Legislation
          (including but not limited to, providing any required notices to and
          obtaining any required consents from data subjects) or arising as a
          result of us complying with any documented instructions you give us.
        </p>
        <h4>18. TERMINATION</h4>
        <p>
          18.1 Subject to earlier termination as provided below, our agreement
          under these Terms is for the Service Term specified in the plan you
          signed up for. Upon expiry of the Initial Service Term or an
          applicable Renewal Period, this agreement will be renewed
          automatically in accordance with clause 14.1 above until terminated in
          accordance with this clause 18.
        </p>
        <p>
          18.2 In addition to any other remedy, either party may also terminate
          our agreement under these Terms upon thirty (30) days’ written notice,
          such notice to expire no earlier than the end of the Initial Service
          Term or applicable Renewal Period as set out in clause 14.1.
        </p>
        <p>
          18.3 We may terminate our agreement with you at our own discretion
          with immediate effect for a material breach of these Terms or for any
          other reason including but not limited to circumstances where:
        </p>
        <ul>
          <li>
            {' '}
            You have broken or attempted to break the law, or put us in a
            position where we might break the law or any applicable regulations;
          </li>
          <li>
            {' '}
            You are using the Platform in a way that is harmful to us, such as
            causing harm to our Platform or our reputation;
          </li>
          <li> You have provided us with false information;</li>
          <li>
            {' '}
            You have been abusive (as determined in our absolute discretion) to
            anyone working for Legals Limited or to another user; or
          </li>
          <li>
            {' '}
            If we are required to do so under any law, regulation or by a
            governmental or regulatory authority.
          </li>
        </ul>
        <p>
          18.4 You will pay in full for the Services that you contracted for.
          For a reasonable amount of time following any termination, you will be
          able to access and save your documents and data upon request.
        </p>
        <p>
          18.5 We will continue storing your documents and data for a reasonable
          amount of time following cancellation of your Membership so that you
          can continue where you left off once you choose to re-subscribe, but
          we do not guarantee that we will store this data forever and will not
          be held responsible or assume any liability for its deletion. As such,
          we recommend you download your documents and data before your decision
          to cancel your Membership so you have your own copy of that
          information should you need them outside of being a Legals member.
        </p>
        <p>18.6 TikBox.io Ltd assumes no liability for the loss of data or 
          documents following termination or cancellation of membership. 
          Users are responsible for downloading their content before membership
           cancellation to avoid potential data loss.</p> 
        <p>
          18.7 Some of the terms in our agreement will continue to be
          enforceable, even after termination including, without limitation, the
          right to be paid, confidentiality obligations, warranty disclaimers,
          and limitations of liability.
        </p>
        <p>
          18.8 If you cancel your Membership under clause 15.11 or if either
          party terminates this agreement under these Terms, you will be unable
          to access documentation that you have created and your cap table until
          you re-subscribe for the relevant plan. In that case, this agreement
          will terminate on the last day of your active membership. You can
          re-subscribe at any time and, if you do so, a new agreement will come
          into effect between us based on our terms of service in effect at that
          time.
        </p>
        <p>
          18.9 Unless you request deletion of personal data under clause 15.3,
          your documents and data will be stored by us for a reasonable amount
          of time following cancellation of your Membership so that you can
          continue where you left off once you choose to re-subscribe, but we do
          not guarantee that we will store this data forever and will not be
          held responsible or assume any liability for its deletion. As such, we
          recommend you download your documents and data before your decision to
          cancel your Membership so you have your own copy of that information
          should you need them outside of being a Legals member.</p>
          <p>18.10 TikBox.io Ltd assumes no liability for the loss of data or documents 
            following termination or cancellation of membership. Users are responsible 
            for downloading their content before membership cancellation to avoid 
            potential data loss.

          </p>
        
        <h4>19. TRANSFER OF RIGHTS AND OBLIGATIONS</h4>
        <p>
          19.1 This agreement is binding on you and us and on our respective
          successors and assignees.
        </p>
        <p>
          19.2 You may not transfer, assign, charge, or otherwise dispose of
          this agreement, or any of your rights or obligations arising under it,
          without our prior written consent.
        </p>
        <p>
          19.3 We may assign, charge, novate, or otherwise dispose of this
          agreement. If this occurs, you will be informed in writing by email.
        </p>
        <h4>20. GENERAL TERMS</h4>
        <p>20.1 Communications</p>
        <p>
          We are required to provide certain information to you in writing. By
          accepting this agreement, you agree that we can communicate with you
          electronically either by email or by posting notices on The Platform.
        </p>
        <p>20.2 Entire Agreement</p>
        <p>
          This agreement and any document expressly referred to in it constitute
          the whole agreement between us and supersede any previous discussions,
          correspondence, arrangements, or understandings between us.
        </p>
        <p>20.3 Law and Jurisdiction</p>
        <p>
          This agreement and any dispute or claim arising out of or in
          connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the law of England and Wales. Any dispute or claim
          arising out of or in connection with this agreement will be subject to
          the exclusive jurisdiction of the courts of England and Wales.
        </p>
        <h4>21. CONTACT US</h4>
        <p>To contact us, please email admin [at] tikbox.io</p>
      </div>
      {isSignUpOpen && (
        <SignUpModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      {isSignInOpen && (
        <SignInModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      <Footer />
    </>
  )
}

export default TermsConditions
