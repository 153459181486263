export const PDFMultipleUploadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 13C20 16.7712 20 18.6569 18.7552 19.8284C17.5104 21 15.5069 21 11.5 21H10.7273C7.46607 21 5.83546 21 4.70307 20.2022C4.37862 19.9736 4.09058 19.7025 3.8477 19.3971C3 18.3313 3 16.7966 3 13.7273V11.1818C3 8.21865 3 4.68331 3.46894 3.5C4.22281 1.59767 6.17672 1.00001 7.83836 1C9.5 1 10.6698 1 13.8182 1C15.6173 1 16.5168 1 17.2352 1.2522C18.3902 1.65765 19.3012 2.5151 19.732 3.60214C20 4.27832 20 5.12494 20 6.81818V13Z"
        fill="url(#paint0_linear_2020_2679)"
      />
      <path
        d="M20.4125 21.4643L20.4125 21.4643C19.8994 21.9472 19.2078 22.2174 18.1006 22.3575C16.982 22.4991 15.5168 22.5 13.5 22.5H12.7273C11.0859 22.5 9.8936 22.4994 8.9687 22.403C8.05177 22.3074 7.45789 22.1224 6.99104 21.7935C6.70483 21.5918 6.45176 21.3534 6.23903 21.0859L6.23902 21.0859C5.89586 20.6544 5.70288 20.1079 5.60254 19.2551C5.50081 18.3905 5.5 17.2741 5.5 15.7273V13.1818C5.5 11.6976 5.50008 10.084 5.55818 8.69434C5.58722 7.99964 5.63048 7.36872 5.6942 6.84295C5.75914 6.30715 5.84133 5.91748 5.93377 5.68421L5.93377 5.68421C6.57466 4.06698 8.25649 3.50001 9.83836 3.5L15.8182 3.5C17.6596 3.5 18.4519 3.50714 19.0696 3.72397L19.0696 3.72398C20.0952 4.084 20.8925 4.84101 21.2672 5.78635L21.2672 5.78637C21.3741 6.05602 21.4357 6.37858 21.4676 6.86167C21.4997 7.34924 21.5 7.96497 21.5 8.81818V15C21.5 16.9006 21.4988 18.2727 21.3494 19.3184C21.2026 20.3465 20.9213 20.9854 20.4125 21.4643Z"
        fill="url(#paint1_linear_2020_2679)"
        stroke="white"
      />
      <path
        d="M7.10133 11.0676C6.30133 5.4676 10.4347 4.73427 12.6013 5.0676C11.1016 5.56743 11.1016 6.567 11.1016 7.56669V7.56763C11.1016 8.56763 10.6016 9.06763 9.10156 9.06763C7.90156 9.06763 7.26807 10.4009 7.10133 11.0676Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2020_2679"
          x1="11.048"
          y1="2.42492"
          x2="11.048"
          y2="20.9999"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0B8FF" />
          <stop offset="1" stopColor="#8D98F8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2020_2679"
          x1="13.048"
          y1="4.42492"
          x2="13.048"
          y2="22.9999"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#B0B8FF" />
          <stop offset="1" stopColor="#8D98F8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
