export const AgreementHeaderReviewIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="#e8e8e8" />
      <rect x="2" y="2" width="24" height="24" rx="12" fill="#e8e8e8" />
      <path
        d="M23.544 13.045C23.848 13.4713 24 13.6845 24 14C24 14.3155 23.848 14.5287 23.544 14.955C22.1779 16.8706 18.6892 21 14 21C9.31078 21 5.8221 16.8706 4.45604 14.955C4.15201 14.5287 4 14.3155 4 14C4 13.6845 4.15201 13.4713 4.45604 13.045C5.8221 11.1294 9.31078 7 14 7C18.6892 7 22.1779 11.1294 23.544 13.045Z"
        stroke="#242424"
        strokeWidth="1.5"
      />
      <path
        d="M17 14C17 12.3431 15.6569 11 14 11C12.3431 11 11 12.3431 11 14C11 15.6569 12.3431 17 14 17C15.6569 17 17 15.6569 17 14Z"
        stroke="#242424"
        strokeWidth="1.5"
      />
    </svg>
  )
}
