import { WatermarkModal } from '../../components/common/modal/WatermarkModal'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

const Watermark = () => {
  return (
    <div className="watermark">
      <WatermarkModal open={true} onClose={() => {}} />
    </div>
  )
}

export default Watermark
