import _ from 'lodash'
import FileUtils from '../../utils/FileUtils'

class S3BucketUploader {
  // Function to upload a file to S3 using a pre-signed URL with progress tracking
  async manageUploadPresignedAssetToS3Bucket(
    fileBlob,
    key,
    presignedUrl,
    callback,
  ) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', presignedUrl, true)
      xhr.setRequestHeader('Content-Type', fileBlob.type)

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total)
          callback && callback(progress)
        }
      }

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          // resolve(xhr.response)
          const location = FileUtils.removeURLQueryParameters(presignedUrl)
          resolve({
            key: key,
            Key: key,
            location,
            Location: location,
          })
        } else {
          reject(new Error(`Error uploading file: ${xhr.statusText}`))
        }
      }

      xhr.onerror = () => {
        reject(new Error('Network error'))
      }

      xhr.send(fileBlob)
    })
  }
}

export default new S3BucketUploader()
