import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import Logo from '../../assets/images/TIKBOX_logo_Blue.jpg'
import './verify.scss'
import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import LibraryAssetUploader from '../../services/library-assets/LibraryAssetUploader'
import * as UploadServices from '../../services/UploadServices'
import { toast } from 'react-toastify'
import Spinner from '../../components/common/spinner/Spinner'
import FileUtils from '../../utils/FileUtils'
import { HOME, USER_VERIFY } from '../../constants/Routes'
import instance from '../../utils/Axios'
import Header from '../../components/header/Header'
import SignUpModal from '../main/landing-page/SignUp'
import SignInModal from '../main/landing-page/SignIn'
import { Footer } from '../../components/footer/Footer'

const FILE_UPLOAD_SIZE_LIMIT = 2123138000

const Verify = () => {
  const inputRef = useRef(null)
  const location = useLocation()
  const [uploading, setUploading] = useState(false)
  const [verifiedData, setVerifiedData] = useState()
  const [isSignUpOpen, setIsSignUpOpen] = useState(false)
  const [isSignInOpen, setIsSignInOpen] = useState(false)

  const uploadMedia = () => {
    inputRef.current.click()
  }

  const verifyMedia = async (e) => {
    try {
      setUploading(true)
      setVerifiedData()
      const files = e.target.files
      if (_.isEmpty(files)) {
        return
      }

      const file = _.first(files)
      if (file.size > FILE_UPLOAD_SIZE_LIMIT) {
        return toast.error(
          'Whoa! That is a huge file size. It exceeds our max 5 GB limit. Try something smaller.',
        )
      }

      const fileName = file.name

      const fileExt = fileName.substring(fileName.lastIndexOf('.'))
      const fileNameUrl = `${moment().format('x')}${fileExt}`

      const uploadData = await LibraryAssetUploader.saveAsLibraryAsset(
        file,
        fileNameUrl,
        `assets/${FileUtils.getFileType(file.type)}s`,
        'verify',
      )
      const verifyData = await UploadServices.verifyMedia({
        url: uploadData.location,
      })
      setVerifiedData(verifyData)
      setUploading(false)
    } catch (error) {
      setUploading(false)
      toast.error(error?.message || 'Error while verifying the media')
    }
  }

  const verifyMediaByAssetId = async (assetId) => {
    try {
      setUploading(true)
      setVerifiedData()
      const verifiedData = await instance.get(`/assets/${assetId}/verify`)
      const verifyData = await UploadServices.verifyMedia({
        url: verifiedData?.data?.url,
      })
      setVerifiedData(verifyData)
      setUploading(false)
    } catch (error) {
      setUploading(false)
      toast.error(
        error?.response?.data?.message || 'Error while verifying the media',
      )
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const assetId = queryParams.get('assetId')
    if (assetId) {
      verifyMediaByAssetId(assetId)
    }
  }, [])

  return (
    <>
      <Header
        setIsSignUpOpen={setIsSignUpOpen}
        setIsSignInOpen={setIsSignInOpen}
      />
      <div className="tikbox-verify">
        {uploading && <Spinner />}
        <div className="tikbox-verify__content">
          <div className="upload-section">
            <h4>Upload to verify asset</h4>
            <div className="upload-icon">
              <FontAwesomeIcon
                size="3x"
                icon={faFileImage}
                style={{ color: '#0daa85' }}
              />
            </div>
            <button onClick={uploadMedia}>Upload</button>
            <input
              ref={inputRef}
              type="file"
              id="upload"
              style={{ display: 'none' }}
              onChange={verifyMedia}
            />
          </div>

          {verifiedData && (
            <div className="verify-info" style={{ marginTop: 30 }}>
              <ul>
                <li>
                  <b>Name</b>: {verifiedData.name}
                </li>
                <li>
                  <b>Assignment</b>: {verifiedData.assign.toUpperCase()}
                </li>
                <li>
                  <b>License</b>: {verifiedData.license.toUpperCase()}
                </li>
                <li>
                  <b>Royalty</b>: {verifiedData.royalty.toUpperCase()}
                </li>
                <li>
                  <b>Ref</b>: {verifiedData.ref}
                </li>
              </ul>
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#0daa85',
                  fontWeight: 'bold',
                }}
                to={`${USER_VERIFY}?redirect=${USER_VERIFY}&verify_ref=${verifiedData.ref}`}>
                Login or Register to view more
              </Link>
            </div>
          )}
        </div>
      </div>
      {isSignUpOpen && (
        <SignUpModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      {isSignInOpen && (
        <SignInModal
          setIsSignUpOpen={setIsSignUpOpen}
          setIsSignInOpen={setIsSignInOpen}
        />
      )}
      <Footer />
    </>
  )
}

export default Verify
