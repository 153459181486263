import { toast } from 'react-toastify'
import instance from '../../utils/Axios'

import {
  GET_ASSETS,
  GET_ASSETS_LOADING,
  REMOVE_SIGNING_ASSETS,
  SAVE_ASSETS,
  DELETE_ASSET,
  RESET_SIGNING_ASSETS,
} from './Types'

export const setSignAsset = (assetData) => (dispatch) => {
  dispatch({
    type: SAVE_ASSETS,
    payload: assetData,
  })
}

export const resetSigningAssets = () => (dispatch) => {
  dispatch({
    type: RESET_SIGNING_ASSETS,
    payload: [],
  })
}

// Save Uploaded Assets
export const saveAssets =
  (assetData, assetType, copyright, userId) => (dispatch) => {
    dispatch({
      type: GET_ASSETS_LOADING,
      payload: true,
    })
    instance
      .post('/assets', { assets: assetData, assetType, copyright, userId })
      .then((res) => {
        if (res.data.result !== 'error') {
          dispatch({
            type: SAVE_ASSETS,
            payload: res.data.message,
          })
          return
        }
        toast.error(res.data.message)
      })
      .catch((err) => {
        toast.error('Error saving assets')
      })
      .finally(() => {
        dispatch({
          type: GET_ASSETS_LOADING,
          payload: false,
        })
      })
  }

// Delete Uploaded Assets
export const deleteAsset = (id) => (dispatch) => {
  instance
    .delete(`/assets/${id}/delete`)
    .then(() => {
      toast.success('Asset deleted successfully')
      setTimeout(() => window.location.reload(), 500)
    })
    .catch(() => {
      toast.error('Error deleting asset')
    })
}

// Get Uploaded Assets
export const getAssets = () => (dispatch) => {
  dispatch({
    type: GET_ASSETS_LOADING,
    payload: true,
  })
  instance
    .get('/assets')
    .then((res) => {
      dispatch({
        type: GET_ASSETS,
        payload: res.data.message,
      })
    })
    .catch(() => {
      toast.error('Error fetching assets')
    })
    .finally(() => {
      dispatch({
        type: GET_ASSETS_LOADING,
        payload: false,
      })
    })
}

// Save Uploaded Assets
export const removeSignAsset = (removeAll, name) => (dispatch) => {
  dispatch({
    type: REMOVE_SIGNING_ASSETS,
    payload: { name, removeAll },
  })
}
