import { Tooltip } from 'antd'
import { AgreementContentType } from '../../../constants'
import { ActionBtns } from './ActionBtns'
import { EditOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const ShowSummarizedActions = ({
  paragraphContent,
  setToggleFull,
  isFull,
  isNotFull,
  hideToggleFull,
}) => {
  return paragraphContent.map((p, index) => {
    return (
      <p key={index}>
        {p}{' '}
        {isFull && index === paragraphContent.length - 1 && !hideToggleFull && (
          <span onClick={() => setToggleFull(false)} className="toggler">
            See summary clause
          </span>
        )}
        {isNotFull &&
          index === paragraphContent.length - 1 &&
          !hideToggleFull && (
            <span onClick={() => setToggleFull(true)} className="toggler">
              See legal clause
            </span>
          )}
      </p>
    )
  })
}

export const AgreementItem = ({
  editingAgreement,
  setEditAgreement,
  cancelEditAgreement,
  saveUpdatedAgreement,
  agreementContent,
  setAgreementContent,
  agreement,
  addAgreement,
  removeAgreement,
  isChecked,
  htmlFor,
  displayText,
  hideToggleFull,
  showFullAgreement,
}) => {
  const {
    auth: { user },
  } = useSelector((state) => state)
  const [toggleFull, setToggleFull] = useState(false)

  const checkItem = (e, category) => {
    const { name, checked } = e.target
    if (checked) {
      addAgreement(category, name)
    } else {
      removeAgreement(category, name)
    }
  }

  const changeInput = (e) => {
    const { name, value } = e.target
    addAgreement(name, value)
  }

  const isNotFull = agreement.summary && !toggleFull
  const isFull = agreement.summary && toggleFull

  const paragraphContent =
    (isNotFull && !showFullAgreement
      ? `${agreement.summary}`
      : displayText || agreement.content
    )?.split('/') || []

  return (
    <div
      className={`${
        agreement.contentType === AgreementContentType.CHECKBOX
          ? 'tikbox-form-element '
          : agreement.contentType === AgreementContentType.RADIO
          ? 'length-input-container '
          : ''
      }agreement-item`}>
      {editingAgreement === agreement._id ? (
        <textarea
          type="text"
          value={agreementContent}
          onChange={(e) => setAgreementContent(e.target.value)}>
          {agreementContent}
        </textarea>
      ) : agreement.contentType === AgreementContentType.PARAGRAPH ? (
        <div
          className={`agreement-paragraph${
            isNotFull && !showFullAgreement
              ? ' summary'
              : isFull || showFullAgreement
              ? ' full'
              : ''
          }`}>
          <ShowSummarizedActions
            paragraphContent={paragraphContent}
            setToggleFull={setToggleFull}
            isFull={isFull}
            isNotFull={isNotFull}
            hideToggleFull={hideToggleFull}
          />
        </div>
      ) : agreement.contentType === AgreementContentType.TITLE ? (
        <h2>{agreement.content}</h2>
      ) : agreement.contentType === AgreementContentType.CHECKBOX ? (
        <>
          <input
            type="checkbox"
            id={agreement._id}
            checked={isChecked}
            name={agreement._id}
            onChange={(e) => checkItem(e, agreement.category)}
          />
          <label htmlFor={agreement._id}>{agreement.content}</label>
        </>
      ) : agreement.contentType === AgreementContentType.RADIO ? (
        <>
          <input
            onChange={changeInput}
            name="royaltyLength"
            id={agreement._id}
            type="radio"
            checked={isChecked}
            value={agreement._id}
          />{' '}
          <label style={{ marginLeft: -40 }} htmlFor={agreement._id}>
            {agreement.content}
          </label>
        </>
      ) : agreement.contentType === AgreementContentType.SPAN ? (
        <span>{agreement.content}</span>
      ) : agreement.contentType === AgreementContentType.LABEL ? (
        <label className={htmlFor} htmlFor={htmlFor}>
          {agreement.content}
        </label>
      ) : agreement.contentType === AgreementContentType.SUBTITLE ? (
        <h3>{agreement.content}</h3>
      ) : agreement.contentType === AgreementContentType.OL ? (
        <li
          style={{ listStyleType: 'disc' }}
          className={`agreement-list${
            isNotFull && !showFullAgreement
              ? ' summary'
              : isFull || showFullAgreement
              ? ' full'
              : ''
          }`}>
          <ShowSummarizedActions
            paragraphContent={paragraphContent}
            setToggleFull={setToggleFull}
            isFull={isFull}
            isNotFull={isNotFull}
            hideToggleFull={hideToggleFull}
          />
        </li>
      ) : (
        <div>NONE</div>
      )}

      {(user.isAdmin || user.role === 'member') &&
      editingAgreement === agreement._id ? (
        <ActionBtns
          cancelEditAgreement={cancelEditAgreement}
          saveUpdatedAgreement={saveUpdatedAgreement}
        />
      ) : (
        (user.isAdmin || user.role === 'member') && (
          <Tooltip title="Edit agreement">
            <EditOutlined
              onClick={() => setEditAgreement(agreement._id, agreement.content)}
            />
          </Tooltip>
        )
      )}
    </div>
  )
}
