export const UploadCloudIcon = () => {
  return (
    <svg
      width="50"
      height="27"
      viewBox="0 0 50 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.8065 14.6837C25.4071 14.136 24.59 14.136 24.1906 14.6837L20.9614 19.1116C20.4795 19.7724 20.9515 20.7008 21.7694 20.7008H23.0005V24.5758C23.0005 25.6804 23.8959 26.5758 25.0005 26.5758C26.1051 26.5758 27.0005 25.6804 27.0005 24.5758V20.7008H28.2277C29.0456 20.7008 29.5176 19.7724 29.0357 19.1116L25.8065 14.6837Z"
        fill="url(#paint0_linear_2028_5404)"
      />
      <path
        d="M29.75 21.9243H47.7396C49.1488 19.9335 50.558 15.1463 44.9211 11.9239C44.0756 8.68307 40.3834 3.00693 32.379 6.22928C30.9698 4.37734 26.7985 0.840134 21.3871 1.50683C15.9756 2.17352 12.368 5.67362 11.2407 7.34034C8.93892 7.15521 3.82814 7.81274 1.79885 11.9239C-0.230428 16.0351 2.08061 20.4428 3.48983 21.9243H20"
        stroke="url(#paint1_linear_2028_5404)"
        stroke-dasharray="5 5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2028_5404"
          x1="24.7735"
          y1="15.1495"
          x2="24.7735"
          y2="26.5758"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#C7CCFF" />
          <stop offset="1" stopColor="#E3E6FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2028_5404"
          x1="25"
          y1="1.42432"
          x2="25"
          y2="21.9243"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#0F27FD" />
          <stop offset="1" stopColor="#B8BFFF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
