import _ from 'lodash'

export const FileTypes = {
  imageType: 'image',
  videoType: 'video',
  audioType: 'audio',
}

export function getFilename(url) {
  const filenameWithExtension = url.split('/').pop()
  return _.first(filenameWithExtension.split('.'))
}

export function getFileExtension(url) {
  const filenameWithExtension = url.split('/').pop()
  return _.last(filenameWithExtension.split('.'))
}

export function replaceSpacesIntoUnderscores(fileName) {
  let updatedFileName = fileName.replace(/ /g, '_')
  updatedFileName = fileName.replace(/\s/g, '_')
  updatedFileName = fileName.replace(/\s+/g, '_')
  updatedFileName = updatedFileName.replace(/[&\\/\\#,+()$@!~%'":*?<>{}]/g, '')
  return updatedFileName
}

export function createFilenameByTimestamp(prefix = 'VID') {
  const today = new Date()
  const timestamp = `${today.getDate()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}_${today.getTime()}`
  const currentMonth = today.getUTCMonth() + 1
  const currentYear = today.getFullYear()
  return `${prefix}_${currentYear}${currentMonth}${timestamp}`
}

export function generateTimestampItemId() {
  const today = new Date()
  return `${today.getSeconds()}_${today.getTime()}`
}

const getFileType = (fileType) => {
  if (fileType?.includes(FileTypes.imageType)) {
    return FileTypes.imageType
  } else if (fileType?.includes(FileTypes.videoType)) {
    return FileTypes.videoType
  } else if (fileType?.includes(FileTypes.audioType)) {
    return FileTypes.audioType
  } else {
    return 'other'
  }
}

// Function to remove query parameters from a URL
const removeURLQueryParameters = (url) => {
  const urlObj = new URL(url)
  return `${urlObj.origin}${urlObj.pathname}`
}

export default {
  createFilenameByTimestamp,
  replaceSpacesIntoUnderscores,
  generateTimestampItemId,
  getFileType,
  removeURLQueryParameters,
}
