import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AgreementContentType } from '../../../constants'
import { AgreementItem } from './AgreementItem'
import {
  sortAgreements,
  triggerGoogleAnalyticsEvent,
} from '../../../utils/Helper'

const BoilerplateContent = ({
  data,
  editingAgreement,
  setEditAgreement,
  agreementContent,
  setAgreementContent,
  cancelEditAgreement,
  saveUpdatedAgreement,
  selectedAgreements,
  setSelectedAgreements,
  cco,
  onChangeCCOBoilerplate,
}) => {
  const {
    auth: { user },
    creativeCommonLicenses: { data: creativeCommonLicensesData },
  } = useSelector((state) => state)
  const [guaranteesFull, setGuaranteesFull] = useState(false)
  const [indemnityFull, setIndemnityFull] = useState(false)
  const titles = data.filter(
    (x) => x.contentType === AgreementContentType.TITLE,
  )
  const subtitles = data.filter(
    (x) => x.contentType === AgreementContentType.SUBTITLE,
  )
  const paragraphs = data.filter(
    (x) => x.contentType === AgreementContentType.PARAGRAPH,
  )
  const ols = sortAgreements(
    data.filter((x) => x.contentType === AgreementContentType.OL),
  )

  const { guaranteeOls, indemnityOls } = ols.reduce(
    (acc, x, index) => {
      if (index < 5) {
        acc.guaranteeOls.push(x)
      } else if (index < 9) {
        acc.indemnityOls.push(x)
      }
      return acc
    },
    { guaranteeOls: [], indemnityOls: [] },
  )

  const transformCCOTitleValues = (str, username, imageName, cco) => {
    return str
      .replace(/{{username}}/g, username)
      .replace(/{{imageName}}/g, imageName)
      .replace(/{{cco}}/g, cco)
  }

  useEffect(() => {
    triggerGoogleAnalyticsEvent('setting_agreement', {
      stage: 'boilerplate',
      userId: user?.id,
    })
  }, [])

  useEffect(() => {
    // Set ccoLicenceTitle by updating variables match actual values
    if (cco) {
      setSelectedAgreements({
        ...selectedAgreements,
        ccoLicenseTitle: transformCCOTitleValues(
          selectedAgreements.ccoLicenseTitle,
          selectedAgreements.ccoAttribution[1].value,
          selectedAgreements.ccoAttribution[0].value,
          cco,
        ),
      })
    }
  }, [cco])

  return (
    <>
      {!cco &&
        titles.map((title, index) => (
          <Fragment key={index}>
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={title}
            />
          </Fragment>
        ))}
      {!cco && (
        <div className="mb-4">
          {subtitles.length && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={subtitles[0]}
            />
          )}
          <div
            className={`legal-clause-container${
              guaranteesFull ? ' full' : ' summary'
            }`}>
            {paragraphs.length && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={paragraphs[0]}
                hideToggleFull={true}
                showFullAgreement={guaranteesFull}
              />
            )}
            {guaranteeOls.length && (
              <ol>
                {guaranteeOls.map((ol, index) => (
                  <Fragment key={index}>
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={ol}
                      hideToggleFull={true}
                      showFullAgreement={guaranteesFull}
                    />
                  </Fragment>
                ))}
              </ol>
            )}

            {guaranteesFull && (
              <span
                onClick={() => setGuaranteesFull(false)}
                className="toggler">
                See summary clause
              </span>
            )}
            {!guaranteesFull && (
              <span onClick={() => setGuaranteesFull(true)} className="toggler">
                See legal clause
              </span>
            )}
          </div>
        </div>
      )}
      {!cco && (
        <div>
          {subtitles.length > 1 && (
            <AgreementItem
              editingAgreement={editingAgreement}
              setEditAgreement={setEditAgreement}
              cancelEditAgreement={cancelEditAgreement}
              saveUpdatedAgreement={saveUpdatedAgreement}
              agreementContent={agreementContent}
              setAgreementContent={setAgreementContent}
              agreement={subtitles[1]}
            />
          )}
          <div
            className={`legal-clause-container${
              indemnityFull ? ' full' : ' summary'
            }`}>
            {paragraphs.length > 1 && (
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={paragraphs[1]}
                hideToggleFull={true}
                showFullAgreement={indemnityFull}
              />
            )}

            {indemnityOls.length && (
              <ol>
                {indemnityOls.map((ol, index) => (
                  <Fragment key={index}>
                    <AgreementItem
                      editingAgreement={editingAgreement}
                      setEditAgreement={setEditAgreement}
                      cancelEditAgreement={cancelEditAgreement}
                      saveUpdatedAgreement={saveUpdatedAgreement}
                      agreementContent={agreementContent}
                      setAgreementContent={setAgreementContent}
                      agreement={ol}
                      hideToggleFull={true}
                      showFullAgreement={indemnityFull}
                    />
                  </Fragment>
                ))}
              </ol>
            )}

            {indemnityFull && (
              <span onClick={() => setIndemnityFull(false)} className="toggler">
                See summary clause
              </span>
            )}
            {!indemnityFull && (
              <span onClick={() => setIndemnityFull(true)} className="toggler">
                See legal clause
              </span>
            )}
          </div>
        </div>
      )}
      {cco && (
        <div className="cco-boilerplate">
          <h2>Boiler plate</h2>

          <form>
            <div className="attribution-checkbox">
              <input
                type="checkbox"
                checked={selectedAgreements.ccoBoilerplate[0].value}
                name="ownLicense"
                id="ownLicense"
                onChange={onChangeCCOBoilerplate}
              />
              <label htmlFor="ownLicense">
                I own or have authority to license the work.
              </label>
            </div>
            <div className="attribution-checkbox">
              <input
                type="checkbox"
                checked={selectedAgreements.ccoBoilerplate[1].value}
                name="licenseTerms"
                id="licenseTerms"
                onChange={onChangeCCOBoilerplate}
              />
              <label htmlFor="licenseTerms">
                I have read and understand the terms of the license.
              </label>
            </div>
            <div className="attribution-checkbox">
              <input
                type="checkbox"
                checked={selectedAgreements.ccoBoilerplate[2].value}
                name="nonRevocable"
                id="nonRevocable"
                onChange={onChangeCCOBoilerplate}
              />
              <label htmlFor="nonRevocable">
                I understand that CC licensing is not revocable.
              </label>
            </div>
          </form>
        </div>
      )}
    </>
  )
}

export default BoilerplateContent
