export const AgreementHeaderCheckedIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="#EEFFEA" />
      <path
        d="M25.6673 13.9999C25.6673 7.55659 20.4439 2.33325 14.0007 2.33325C7.55733 2.33325 2.33398 7.55659 2.33398 13.9999C2.33398 20.4432 7.55733 25.6666 14.0007 25.6666C20.4439 25.6666 25.6673 20.4432 25.6673 13.9999Z"
        fill="#EEFFEA"
      />
      <path
        d="M9.33398 14.5833L12.2507 17.5L18.6673 10.5"
        stroke="#1F8505"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
