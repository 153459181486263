import { useSelector } from 'react-redux'
import { Select } from 'antd'
import { AgreementContentType, AgreementKeys } from '../../../constants'
import { AgreementItem } from './AgreementItem'
import Spinner from '../../../components/common/spinner/Spinner'
import { DropdownIcon } from '../../../components/common/icons/DropdownIcon'
import { Fragment, useEffect, useState } from 'react'
import {
  sortAgreements,
  triggerGoogleAnalyticsEvent,
} from '../../../utils/Helper'
import SelectRadio from '../../../components/common/select-radio/SelectRadio'

const LicenseContent = ({
  data,
  addAgreement,
  removeAgreement,
  selectedAgreements,
  editingAgreement,
  setEditAgreement,
  agreementContent,
  setAgreementContent,
  cancelEditAgreement,
  saveUpdatedAgreement,
  cco,
  knowCCOLicense,
  agreementBuilder,
  updateAgreementBuilder,
  removeAnswerFromAgreementBuilder,
  navigateToNextAgreementTimeline,
  navigateToPreviousAgreementTimeline,
  navigateToSpecificTimeline,
}) => {
  const {
    agreements: { loading },
    auth: { user },
    creativeCommonLicenses: { data: creativeCommonLicensesData },
  } = useSelector((state) => state)
  const [showContent, setShowContent] = useState(true)
  const selections = selectedAgreements[AgreementKeys.License]

  const titles = data.filter(
    (x) => x.contentType === AgreementContentType.TITLE,
  )
  const paragraphs = data.filter(
    (x) => x.contentType === AgreementContentType.PARAGRAPH,
  )
  const checkboxes = sortAgreements(
    data.filter((x) => x.contentType === AgreementContentType.CHECKBOX),
  )

  useEffect(() => {
    triggerGoogleAnalyticsEvent('setting_agreement', {
      stage: 'license',
      userId: user?.id,
    })
  }, [])

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="assignment-content">
      {!knowCCOLicense && (
        <>
          <DropdownIcon
            className={`dropdown${showContent ? ' open' : ''}`}
            onClick={() => setShowContent(!showContent)}
          />
          {titles.map((title, index) => (
            <Fragment key={index}>
              <AgreementItem
                editingAgreement={editingAgreement}
                setEditAgreement={setEditAgreement}
                cancelEditAgreement={cancelEditAgreement}
                saveUpdatedAgreement={saveUpdatedAgreement}
                agreementContent={agreementContent}
                setAgreementContent={setAgreementContent}
                agreement={title}
              />
            </Fragment>
          ))}
          {showContent &&
            paragraphs.map((p, index) => (
              <Fragment key={index}>
                <AgreementItem
                  editingAgreement={editingAgreement}
                  setEditAgreement={setEditAgreement}
                  cancelEditAgreement={cancelEditAgreement}
                  saveUpdatedAgreement={saveUpdatedAgreement}
                  agreementContent={agreementContent}
                  setAgreementContent={setAgreementContent}
                  agreement={p}
                />
              </Fragment>
            ))}

          {showContent && (
            <div className="questionaire">
              <form>
                {checkboxes.map((checkbox, index) => {
                  const isChecked = !!selections.find(
                    (x) => x.index === checkbox._id,
                  )
                  return (
                    <Fragment key={index}>
                      <AgreementItem
                        editingAgreement={editingAgreement}
                        setEditAgreement={setEditAgreement}
                        cancelEditAgreement={cancelEditAgreement}
                        saveUpdatedAgreement={saveUpdatedAgreement}
                        agreementContent={agreementContent}
                        setAgreementContent={setAgreementContent}
                        agreement={checkbox}
                        isChecked={isChecked}
                        addAgreement={addAgreement}
                        removeAgreement={removeAgreement}
                      />
                    </Fragment>
                  )
                })}
              </form>
            </div>
          )}
        </>
      )}

      {/* CCO SECTION */}
      {knowCCOLicense && (
        <div>
          {/* <h2>License Chooser</h2> */}
          <div className="license-chooser">
            {/* <div className="license-chooser__decisions">
              {agreementBuilder.questions
                .filter((question) => question.active)
                .map((question, index) => {
                  const selected = question.answer
                  const selection = question.options.find(
                    (option) => option.title === selected,
                  )?.selection
                  return (
                    <div
                      onClick={() =>
                        agreementBuilder.currentTimeline > question.timeline &&
                        navigateToSpecificTimeline(question.timeline)
                      }
                      style={{
                        cursor:
                          agreementBuilder.currentTimeline > question.timeline
                            ? 'pointer'
                            : 'auto',
                      }}
                      key={index}
                      className={`license-chooser__decisions-decision${
                        question.timeline === agreementBuilder.currentTimeline
                          ? ' open'
                          : question.timeline > agreementBuilder.currentTimeline
                          ? ' pending'
                          : ''
                      }`}>
                      <div className="decision-no">
                        <span>{index + 1}</span>
                      </div>
                      <div className="decision-question">
                        <h3 className="decision-question__open-title">
                          {question.question.title}
                        </h3>
                        <h3 className="decision-question__close-title">
                          {question.question.selection}
                        </h3>
                        <p className="decision-question__selection">
                          {selection}
                        </p>
                        <div className="decision-question__select-options">
                          {question.optionsType === 'select' && (
                            <Select
                              defaultValue={selected || 'Select'}
                              style={{
                                marginRight: 7,
                                marginTop: 15,
                                height: 48,
                                width: '100%',
                              }}
                              popupClassName="custom-select-dropdown"
                              onChange={(value) => {
                                updateAgreementBuilder(question.timeline, value)
                              }}
                              options={question.options.map((ccl) => {
                                return { value: ccl.title, label: ccl.title }
                              })}
                            />
                          )}
                          {question.optionsType === 'radio' &&
                            question.options.map((option, index) => {
                              return (
                                <SelectRadio
                                  key={index}
                                  onClick={() =>
                                    updateAgreementBuilder(
                                      question.timeline,
                                      option.title,
                                    )
                                  }
                                  title={option.title}
                                  selected={question.answer === option.title}
                                />
                              )
                            })}
                          {question.optionsType === 'checkbox' &&
                            question.options.map((option, index) => {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'start',
                                  }}
                                  key={index}>
                                  <input
                                    type="checkbox"
                                    id={index}
                                    style={{ marginTop: 5, marginRight: 5 }}
                                    checked={question.answer.includes(
                                      option.title,
                                    )}
                                    name={index}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        updateAgreementBuilder(
                                          question.timeline,
                                          option.title,
                                        )
                                      } else {
                                        removeAnswerFromAgreementBuilder(
                                          question.timeline,
                                          option.title,
                                        )
                                      }
                                    }}
                                  />
                                  <label htmlFor={index}>{option.title}</label>
                                </div>
                              )
                            })}
                        </div>
                        <div className="decision-question__btns">
                          <button
                            className="decision-question__btns-back"
                            disabled={question.timeline < 2}
                            onClick={navigateToPreviousAgreementTimeline}>
                            Back
                          </button>
                          <button
                            className="decision-question__btns-next"
                            disabled={
                              question.optionsType === 'checkbox'
                                ? question.answer.length !==
                                  question.options.length
                                : !question.answer
                            }
                            onClick={navigateToNextAgreementTimeline}>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div> */}
            <div className="license-chooser__selection">
              <h5>{cco}</h5>
              <h6>
                {' '}
                {creativeCommonLicensesData.find((x) => x.code === cco)?.name}
              </h6>
              <p>
                {
                  creativeCommonLicensesData.find((x) => x.code === cco)
                    ?.content
                }
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LicenseContent
