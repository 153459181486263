import instance from '../../utils/Axios'

import {
  GET_CREATIVE_COMMON_LICENSES,
  GET_CREATIVE_COMMON_LICENSES_LOADING,
} from './Types'

// Get creative common licenses
export const getCreativeCommonLicenses = () => (dispatch) => {
  dispatch({
    type: GET_CREATIVE_COMMON_LICENSES_LOADING,
    payload: true,
  })
  instance
    .get('/creative-common-licenses')
    .then((res) => {
      dispatch({
        type: GET_CREATIVE_COMMON_LICENSES,
        payload: res.data.message,
      })
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({
        type: GET_CREATIVE_COMMON_LICENSES_LOADING,
        payload: false,
      })
    })
}
