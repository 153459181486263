import instance from '../../utils/Axios'

import {
  GET_AGREEMENTS,
  GET_AGREEMENTS_LOADING,
  UPDATE_AGREEMENT,
  UPDATE_AGREEMENT_LOADING,
} from './Types'

// Get agreements
export const getAgreements = () => (dispatch) => {
  dispatch({
    type: GET_AGREEMENTS_LOADING,
    payload: true,
  })
  instance
    .get('/agreements')
    .then((res) => {
      dispatch({
        type: GET_AGREEMENTS,
        payload: res.data.message,
      })
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({
        type: GET_AGREEMENTS_LOADING,
        payload: false,
      })
    })
}

// Update agreements
export const updateAgreement = (id, content) => (dispatch) => {
  dispatch({
    type: UPDATE_AGREEMENT_LOADING,
    payload: true,
  })
  instance
    .put(`/agreements/${id}/update`, { content })
    .then((res) => {
      dispatch({
        type: UPDATE_AGREEMENT,
        payload: res.data.message,
      })
    })
    .catch((err) => {})
    .finally(() => {
      dispatch({
        type: UPDATE_AGREEMENT_LOADING,
        payload: false,
      })
    })
}
